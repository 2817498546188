<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  // mixins: [reactiveProp],
  props: ["height", "users", "graphic"],

  data() {
    return {
      loading: false,
      value: [],
      chartdata: {
        labels: [
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
        ],
        datasets: [
          {
            label: "Ligações por hora",
            borderColor: "#00ffff",
            borderWidth: 1,
            fill: false,
            lineTension: 0,
            // pointBorderColor: "#012433",
            pointBackgroundColor: "#00ffff",
            pointBorderWidth: 0,
            data: this.graphic,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          display: false,
        },
        animation: {
          duration: 1,
        },
        plugins: {
          datalabels: {
            anchor: "center",
            display: false,
            color: "black",
            backgroundColor: "#FFFFFFF4",
            borderRadius: 3,
            font: {
              size: 13,
            },
          },
        },
        tooltips: {
          mode: "label",
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                "Ligações: " +
                Number(tooltipItem.yLabel)                  
              );
            },
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize:15,
                fontColor:'#fff',
                beginAtZero: true,
              },              
            },
          ],
          yAxes: [
            {
              ticks: {
                fontSize:15,
                fontColor:'#fff',
                beginAtZero: true,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      },
    };
  },

  mounted() {
    this.renderChart(this.chartdata, this.options);
  },

  watch: {
    users() {
      this.chartdata.datasets[0].data = this.graphic;
      this.renderChart(this.chartdata, this.options);
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
</style>