<template>
  <div class="fill-height">
    <v-row justify="center">
      <v-col class="pr-0">
        <v-card width="100%" height="100%" flat>
          <v-card-title class="py-1" style="font-size: 14px">{{
            title
          }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0" md="12">
        <v-row class="ma-0">
          <v-col class="pt-0 pr-0" md="4">
            <v-card class="text-center" flat width="100%" height="65px">
              <v-card-title style="font-size: 14px; padding: 10px">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">NOVOS PACIENTES</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text v-if="!loading">
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col>
                    <span
                      class="text-info-yellow h5 font-weight-bold"
                      v-if="show"
                      >{{ value.novos_pacientes }}</span
                    >
                    <span class="text-info-yellow h5 font-weight-bold" v-else
                      >-</span
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-skeleton-loader
                type="list-item"
                height="40"
                v-if="loading"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
          <v-col class="pt-0 pr-0" md="4">
            <v-card class="text-center" flat width="100%" height="65px">
              <v-card-title style="font-size: 14px; padding: 10px">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">AGENDAMENTOS</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text v-if="!loading">
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col>
                    <span
                      class="text-info-yellow h5 font-weight-bold"
                      v-if="show"
                      >{{ value.novos_agendamentos }}</span
                    >
                    <span class="text-info-yellow h5 font-weight-bold" v-else
                      >-</span
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-skeleton-loader
                type="list-item"
                height="40"
                v-if="loading"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
          <v-col class="pt-0 pr-0" md="4">
            <v-card class="text-center" flat width="100%" height="65px">
              <v-card-title style="font-size: 14px; padding: 10px">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">ATENDIMENTOS</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text v-if="!loading">
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col>
                    <span
                      class="text-info-yellow h5 font-weight-bold"
                      v-if="show"
                      >{{ value.novos_atendimentos }}</span
                    >
                    <span class="text-info-yellow h5 font-weight-bold" v-else
                      >-</span
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-skeleton-loader
                type="list-item"
                height="40"
                v-if="loading"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="pt-0 pr-0" md="4">
            <v-card class="text-center" flat width="100%" height="65px">
              <v-card-title style="font-size: 14px; padding: 10px">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">ORÇAMENTOS</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text v-if="!loading">
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col>
                    <span
                      class="text-info-yellow h5 font-weight-bold"
                      v-if="show"
                      >{{ value.novos_orcamentos }}</span
                    >
                    <span class="text-info-yellow h5 font-weight-bold" v-else
                      >-</span
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-skeleton-loader
                type="list-item"
                height="40"
                v-if="loading"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
          <v-col class="pt-0 pr-0" md="4">
            <v-card class="text-center" flat width="100%" height="65px">
              <v-card-title style="font-size: 14px; padding: 10px">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">CONTRATOS</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text v-if="!loading">
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col>
                    <span
                      class="text-info-yellow h5 font-weight-bold"
                      v-if="show"
                      >{{ value.novos_contratos }}</span
                    >
                    <span class="text-info-yellow h5 font-weight-bold" v-else
                      >-</span
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-skeleton-loader
                type="list-item"
                height="40"
                v-if="loading"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
          <v-col class="pt-0 pr-0" md="4">
            <v-card class="text-center" flat width="100%" height="65px">
              <v-card-title style="font-size: 14px; padding: 10px">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">FINANCEIRO</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text v-if="!loading">
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col>
                    <span
                      class="text-info-yellow h5 font-weight-bold"
                      v-if="show"
                      >{{ value.novos_recebiveis }}</span
                    >
                    <span class="text-info-yellow h5 font-weight-bold" v-else
                      >-</span
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-skeleton-loader
                type="list-item"
                height="40"
                v-if="loading"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0" style="padding: 0 0 0 15px">
          <card-taxas-geral-fake
            title="TAXAS GERAL"
            :show="show"
          ></card-taxas-geral-fake>
        </v-row>
        <v-row class="ma-0 pr-0" style="padding-left: 15px">
          <card-nps-fake
            title="TAXAS GERAL"
            :show="show"
          ></card-nps-fake>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// moment(newValue.data).fromNow()
export default {
  props: ["title", "show"],

  data() {
    return {
      loading: false,
      loadingAquisicao: false,
      loadingFatu: false,
      loadingInv: false,
      loadingCplReal: false,
      value: {
        id: 4,
        novos_pacientes: 600,
        novos_agendamentos: 850,
        novos_atendimentos: 680,
        novos_orcamentos: 570,
        novos_contratos: 150,
        novos_recebiveis: 149,
        factory_id: 1,
      },
      valueAquisicao: [],
      valueFatu: [],
      valueInv: [],
      valueCplReal: [],
      totalAquisicao: 0,
    };
  },
};
</script>