<template>
  <v-row>
    <v-col>
      <infinite-slide-bar
        :duration="duration()"
        :delay="!loading ? '10s': '50000s'"
        v-if="!loading"
      >
        <v-slide-group class="py-0">
          <v-slide-item v-for="(item,index) in items" :key="index" class="mr-2">
            <v-list two-line width="380px" height="100px" class="pa-0 d-flex align-item-center" justify="center">
              <v-list-item style="height:100px">
                <v-avatar size="60" color="grey" class="mr-4">
                  <v-img
                    :src="item.avatar"
                    :lazy-src="item.avatar"
                    v-if="item.avatar"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                          size="20"
                          width="2"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-icon dark v-else>mdi-account</v-icon>
                </v-avatar>
                <v-list-item-content class="pa-0" width="100%">
                  <v-list-item-title class="pl-0">
                    <div class="title" style="font-size:16px !important;line-height: 20px !important;">{{item.user_name}}</div>
                    <span class="text-info-yellow font-weight-bold title mt-1" v-if="show">{{item.valor | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}</span>
                    <span class="text-info-yellow font-weight-bold title mt-1" v-else>-</span>
                    <!-- <span class="text-info-yellow title mx-1">|</span>
                    <span class="text-info-yellow font-weight-bold title">{{item.contibuition}}%</span> -->
                  </v-list-item-title>
                  <v-list-item-subtitle style="height: 13px;">
                    <v-row justify="start" align="center" class="pl-0 mr-0 ml-0">
                      <v-col class="pa-0 pr-2" v-if="item.gap">
                        <span style="font-size:12px;">GAP:</span>
                        <span class="text-info-blue font-weight-bold overline">{{item.gap | percent}}</span>
                        <span class="text-info-yellow title">|</span>
                        <span
                          class="text-info-blue font-weight-bold overline"
                          v-if="show"
                        >{{item.gap_valor | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}</span>
                        <span class="text-info-blue font-weight-bold overline" v-else>-</span>
                      </v-col>
                      <v-col class="pa-0" v-if="item.valor_ant">
                        <span style="font-size:12px;">Ant:</span>
                        <span class="text-info-blue font-weight-bold overline">{{item.porc_ant | percent}}</span>
                        <span class="text-info-yellow title">|</span>
                        <span
                          class="text-info-blue font-weight-bold overline"
                          v-if="show"
                        >{{item.valor_ant | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}</span>
                        <span class="text-info-blue font-weight-bold overline" v-else>-</span>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <span v-if="factory.id == 999999" class="badge badge-dark" style="position:absolute;top:5px;right:5px;font-size:10px;">{{item.unidade}}</span>
              </v-list-item>
            </v-list>
          </v-slide-item>
        </v-slide-group>
      </infinite-slide-bar>
      <div v-else>
        <v-slide-group class="py-0">
          <v-slide-item v-for="(item,index) in 5" :key="index" class="mr-2">
            <v-list two-line width="320px">
              <v-list-item>
                <v-list-item-content class="pa-0" width="100%" height="100%">
                  <v-skeleton-loader type="list-item-two-line" height="75"></v-skeleton-loader>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-slide-item>
        </v-slide-group>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  props: ["title", "show", "factory", "date"],
  data: () => ({
    loading: false,
    items: []
  }),
  watch: {
    date() {
      this.getDados();
    }
  },
  created() {
    this.getDados();
  },
  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: 212,
        periodo: this.date,
        factory_id: this.factory.id
      };
      axios
        .post("/api/usuarios-total", dados)
        .then(response => {
          console.log("getUsuariosTotal");
          console.log(response.data);
          this.items = response.data;
          this.loading = false;
          this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
      }, 120000);
    },

    duration() {
      if (this.items.length >= 6) {
        // return "800s";
        return (this.items.length * 7) + "s";
      } else {
        return "4984984984984s";
      }
    }
  }
};
</script>
<style>
</style>