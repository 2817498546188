<template>
  <div class="box box-widget">
    <div class="box-header">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <h3 class="box-title">{{ title }}</h3>
        </div>
        <div v-if="busca != 'false'" class="col-md-6 col-sm-6">
          <form method="GET" class="form-horizontal" :action="url">
            <div class="input-group">
              <a
                class="btn btn-flat btn-warning mr-5"
                href="/dashboard/52876260c54ebf89ed572e0eb8465dcf"
                target="_blank"
                data-toggle="tooltip"
                title="Dashboard Geral"
                v-if="factoryList"
              >
                <i class="fa fa-bar-chart"></i> Dashboard Geral
              </a>
              <div
                class="mt-2 ml-n5 mr-2"
                v-if="filter_prod == true || filter_prod == false"
              >
                <label for="status" class="col-sm-0 control-label"
                  >Sem produtos</label
                >
                <input
                  type="checkbox"
                  name="filter_prod"
                  :value="1"
                  :checked="filter_prod"
                />
              </div>
              <input
                type="text"
                name="busca"
                class="form-control"
                placeholder="Buscar por"
                :value="busca"
              />
              <span class="input-group-btn">
                <button type="submit" class="btn btn-primary btn-flat">
                  <i class="fa fa-search"></i>
                </button>
                <a
                  :href="url"
                  class="btn btn-default btn-flat"
                  data-toggle="tooltip"
                  title="Limpar Busca"
                >
                  <i class="fa fa-times"></i>
                </a>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="box-body">
      <slot></slot>
      <form
        v-if="items.data.length > 0"
        :class="formClass"
        v-on:submit.prevent="confirmDelete"
        :action="url + '/destroy'"
        method="POST"
      >
        <input type="hidden" name="_token" :value="token" />
        <input type="hidden" name="_method" value="DELETE" />

        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th v-if="!notDeletable" align="center" width="50px">
                <input
                  class="checkbox-delete"
                  id="checkbox-delete-origin"
                  type="checkbox"
                  name="registro[]"
                  data-toggle="tooltip"
                  title="Marcar tudo"
                  v-on:click="checkAll"
                />
              </th>
              <th
                :style="title[1] ? 'cursor:pointer;' : ''"
                v-on:click="order(title[1])"
                v-for="(title, index) in titles"
                :key="index"
              >
                <div class="d-flex justify-content-between">
                  {{ title[0] }}
                  <span v-if="title[1] == order_selected && direction">
                  <i  v-if="direction == 1" class="fa fa-caret-down"></i>
                  <i v-else class="fa fa-caret-up"></i>
                  </span>
                </div>
              </th>
              <th v-if="actions != 'false'">Ações</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <th v-if="!notDeletable" align="center" class="v-middle">
                <input
                  class="checkbox-delete"
                  type="checkbox"
                  name="registro[]"
                  :value="item.id"
                />
              </th>

              <td
                v-for="(field, index) in [
                  item.id,
                  item.name,
                  item.situation,
                  item.score,
                  item.links,
                ]"
                :key="index"
                class="v-middle"
                :style="isMultiBadge(field) ? 'max-width: 300px' : ''"
              >
                <span v-if="field === null && index != 'text'">{{
                  field
                }}</span>
                <span
                  v-else-if="
                    !field.type && !Array.isArray(field) && index != 'text'
                  "
                  >{{ field }}</span
                >
                <span v-else-if="field.type == 'img' && index != 'text'">
                  <img
                    :src="field.src"
                    style="max-width: 150px; max-height: 150px"
                  />
                </span>
                <span
                  class="mr-2 px-2 py-0 rounded-pill badge"
                  v-else-if="isMultiBadge(field) && index != 'text'"
                  v-for="(campo, index) in field"
                  :key="index"
                  :class="'badge-' + campo.status"
                >
                  <span>{{ campo.text }}</span>
                </span>
                <span
                  v-else-if="field.type == 'badge' && index != 'text'"
                  class="badge"
                  :class="'badge-' + field.status"
                  >{{ field.text }}</span
                >

                <span
                  v-else-if="field.type == 'action' && index != 'text'"
                  v-for="(action, index) in item.actions"
                  :key="index"
                >
                  <a
                    data-toggle="tooltip"
                    class="btn btn-flat ml-10"
                    :class="'btn-' + action.color"
                    :href="action.path"
                    :title="action.label"
                  >
                    <i :class="action.icon"></i> </a
                  >&nbsp;
                </span>
              </td>
              <td v-if="!item.actions && actions != 'false'" class="v-middle">
                <a
                  class="btn btn-flat btn-primary"
                  :href="url + '/' + item.id + '/edit'"
                  data-toggle="tooltip"
                  title="Editar mensagem"
                >
                  <i class="fa fa-pencil"></i>
                </a>
                <a
                  class="btn btn-flat btn-primary"
                  :href="url + '/' + item.id + '/visualize'"
                  data-toggle="tooltip"
                  title="Visualizar"
                >
                  <i class="fa fa-eye"></i>
                </a>
                <button
                  v-on:click="copy(item)"
                  class="btn btn-flat btn-info"
                  data-toggle="tooltip"
                  :title="btnCopy.text"
                  :id="item.id"
                  :disabled="!btnCopy.disabled"
                  type="button"
                >
                  <i class="fa fa-copy"></i>
                </button>
                <a
                  class="btn btn-flat btn-warning"
                  :href="'/dashboard/' + item.status.hash"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Dashboard"
                  v-if="factoryList && item.status.usuario_sistema"
                >
                  <i class="fa fa-bar-chart"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                :colspan="titles.length + 2"
                align="left"
                vertical-align="center"
              >
                <button
                  v-if="!notDeletable"
                  type="submit"
                  class="mv-22 btn btn-flat btn-danger"
                  :disabled="btnDelete.disabled"
                >
                  <i :class="btnDelete.icon"></i>
                  {{ btnDelete.text }}
                </button>
                <slot name="pagination"></slot>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>

      <div v-if="items.data.length == 0" class="callout callout-default">
        <h4>Nenhum registro encontrado!</h4>
      </div>
    </div>
  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
import axios from "axios";

export default {
  props: [
    "filter_prod",
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "notDeletable",
    "actions",
    "factoryList",
    "direction",
    "order_selected",
  ],
  data: function () {
    return {
      deleteItems: [],
      btnDelete: {
        icon: "fa fa-trash",
        text: "Excluir registros selecionados",
        disabled: false,
      },
      btnCopy: {
        icon: "fa fa-copy",
        text: "Copiar texto",
        disabled: true,
      },
      text: "",
    };
  },
  created() {
    if (!this.items.data) {
      this.items.data = this.items;
    }
  },
  methods: {
    copy: async function (item) {
      $("#" + item.id)
        .children("i.fa")
        .removeClass("fa-copy");
      $("#" + item.id)
        .children("i.fa")
        .addClass("fa-spinner fa-pulse");

      this.btnCopy.text = "Copiando";
      this.btnCopy.disabled = false;

      var letters = new Array("3", "a", "W", "Z", "o", "e", "K", "s", "6", "v");
      var date = new Date().toISOString();

      var chars = { "/": "", " ": "", ":": "", ".": "", "-": "", T: "", Z: "" };
      var hash = date.replace(/[:. -]/g, (m) => chars[m]);
      hash = hash.replace(/[TZ]/g, (m) => chars[m]);

      for (var i = 0; i < 14; i++) {
        var num = parseInt(hash[i]);
        hash = hash.replace(hash.charAt(i), letters[num]);
      }

      await axios
        .post("/api/npsShortener", { url: hash, id: item.id })
        .then((response) => {
          if (response.data && response.data.nps_text) {
            let text =
              response.data.nps_text +
              " " +
              response.data.shortened_url.url.shortLink;

            const el = document.createElement("textarea");
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);

            this.btnCopy.text = "Copiar mensagem";
            this.btnCopy.disabled = true;

            $("#" + item.id)
              .children("i.fa")
              .removeClass("fa-spinner fa-pulse");
            $("#" + item.id)
              .children("i.fa")
              .addClass("fa-copy");
          } else if (response.data && !response.data.nps_text) {
            this.$swal({
              title: "Atenção!",
              text: "Não existe um texto de NPS cadastrado para esta unidade.",
              type: "warning",
              showCancelButton: false,
              confirmButtonColor: "#008d4c",
              cancelButtonColor: "#d4d4d4",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((err) => {
          console.log(err);

          this.$swal({
            title: "Oops!",
            text: "Ocorreu um erro ao copiar seu link. Por favor, tente de novo agora ou aguarde alguns instantes e tente novamente.",
            type: "error",
            showCancelButton: false,
            confirmButtonColor: "#008d4c",
            cancelButtonColor: "#d4d4d4",
            confirmButtonText: "Ok",
          });
        });

      
    },
    isMultiBadge(field) {
      if (Array.isArray(field) && field.length > 0) {
        if (field[0].type == "multiBadge") {
          return true;
        }
      }
      return false;
    },
    checkAll: function () {
      var checkboxOrigin = document.getElementById("checkbox-delete-origin");
      var checkboxes = document.getElementsByClassName("checkbox-delete");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = checkboxOrigin.checked;
      }
    },

    order(item) {
      if (!item) {
        return false;
      }
      var url = new URL(location.href);
      var url_search = url.searchParams.get("order");
      var url_direction = url.searchParams.get("direction");
      if (url_direction == null || url_direction == 1 || item != url_search) {
        url_direction = 0;
      } else {
        url_direction = 1;
      }
      location.href =
        window.location.pathname + "?order=" +
        item +
        "&direction=" +
        url_direction;
    },

    confirmDelete: function (e) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir os registros selecionados?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, deletar!",
        cancelButtonText: "Não!",
      }).then((result) => {
        if (result.value) {
          e.target.submit(), (this.btnDelete.icon = "fa fa-spinner fa-pulse");
          this.btnDelete.text = "Excluindo";
          this.btnDelete.disabled = true;
        }
      });
    },
  },
};
</script>