<template>
  <v-row class="ma-0">
    <v-col class="pr-0 pl-0">
      <v-card class="text-center" flat width="100%">
        <v-row class="pl-0 pr-0 ma-0" v-if="!loading">
          <v-col class="pr-0" md="4">
            <v-row justify="center" align="center" class="ma-0">
              <div class="circle d-flex justify-center align-center" :class="value.situation">
                <div>
                  <div class="h4 text-info-white mb-0" style="font-size: 40px;" v-if="show">{{value.score}}</div>
                  <div class="h4 text-info-white mb-0" style="font-size: 40px;" v-else>-</div>
                  <div class="h4 text-info-white mb-0" style="font-size: 11px; margin-top: -9px;">NPS</div>
                </div>
              </div>
            </v-row>            
          </v-col>
          <v-col class="" md="8">
            <v-row class="ma-0 line_nps">
              <v-col class="pa-0 d-flex justify-center align-center box_smile bg-success" cols="12" sm="1" md="1" lg="1">
                <i class="fa fa-smile-o"></i>
              </v-col>
              <v-col class="pa-0 pl-3 d-flex align-center" cols="12" sm="9" md="9" lg="9">
                <span class="font-weight-bold" style="font-size: 14px;" v-if="show">{{value.promoters | number}} {{value.promoters > 1 ? 'Promotores' : 'Promotor'}}</span>
                <span class="font-weight-bold" style="font-size: 14px;" v-else>-</span>
              </v-col>
              <v-col class="pa-0 d-flex align-center text-right justify-content-end" cols="12" sm="2" md="2" lg="2">
                <span class="text-success font-weight-bold subtitle-1 pull-right" v-if="show">{{value.promoters_porc | percent}}</span>
                <span class="text-success font-weight-bold subtitle-1 pull-right" v-else>-</span>
              </v-col>
            </v-row>
            <v-divider class="mb-2 mt-2"></v-divider>
            <v-row class="ma-0 line_nps">
              <v-col class="pa-0 d-flex justify-center align-center box_smile bg-warning" cols="12" sm="1" md="1" lg="1">
                <i class="fa fa-meh-o"></i>
              </v-col>
              <v-col class="pa-0 pl-3 d-flex align-center" cols="12" sm="9" md="9" lg="9">
                <span class="font-weight-bold" style="font-size: 14px;" v-if="show">{{value.passive | number}} {{value > 1 ?'Neutros' : 'Neutro'}}</span>
                <span class="font-weight-bold" style="font-size: 14px;" v-else>-</span>
              </v-col>
              <v-col class="pa-0 d-flex align-center text-right justify-content-end" cols="12" sm="2" md="2" lg="2">
                <span class="text-warning font-weight-bold subtitle-1 pull-right" v-if="show">{{value.passive_porc | percent}}</span>
                <span class="text-warning font-weight-bold subtitle-1 pull-right" v-else>-</span>
              </v-col>
            </v-row>
            <v-divider class="mb-2 mt-2"></v-divider>
            <v-row class="ma-0 line_nps">
              <v-col class="pa-0 d-flex justify-center align-center box_smile bg-danger" cols="12" sm="1" md="1" lg="1">
                <i class="fa fa-frown-o"></i>
              </v-col>
              <v-col class="pa-0 pl-3 d-flex align-center" cols="12" sm="9" md="9" lg="9">
                <span class="font-weight-bold" style="font-size: 14px;" v-if="show">{{value.detractors | number}} {{value.detractors > 1 ? 'Detratores' : 'Detrator'}}</span>
                <span class="font-weight-bold" style="font-size: 14px;" v-else>-</span>
              </v-col>
              <v-col class="pa-0 d-flex align-center text-right justify-content-end" cols="12" sm="2" md="2" lg="2">
                <span class="text-danger font-weight-bold subtitle-1 pull-right" v-if="show">{{value.detractors_porc | percent}}</span>
                <span class="text-danger font-weight-bold subtitle-1 pull-right" v-else>-</span>
              </v-col>
            </v-row>
          </v-col>
          <v-skeleton-loader type="list-item" v-if="loading"></v-skeleton-loader>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
// moment(newValue.data).fromNow()
import axios from "axios";

export default {
  props: ["title", "show", "factory", "date"],

  data() {
    return {
      loading: false,
      value: {
        anterior: []
      }
    };
  },
  watch: {
    date() {
      this.getDados();
    }
  },
  created() {
    this.getDados();
  },
  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: 212,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/getNps", dados)
        .then(response => {
          console.log("getNps");
          console.log(response.data);
          this.value = response.data;
          this.loading = false;
          this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
      }, 120000);
    }
  }
};
</script>

<style lang="scss" scoped>
  .circle{
    width:110px;
    height:110px;
    border-radius:100%;
  }

  .line_nps {
    
    .box_smile {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      // width: 30px;
      height: 30px;
      font-size: 20px;
    }
  }
</style>