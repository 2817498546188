<template>
  <v-row class="mr-0">
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="80">
        <v-card-title style="font-size: 14px;">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">TAXA CONVERSÃO</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{value.taxaConversao | percent}}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-skeleton-loader type="list-item" height="80" v-if="loading"></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="80">
        <v-card-title style="font-size: 14px;">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">TAXA REJEIÇÃO</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{value.taxaRejeicao | percent}}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-skeleton-loader type="list-item" height="80" v-if="loading"></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="80">
        <v-card-title style="font-size: 14px;">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">JORNADA MÉDIA</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{value.jornada}}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-skeleton-loader type="list-item" height="80" v-if="loading"></v-skeleton-loader>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
// moment(newValue.data).fromNow()
import axios from "axios";
import moment from "moment";

export default {
  props: ["title", "show", "factory", "date"],

  data() {
    return {
      loading: false,
      value: {
        anterior: []
      }
    };
  },
  watch: {
    date() {
      this.getDados();
    }
  },
  created() {
    this.getDados();
  },
  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: 212,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/getTaxas", dados)
        .then(response => {
          console.log("getTaxas");
          console.log(response.data);
          this.value = response.data;
          this.loading = false;
          this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
      }, 120000);
    }
  }
};
</script>