<template>
  <v-row>
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="115">
        <v-card-title style="font-size: 14px">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">QTD AVALIAÇÕES</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{
                value.total
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="!loading"
          style="font-size: 0.875rem !important; color: #ffffffb3"
          class="mt-n5"
        >
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" sm="6" lg="6" class="px-0">
              <p>
                <span class="text-info-blue font-weight-bold body-2">{{
                  value.total_ant_porc | percent
                }}</span>
                <span class="text-info-yellow title mx-1">|</span>
                <span class="text-info-blue font-weight-bold body-2">{{
                  value.anterior.total_ant
                }}</span>
              </p>
            </v-col>
          </v-row>
          <v-skeleton-loader
            type="list-item-three-line"
            height="130"
            v-if="loading"
          ></v-skeleton-loader>
        </v-card-actions>
        <v-skeleton-loader
          type="list-item-three-line"
          height="115"
          v-if="loading"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="115">
        <v-card-title style="font-size: 14px">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">QTD REMARCADO</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{
                value.reagendados
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="!loading"
          style="font-size: 0.875rem !important; color: #ffffffb3"
          class="mt-n5"
        >
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" sm="6" lg="6" class="px-0">
              <p>
                <span class="text-info-blue font-weight-bold body-2">{{
                  value.reagendados_ant_porc | percent
                }}</span>
                <span class="text-info-yellow title mx-1">|</span>
                <span class="text-info-blue font-weight-bold body-2">{{
                  value.anterior.reagendados_ant
                }}</span>
              </p>
            </v-col>
          </v-row>
          <v-skeleton-loader
            type="list-item-three-line"
            height="130"
            v-if="loading"
          ></v-skeleton-loader>
        </v-card-actions>
        <v-skeleton-loader
          type="list-item-three-line"
          height="115"
          v-if="loading"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="115">
        <v-card-title style="font-size: 14px">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">NO SHOW</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{
                value.faltas_porc | percent
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="!loading"
          style="font-size: 0.875rem !important; color: #ffffffb3"
          class="mt-n5"
        >
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" sm="6" lg="6" class="px-0">
              <p>
                <span class="text-info-blue font-weight-bold body-2">{{
                  value.faltas_porc_ant | percent
                }}</span>
                <span class="text-info-yellow title mx-1">|</span>
                <span class="text-info-blue font-weight-bold body-2">{{
                  value.anterior.faltas_ant
                }}</span>
              </p>
            </v-col>
          </v-row>
          <v-skeleton-loader
            type="list-item-three-line"
            height="130"
            v-if="loading"
          ></v-skeleton-loader>
        </v-card-actions>
        <v-skeleton-loader
          type="list-item-three-line"
          height="115"
          v-if="loading"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col>
      <v-card class="text-center" flat width="100%" height="115">
        <v-card-title style="font-size: 14px">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">QTD ATENDIMENTO</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{
                value.atendidos
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="!loading"
          style="font-size: 0.875rem !important; color: #ffffffb3"
          class="mt-n5"
        >
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" sm="6" lg="6" class="px-0">
              <p>
                <span class="text-info-blue font-weight-bold body-2">{{
                  value.atendidos_ant_porc | percent
                }}</span>
                <span class="text-info-yellow title mx-1">|</span>
                <span class="text-info-blue font-weight-bold body-2">{{
                  value.anterior.atendidos_ant
                }}</span>
              </p>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-skeleton-loader
          type="list-item-three-line"
          height="115"
          v-if="loading"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
// moment(newValue.data).fromNow()
export default {
  props: ["title", "show"],

  data() {
    return {
      loading: false,
      value: {
        total: "560",
        reagendados: "100",
        faltas: "353",
        atendidos: "400",
        anterior: {
          total_ant: "280",
          reagendados_ant: "20",
          faltas_ant: "88",
          atendidos_ant: "172",
        },
        total_ant_porc: "-0.50",
        reagendados_ant_porc: "-0.80",
        faltas_porc: "0.63",
        faltas_porc_ant: "-0.75",
        atendidos_ant_porc: "-0.57",
      },
    };
  },
};
</script>