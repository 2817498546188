<template>
  <div class="relative" style="background-image: url(./img/porquinhos_v2/fundo.jpg);">
    <div class="iniciar" v-if="!iniciar">
      <v-btn class="botaoBig" @click="startar()" x-large>INICIAR SUPER SEXTA</v-btn>
    </div>
    <v-img class="logo" src="./img/porquinhos_v2/logo.gif" alt="Super Sexta" />
    <div class="area_footer">
      <!-- blink -->
      <marquee width="100%" direction="right" scrollamount="10" behavior="alternate" loop="infinite" class="titulo_super">
        <span v-for="index in 20" :key="index">
          <span class="pl-1"></span> SUPER SEXTA ODONTOTOP <span class="pr-1"></span>| 
        </span>
      </marquee>
    </div>
    <div class="valores_area text-center">
      <div class="odonto">
        OdontoTop
      </div>
      <div class="unidade">
        {{factory.name ? factory.name : 'Franchising'}}
      </div>
      <div class="limita_fundo">
        <div class="valor_fundo">
          <div class="valor_meta_titulo">
            Meta
          </div>
          <div class="valor_meta">
            <b>
              R$
            </b>
            <number
              ref="number1"
              :from="0"
              :to="meta"
              :format="theFormat"
              :duration="5"
              :delay="1"
              easing="Power1.easeOut"/>
          </div>
        </div>
        <div class="w-100 my-3"></div>
        <div class="valor_fundo">
          <div class="valor_atual_titulo">
            Total
          </div>
          <div class="valor_atual">
            <b>
              R$
            </b>
            <number
              ref="number2"
              :from="0"
              :to="valor"
              :format="theFormat"
              :duration="8"
              :delay="1"
              easing="Power1.easeOut"/>
          </div>
        </div>
        <div class="w-100 my-3"></div>
        <div class="valor_fundo" v-if="porquinho < 10 && factory.id == '999999'">
          <div class="valor_atual_titulo">
            Restante
          </div>
          <div class="valor_atual">
            <b>
              R$
            </b>
            <number
              ref="number3"
              :from="0"
              :to="diferenca"
              :format="theFormat"
              :duration="8"
              :delay="1"
              easing="Power1.easeOut"/>
          </div>
        </div>
      </div>
    </div>
    <div class="moedas" :class="moeda ? 'descendo' : 'd-none'">
      <object v-for="index in 10" :key="index" :data="'./img/moedas/' + index + '.svg'" type="image/svg+xml" width="50px">
        <v-img :src="'./img/moedas/' + index + '.svg'" />
      </object>
    </div>
    <div class="moedas_dois" :class="moeda ? 'descendo_dois' : 'd-none'">
      <object v-for="index in 10" :key="index" :data="'./img/moedas/' + index + '.svg'" type="image/svg+xml" width="50px">
        <v-img :src="'./img/moedas/' + index + '.svg'" />
      </object>
    </div>
    <div v-for="index in 10" :key="index" class="porco" :class="porquinho === index ? 'porco_visivel' : ''">
      <object v-if="!finalizou" :data="'./img/porquinhos_v2/' + index + '.png'" type="image/png" class="width_porco">
        <v-img :src="'./img/porquinhos_v2/' + index + '.png'" />
      </object>
      
      <object v-if="finalizou" :data="'./img/porquinhos_v2/gif_terminou.gif'" type="image/png" class="width_porco">
        <v-img :src="'./img/porquinhos_v2/gif_terminou.gif'" />
      </object>
    </div>
    <audio ref="audio" :src="'./img/porquinhos/moedas.mp3'"></audio>
    <audio ref="porco" :src="'./img/porquinhos/porco.mp3'"></audio>
    <audio ref="audiofim" :src="'./img/porquinhos_v2/moedas_fim.mp3'"></audio>
    <audio ref="acabou" :src="'./img/porquinhos/acabou.mp3'"></audio>
    <div class="wrapper" v-if="confetti">
      <div :class="'confetti-' + index" v-for="index in 150" :key="index"></div>
    </div>
    <div class="versao">
      2.0
    </div>
    <div class="dimensao">
      {{porquinho}}-{{windowWidth}}/{{windowHeight}}
    </div>
  </div>
</template>

<script>
import VueNumber from 'vue-number-animation'
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['factory'],
  components: {
    VueNumber
  },
  data() {
    return {
      items: [],
      porquinho: 1,
      moeda: false,
      confetti: false,
      meta: 0,
      valor: 0,
      diferenca: 0,
      iniciar: false,
      finalizou: false,
      valorOriginal: 0,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    },
    startar () {
      this.iniciar = true
      this.loadContent()
    },
    getDados() {
      let dados = {
        factory_id: this.factory.id ? this.factory.id : 999999,
        periodo: moment().format('Y-MM'),
      }
      // verificar o período
      axios
        .post('/api/faturamentoTotalSuperSexta', dados)
        .then(response => {
          console.log(response.data)
          this.items = response.data
          this.meta = response.data.meta_dia
          this.valor = response.data.valor_dia
          this.diferenca = Math.floor(response.data.meta_dia-response.data.valor_dia)
          console.log('Diferença', this.diferenca)
          // this.valor = 1108195160
          let divisao = Math.floor(response.data.meta_dia/10)

          console.log(divisao, 'valor de cada etapa')
          console.log(response.data.valor_dia, 'resultado valor dia')
          // response.data.valor_dia = 1108195160
          if (this.valor > this.valorOriginal) {
            this.valorOriginal = this.valor
            this.moeda = true
            this.$refs.audio.play()
            setTimeout(() => {
              this.$refs.porco.play()
              this.moeda = false
            }, 9000)
          }
          console.log(this.valorOriginal, 'Valor original')
          let etapaAtual = this.porquinho
          if (response.data.valor_dia > response.data.meta_dia && response.data.meta_dia > 0) {
            this.porquinho = 10
            if (!this.finalizou) {
              this.moeda = true
              this.$refs.audiofim.play()
              this.$refs.audio.play()
              console.log('chegou na meta')
              setTimeout(() => {
                this.$refs.porco.play()
                this.moeda = false
              }, 9000)
              setTimeout(() => {
                this.confetti = true
                this.$refs.acabou.play()
              }, 10000)
              this.finalizou = true
            } else {
              this.confetti = true
            }
          } else {
            // divisao ou response.data.meta_dia
            let etapa = 0
            if (response.data.valor_dia >= divisao * 1 && response.data.valor_dia < divisao * 2) {
              etapa = 10
            }
            if (response.data.valor_dia >= divisao * 2 && response.data.valor_dia < divisao * 3) {
              etapa = 9
            }
            if (response.data.valor_dia >= divisao * 3 && response.data.valor_dia < divisao * 4) {
              etapa = 8
            }
            if (response.data.valor_dia >= divisao * 4 && response.data.valor_dia < divisao * 5) {
              etapa = 7
            }
            if (response.data.valor_dia >= divisao * 5 && response.data.valor_dia < divisao * 6) {
              etapa = 6
            }
            if (response.data.valor_dia >= divisao * 6 && response.data.valor_dia < divisao * 7) {
              etapa = 5
            }
            if (response.data.valor_dia >= divisao * 7 && response.data.valor_dia < divisao * 8) {
              etapa = 4
            }
            if (response.data.valor_dia >= divisao * 8 && response.data.valor_dia < divisao * 9) {
              etapa = 3
            }
            if (response.data.valor_dia > divisao * 9 && response.data.valor_dia < divisao * 10) {
              etapa = 2
            }
            if (response.data.valor_dia > divisao * 10) {
              etapa = 1
            }
            console.log(etapa, 'ETAPA')
            switch (etapa) {
              case 1:
                this.finalizou = true
                this.porquinho = 10
                this.moeda = true
                this.$refs.audio.play()
                console.log('wiiiiiiiiiii acabou, etapa 10')
                setTimeout(() => {
                  this.$refs.audiofim.play()
                  this.$refs.porco.play()
                  this.moeda = false
                }, 9000)
                setTimeout(() => {
                  this.confetti = true
                  this.$refs.acabou.play()
                }, 10000)
              break
              case 2:
                this.porquinho = 9
                console.log('etapa 9')
                // if (etapaAtual === 9) {
                //   this.moeda = true
                //   this.$refs.audio.play()
                //   setTimeout(() => {
                //     this.$refs.porco.play()
                //     this.moeda = false
                //   }, 9000)
                //   console.log('etapa 10')
                // }
              break
              case 3:
                this.porquinho = 8
                // if (etapaAtual === 8) {
                //   this.moeda = true
                //   this.$refs.audio.play()
                //   setTimeout(() => {
                //     this.$refs.porco.play()
                //     this.moeda = false
                //   }, 9000)
                // }
                console.log('etapa 8')
              break
              case 4:
                this.porquinho = 7
                // if (etapaAtual === 7) {
                //   this.moeda = true
                //   this.$refs.audio.play()
                //   setTimeout(() => {
                //     this.$refs.porco.play()
                //     this.moeda = false
                //   }, 9000)
                // }
                console.log('etapa 7')
              break
              case 5:
                this.porquinho = 6
                // if (etapaAtual === 6) {
                //   this.moeda = true
                //   this.$refs.audio.play()
                //   setTimeout(() => {
                //     this.$refs.porco.play()
                //     this.moeda = false
                //   }, 9000)
                // }
                console.log('etapa 6')
              break
              case 6:
                this.porquinho = 5
                // if (etapaAtual === 5) {
                //   this.moeda = true
                //   this.$refs.audio.play()
                //   setTimeout(() => {
                //     this.$refs.porco.play()
                //     this.moeda = false
                //   }, 9000)
                // }
                console.log('etapa 5')
              break
              case 7:
                this.porquinho = 4
                // if (etapaAtual === 4) {
                //   this.moeda = true
                //   this.$refs.audio.play()
                //   setTimeout(() => {
                //     this.$refs.porco.play()
                //     this.moeda = false
                //   }, 9000)
                // }
                console.log('etapa 4')
              break
              case 8:
                this.porquinho = 3
                // if (etapaAtual === 3) {
                //   this.moeda = true
                //   this.$refs.audio.play()
                //   setTimeout(() => {
                //     this.$refs.porco.play()
                //     this.moeda = false
                //   }, 9000)
                // }
                console.log('etapa 3')
              break
              case 9:
                this.porquinho = 2
                // if (etapaAtual === 2) {
                //   this.moeda = true
                //   this.$refs.audio.play()
                //   setTimeout(() => {
                //     this.$refs.porco.play()
                //     this.moeda = false
                //   }, 9000)
                // }
                console.log('etapa 2')
              break;
              case 10:
                this.porquinho = 1
                // if (etapaAtual === 1) {
                //   this.moeda = true
                //   this.$refs.audio.play()
                //   setTimeout(() => {
                //     this.$refs.porco.play()
                //     this.moeda = false
                //   }, 9000)
                // }
                console.log('etapa 1')
              break
              default:
                this.porquinho = 1
                console.log('etapa default')
              break
            }
          }
          this.timeoutAtt()
          // if((response.data.valor_dia-valor) >= 5000) {
          //   this.aumentaFuncao()
          // }
        })
        .catch(err => {
          console.log(err)
        })
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true)
      }, 120000)
      // }, 10000)
      // 120000
    },
    theFormat(number) {
      console.log(number)
      // return number.toString().split('.')[0]
      // return parseInt(number.toString().replace('.', ''), 10)
      const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      return new Intl.NumberFormat('pt-BR', options).format(parseFloat(number))
    },
    loadContent () {
      this.getDados()
    },
    aumentaFuncao () {
      this.valor = this.valor + 3000000
      if (this.porquinho >= 10) {
        this.porquinho = 10
        // this.confetti = false
      }
      if (this.porquinho == 10) {
        setTimeout(() => {
          this.confetti = true
          this.$refs.acabou.play()
        }, 10000)
      }
      this.porquinho++
      this.moeda = true
      this.$refs.audio.play()
      setTimeout(() => {
        this.$refs.porco.play()
        this.moeda = false
      }, 9000)
    }
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize)
  },
  created() {
    window.addEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
@import "resources/assets/sass/website/_variables.scss";
.relative {
  background-color: rgb(254 255 255);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
}
.fundo {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  bottom: -50px;
  transform: scaleX(-1);
  height: 100vh;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}
.area_footer {
  bottom: 0;
  background: #ffc911;
  height: 35px;
  position: absolute;
  width: 100%;
  z-index: 20;
}
.logo {
  position: absolute;
  right: 50px;
  top: 0px;
  max-width: 300px;
}
.valores_area {
  position: absolute;
  left: 0px;
  top: 100px;
  z-index: 10;
}
.titulo_super {
  color: #102945;
  left: 0;
  font-family: 'Raleway';
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  padding: 0 5px;
  top: 8px;
}
.row_vh {
  height: calc(100vh - 70px);
  padding-left: 20px;
  padding-right: 20px;
}
.valor_atual {
  color: #fff;
  font-family: 'Raleway';
  font-size: 46px;
  font-weight: 900;
  line-height: normal;
  min-width: 340px;
  b {
    font-size: 36px;
  }
  @media (max-width: 1480px){
    font-size: 26px;
    b {
    font-size: 24px;
    }
  }
  @media (max-width: 1200px){
    font-size: 20px;
    b {
      font-size: 18px;
    }
  }
}
.valor_meta {
  color: #fff;
  font-family: 'Raleway';
  font-size: 46px;
  font-weight: 900;
  line-height: normal;
  b {
    font-size: 36px;
  }
  @media (max-width: 1480px){
    font-size: 26px;
    b {
    font-size: 24px;
    }
  }
  @media (max-width: 1200px){
    font-size: 20px;
    b {
      font-size: 18px;
    }
  }
}
.limita_fundo {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 450px;
}
.valor_fundo {
  background: rgba(25, 55, 88, 0.8);
  border: 2px #153d69 solid;
  border-radius: 10px;
  min-width: 380px;
  padding: 10px;
  width: fit-content;
}
.odonto {
  color: white;
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.unidade {
  color: white;
  font-family: 'Raleway';
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.valor_atual_titulo {
  color: white;
  font-family: 'Raleway';
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  margin-top: 5px;
  text-transform: uppercase;
}
.valor_meta_titulo {
  color: white;
  font-family: 'Raleway';
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  margin-top: 5px;
  text-transform: uppercase;
}
.blink {
  animation: blinker 3s linear infinite;
}
@keyframes blinker {
  50% {
    color: #000;
  }
}
.botao {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99999;
}
.novo {
  right: 687px;
  bottom: 411px;
  @media (max-width: 1480px){
    right: 570px;
  }
  @media (max-width: 1300px){
    right: 419px;
  }
  @media (max-width: 1100px){
    bottom: 374px;
    right: 348px;
  }
}
@keyframes piscar {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.porco_visivel {
  opacity: 1!important;
  transition: all 1s;
}
.width_porco {
  bottom: 5px;
  position: absolute;
  left: calc(45%);
  width: 500px;
  @media (max-width: 991px){
    width: 380px;
  }
  // @media (max-width: 1100px){
  //   height: 600px;
  //   bottom: 70px;
  //   right: -100px;
  // }
}
.porco {
  top: 40%;
  height: 400px;
  opacity: 0;
  position: absolute;
  transition: all 1s;
  width: 100%;
  z-index: 10;
}
.moedas {
  position: absolute;
  right: calc(45% - 70px);
  width: 70px;
  z-index: 5;
  @media (max-width: 1480px){
    right: 450px;
  }
  @media (max-width: 1300px){
    right: 310px;
  }
  @media (max-width: 1100px){
    right: 250px;
  }
  @media (max-width: 991px){
    right: 150px;
  }
}
.moedas_dois {
  position: absolute;
  right: calc(45% - 70px);
  width: 70px;
  z-index: 5;
  @media (max-width: 1480px){
    right: 450px;
  }
  @media (max-width: 1300px){
    right: 310px;
  }
  @media (max-width: 1100px){
    right: 250px;
  }
  @media (max-width: 991px){
    right: 150px;
  }
}
.descendo_dois {
  animation: desce_dois linear 3s;
  animation-iteration-count: 3;
}
@keyframes desce_dois {
  from {
    top: -850px;
  }
  to {
    top: -50px;
  }
}
.descendo {
  animation: desce linear 3s;
  animation-iteration-count: 3;
}
@keyframes desce {
  from {
    top: -800px;
  }
  to {
    top: -50px;
  }
}

body {
  margin: 0;
  overflow: hidden;
}

.wrapper {
  top: 0;
  left: 0;
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 999;
}

[class|="confetti"] {
  position: absolute;
}

$colors: (#d13447, #14aa00, #29235c);

@for $i from 0 through 150 {
  $w: random(20);
  $l: random(200);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l+"%");
    opacity: random() + 0.5;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote(2+random()+"s") unquote(random()+"s") infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l+random(15)+"%");
    }
  }
}
.nuvem_passa {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 250px;
  left: -130px;
  position: absolute;
  top: 120px;
  width: 220px;
  animation: nuvemPassa 70s ease infinite;
}
@keyframes nuvemPassa {
  0% {
    left: -130px;
    top: 120px;
  }
  25% {
    left: 15vw;
    top: 80px;
  }
  50% {
    left: 60vw;
    top: 130px;
  }
  75% {
    left: 70vw;
    top: 0px;
  }
  100% {
    left: 110vw;
    top: -700px;
  }
}
.nuvem_passa_dois {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 250px;
  left: -250px;
  position: absolute;
  top: 120px;
  width: 250px;
  animation: nuvemPassaDois 80s ease infinite;
}
@keyframes nuvemPassaDois {
  0% {
    left: -250px;
    top: 120px;
  }
  25% {
    left: 25vw;
    top: -30px;
  }
  50% {
    left: 45vw;
    top: 100px;
  }
  75% {
    left: 80vw;
    top: 20px;
  }
  100% {
    left: 110vw;
    top: 350px;
  }
}
.nuvem_passa_tres {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  left: -200px;
  position: absolute;
  top: 120px;
  width: 200px;
  animation: nuvemPassaTres 110s ease infinite;
}
@keyframes nuvemPassaTres {
  0% {
    left: -200px;
    top: 180px;
  }
  25% {
    left: 30vw;
    top: -90px;
  }
  50% {
    left: 35vw;
    top: 120px;
  }
  75% {
    left: 30vw;
    top: 320px;
  }
  100% {
    left: 40vw;
    top: -320px;
  }
}
.nuvem_passa_quatro {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  left: -200px;
  position: absolute;
  top: 120px;
  width: 100px;
  animation: nuvemPassaQuatro 135s ease infinite;
}
@keyframes nuvemPassaQuatro {
  0% {
    left: -200px;
    top: 100px;
  }
  25% {
    left: 40vw;
    top: -90px;
  }
  50% {
    left: 50vw;
    top: 60px;
  }
  75% {
    left: 37vw;
    top: 200px;
  }
  100% {
    left: 110vw;
    top: 20px;
  }
}
.nuvem_passa_cinco {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 60px;
  left: -100px;
  position: absolute;
  top: 180px;
  width: 60px;
  animation: nuvemPassaCinco 150s ease infinite;
}
@keyframes nuvemPassaCinco {
  0% {
    left: -100px;
    top: 180px;
  }
  25% {
    left: 20vw;
    top: 20px;
  }
  50% {
    left: 25vw;
    top: 30px;
  }
  75% {
    left: 60vw;
    top: 120px;
  }
  100% {
    left: 110vw;
    top: 120px;
  }
}
.nuvem_passa_seis {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  left: -120px;
  position: absolute;
  top: 120px;
  width: 50px;
  animation: nuvemPassaSeis 180s ease infinite;
}
@keyframes nuvemPassaSeis {
  0% {
    left: -120px;
    top: 120px;
  }
  25% {
    left: 25vw;
    top: 150px;
  }
  50% {
    left: 45vw;
    top: 80px;
  }
  75% {
    left: 78vw;
    top: 70px;
  }
  100% {
    left: 110vw;
    top: 100px;
  }
}
.moeda_topo_esquerda {
  bottom: 340px;
  left: -120px;
  position: absolute;
  z-index: 16;
  object {
    width: 200px;
  }
}
.moeda_canto_esquerda {
  bottom: 90px;
  left: -151px;
  position: absolute;
  z-index: 16;
  object {
    width: 250px;
  }
}
.moeda_quina_esquerda {
  bottom: -250px;
  left: -150px;
  position: absolute;
  z-index: 15;
  object {
    width: 400px;
  }
}
.moeda_quina_direita {
  bottom: -270px;
  position: absolute;
  right: -170px;
  z-index: 15;
  object {
    width: 400px;
  }
}
.moedas_chao_left {
  bottom: 40px;
  left: calc(50% - 300px);
  position: absolute;
  transform: rotate(-5deg);
  z-index: 15;
  object {
    width: 80px;
  }
}
.moedas_chao {
  bottom: 10px;
  left: calc(50% - 100px);
  position: absolute;
  transform: rotate(-5deg);
  z-index: 15;
  object {
    margin: 0 -25px;
    width: 80px;
    &:nth-of-type(2) {
      position: relative;
      z-index: 2;
    }
    &:nth-last-of-type(1) {
      margin: 0px 0 -20px -45px;
      position: relative;
      z-index: -1;
    }
  }
}
.moeda_canto_direita {
  bottom: 100px;
  position: absolute;
  right: -90px;
  z-index: 15;
  object {
    width: 200px;
  }
}
.iniciar {
  align-items: center;
  background: rgba(159,159,159,0.8);
  display: flex;
  justify-content: center;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 999;
  .botaoBig {
    font-size: 40px!important;
    height: 120px!important;
  }
}
.versao {
  color: #fff;
  font-size: 12px;
  bottom: 33px;
  left: 5px;
  position: absolute;
  z-index: 20;
}
.dimensao {
  color: #fff;
  font-size: 12px;
  bottom: 33px;
  position: absolute;
  z-index: 20;
  right: 5px;
}
</style>
