<template>
  <v-row>
    <v-col>
      <infinite-slide-bar
        :duration="duration()"
        :delay="!loading ? '10s' : '50000s'"
      >
        <v-slide-group class="py-0">
          <v-slide-item
            v-for="(item, index) in items"
            :key="index"
            class="mr-2"
          >
            <v-list
              two-line
              width="380px"
              height="100px"
              class="pa-0 d-flex align-item-center"
              justify="center"
            >
              <v-list-item style="height: 100px">
                <v-avatar size="60" color="grey" class="mr-4">
                  <v-img
                    :src="item.avatar"
                    :lazy-src="item.avatar"
                    v-if="item.avatar"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                          size="20"
                          width="2"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-icon dark v-else>mdi-account</v-icon>
                </v-avatar>
                <v-list-item-content class="pa-0" width="100%">
                  <v-list-item-title class="pl-0">
                    <div
                      class="title"
                      style="
                        font-size: 16px !important;
                        line-height: 20px !important;
                      "
                    >
                      {{ item.user_name }}
                    </div>
                    <span
                      class="text-info-yellow font-weight-bold title mt-1"
                      v-if="show"
                      >{{
                        item.valor
                          | currency("", 0, {
                            thousandsSeparator: ".",
                            decimalSeparator: ",",
                          })
                      }}</span
                    >
                    <span
                      class="text-info-yellow font-weight-bold title mt-1"
                      v-else
                      >-</span
                    >
                    <!-- <span class="text-info-yellow title mx-1">|</span>
                    <span class="text-info-yellow font-weight-bold title">{{item.contibuition}}%</span> -->
                  </v-list-item-title>
                  <v-list-item-subtitle style="height: 13px">
                    <v-row
                      justify="start"
                      align="center"
                      class="pl-0 mr-0 ml-0"
                    >
                      <v-col class="pa-0 pr-2" v-if="item.gap">
                        <span style="font-size: 12px">GAP:</span>
                        <span
                          class="text-info-blue font-weight-bold overline"
                          >{{ item.gap | percent }}</span
                        >
                        <span class="text-info-yellow title">|</span>
                        <span
                          class="text-info-blue font-weight-bold overline"
                          v-if="show"
                          >{{
                            item.gap_valor
                              | currency("", 0, {
                                thousandsSeparator: ".",
                                decimalSeparator: ",",
                              })
                          }}</span
                        >
                        <span
                          class="text-info-blue font-weight-bold overline"
                          v-else
                          >-</span
                        >
                      </v-col>
                      <v-col class="pa-0" v-if="item.valor_ant">
                        <span style="font-size: 12px">Ant:</span>
                        <span
                          class="text-info-blue font-weight-bold overline"
                          >{{ item.porc_ant | percent }}</span
                        >
                        <span class="text-info-yellow title">|</span>
                        <span
                          class="text-info-blue font-weight-bold overline"
                          v-if="show"
                          >{{
                            item.valor_ant
                              | currency("", 0, {
                                thousandsSeparator: ".",
                                decimalSeparator: ",",
                              })
                          }}</span
                        >
                        <span
                          class="text-info-blue font-weight-bold overline"
                          v-else
                          >-</span
                        >
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <!-- <span
                  v-if="factory.id == 999999"
                  class="badge badge-dark"
                  style="
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    font-size: 10px;
                  "
                  >{{ item.unidade }}</span
                > -->
              </v-list-item>
            </v-list>
          </v-slide-item>
        </v-slide-group>
      </infinite-slide-bar>
      <!-- <div v-else>
        <v-slide-group class="py-0">
          <v-slide-item v-for="(item, index) in 5" :key="index" class="mr-2">
            <v-list two-line width="320px">
              <v-list-item>
                <v-list-item-content class="pa-0" width="100%" height="100%">
                  <v-skeleton-loader
                    type="list-item-two-line"
                    height="75"
                  ></v-skeleton-loader>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-slide-item>
        </v-slide-group>
      </div> -->
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["show"],
  data: () => ({
    loading: false,
    items: [
      {
        valor: 252000,
        data: "2021-10-01",
        user_id: 1,
        user_name: "Dra Avaliadora",
        avatar:"",
        unidade: "Exemplo",
        valor_ant: 220000,
        porc_ant: "-0.10",
      },
      {
        valor: 5000,
        data: "2021-10-01",
        user_id: 2,
        user_name: "Dra Clinica Geral",
        avatar:"",
        unidade: "Exemplo",
        valor_ant: 5500,
        porc_ant: "0.10",
      },
      {
        valor: 20000,
        data: "2021-10-01",
        user_id: 3,
        user_name: "Dentista 1",
        avatar:"",
        unidade: "Exemplo",
        valor_ant: 16000,
        porc_ant: "-0.20",
      },
      {
        valor: 45000,
        data: "2021-10-01",
        user_id: 4,
        user_name: "Dentista 2",
        avatar:"",
        unidade: "Exemplo",
        valor_ant: 31500,
        porc_ant: "-0.30",
      },
      {
        valor: 26450,
        data: "2021-10-04",
        user_id: 5,
        user_name: "Dentista 3",
        avatar:"",
        unidade: "Exemplo",
        valor_ant: 28566,
        porc_ant: "+0.08",
      },
      {
        valor: 141536,
        data: "2021-10-01",
        user_id: 6,
        user_name: "Dentista 4",
        avatar:"",
        unidade: "Exemplo",
      },
      {
        valor: 93734,
        data: "2021-10-04",
        user_id: 7,
        user_name: "Dentista 5",
        avatar:"",
        unidade: "Exemplo",
        valor_ant: 94671.34,
        porc_ant: "+0.01",
      },
      {
        valor: 41563,
        data: "2021-10-01",
        user_id: 8,
        user_name: "Dentista 6",
        avatar:"",
        unidade: "Exemplo",
      },
      {
        valor: 22320,
        data: "2021-10-01",
        user_id: 9,
        user_name: "Dentista 7",
        avatar:"",
        unidade: "Exemplo",
      },
      {
        valor: 1350,
        data: "2021-10-07",
        user_id: 10,
        user_name: "Dentista 8",
        avatar:"",
        unidade: "Exemplo",
      },
    ],
  }),

  methods: {
    duration() {
      if (this.items.length >= 6) {
        // return "800s";
        return this.items.length * 7 + "s";
      } else {
        return "4984984984984s";
      }
    },
  },
};
</script>