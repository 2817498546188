/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Popper = require('popper.js');

window.$ = window.jQuery = require('jquery');

import ChartDataLabels from "chartjs-plugin-datalabels";

import InfiniteSlideBar from 'vue-infinite-slide-bar'
Vue.component('infinite-slide-bar', InfiniteSlideBar)

require('admin-lte');
require("bootstrap");

window.Vue = require("vue");

import Vue from "vue";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import money from "v-money";
Vue.use(money, { precision: 4 });

import Multiselect from "vue-multiselect";

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask);

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import Vuetify from 'vuetify';
Vue.use(Vuetify);

import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

import { LMap, LTileLayer, LMarker, LControl, LPopup, LLayerGroup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
Vue.component('l-map', LMap);
Vue.component('l-popup', LPopup);
Vue.component('l-layer-group', LLayerGroup);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-control', LControl);

// import LHeatmap from 'vue2-leaflet-heatmap';
import LeafletHeatmap from './Vue2LeafletHeatmap';
Vue.component('LeafletHeatmap', LeafletHeatmap)
// Vue.component('l-heatmap', LeafletHeatmap);

import LControlFullscreen from 'vue2-leaflet-fullscreen';
Vue.component('l-control-fullscreen', LControlFullscreen);

import "flatpickr";
global.rangePlugin = require("flatpickr/dist/plugins/rangePlugin");
import "flatpickr/dist/flatpickr.min.css";
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.component('multiselect', Multiselect);
Vue.component('multi-select', require("./cms/UIMultiSelect.vue").default);
Vue.component('goal-form', require("./cms/GoalForm.vue").default);
Vue.component("content-header", require("./cms/ContentHeader.vue").default);
Vue.component("data-table", require("./cms/DataTable.vue").default);
Vue.component("data-table-goals", require("./cms/DataTableGoals.vue").default);
Vue.component("data-table-nps", require("./cms/DataTableNPS.vue").default);
Vue.component("data-table-accreditation", require("./cms/DataTableAccreditation.vue").default);
Vue.component("data-table-visualize", require("./cms/DataTableVisualize.vue").default);
Vue.component("tabs", require("./cms/Tabs.vue").default);
Vue.component("ui-form", require("./cms/UIForm.vue").default);
Vue.component("ui-select", require("./cms/UISelect.vue").default);
Vue.component("ui-textarea", require("./cms/UITextarea.vue").default);
Vue.component("ui-money", require("./cms/UIMoney.vue").default);
Vue.component("ui-phone", require("./cms/UIPhone.vue").default);
Vue.component("ui-mask-input", require("./cms/UIMaskInput.vue").default);
Vue.component("alert", require("./cms/Alert.vue").default);
Vue.component("checkboxes", require("./cms/Checkboxes.vue").default);
Vue.component("radios", require("./cms/Radios.vue").default);
Vue.component("dropdown-list", require("./cms/DropdownList.vue").default);
Vue.component("dropdown-events", require("./cms/DropdownEvents.vue").default);
Vue.component("cidade-bairro", require("./cms/Cidade-bairro.vue").default);

Vue.component("the-gallery", require("./front/TheGallery.vue").default);
Vue.component("card-novo-orcamento", require("./front/CardNovoOrcamento.vue").default);
Vue.component("card-faturamento-total", require("./front/CardFaturamentoTotal.vue").default);
Vue.component("card-faturamento-franquia", require("./front/CardFaturamentoFranquia.vue").default);
Vue.component("card-grafico-metas", require("./front/CardGraficoMetas.vue").default);
Vue.component("card-grafico-produtos", require("./front/CardGraficoProdutos.vue").default);
Vue.component("card-grafico-produtos-pequeno", require("./front/CardGraficoProdutosPequeno.vue").default);
Vue.component("card-jornada-produto", require("./front/CardJornadaProduto.vue").default);
Vue.component("card-grafico-avaliacoes", require("./front/CardGraficoAvaliacoes.vue").default);
Vue.component("card-grafico-ligacoes-hora", require("./front/CardGraficoCallsHours.vue").default);
Vue.component("card-grafico-ligacoes-mes", require("./front/CardGraficoCallsMonth.vue").default);
Vue.component("card-avaliacoes", require("./front/CardAvaliacoes.vue").default);
Vue.component("card-usuarios", require("./front/CardUsuarios.vue").default);
Vue.component("card-unidades", require("./front/CardUnidades.vue").default);
Vue.component("card-graficos-unidades-pequeno", require("./front/CardGraficoUnidadesPequeno.vue").default);
Vue.component("card-ranking", require("./front/CardRanking.vue").default);
Vue.component("card-mapa", require("./front/CardMapa.vue").default);
Vue.component("card-maquina-venda", require("./front/CardMaquinaVenda.vue").default);
Vue.component("card-funil", require("./front/CardFunil.vue").default);
Vue.component('dash-board', require("./front/Dashboard.vue").default);
Vue.component('dash-board-geral', require("./front/DashboardGeral.vue").default);
Vue.component('dashboard-ligacoes-geral', require("./front/DashboardLigacoesGeral.vue").default);
Vue.component('card-ligacao-usuario', require("./front/CardLigacaoUsuario.vue").default);
Vue.component('card-agendamento-unidades', require("./front/CardAgendamentosUnidades.vue").default);
Vue.component('card-taxas-geral', require("./front/CardTaxasGeral.vue").default);
Vue.component('card-aquisicao-clientes', require("./front/CardAquisicaoClientes.vue").default);
Vue.component('card-funil-estatisticas', require("./front/CardFunilEstatisticas.vue").default);
Vue.component('card-nps', require("./front/CardNps.vue").default);

//FAKE DASH

Vue.component('dash-board-fake', require("./front/fake_dash/Dashboard.vue").default);
Vue.component("card-novo-orcamento-fake", require("./front/fake_dash/CardNovoOrcamento.vue").default);
Vue.component("card-faturamento-total-fake", require("./front/fake_dash/CardFaturamentoTotal.vue").default);
Vue.component("card-ranking-fake", require("./front/fake_dash/CardRanking.vue").default);
Vue.component("card-grafico-metas-fake", require("./front/fake_dash/CardGraficoMetas.vue").default);
Vue.component("card-usuarios-fake", require("./front/fake_dash/CardUsuarios.vue").default);
Vue.component("card-grafico-produtos-fake", require("./front/fake_dash/CardGraficoProdutos.vue").default);
Vue.component("card-grafico-produtos-pequeno-fake", require("./front/fake_dash/CardGraficoProdutosPequeno.vue").default);
Vue.component("card-mapa-fake", require("./front/fake_dash/CardMapa.vue").default);
Vue.component("card-jornada-produto-fake", require("./front/fake_dash/CardJornadaProduto.vue").default);
Vue.component("card-grafico-avaliacoes-fake", require("./front/fake_dash/CardGraficoAvaliacoes.vue").default);
Vue.component("card-avaliacoes-fake", require("./front/fake_dash/CardAvaliacoes.vue").default);
Vue.component('card-funil-estatisticas-fake', require("./front/fake_dash/CardFunilEstatisticas.vue").default);
Vue.component('card-taxas-geral-fake', require("./front/fake_dash/CardTaxasGeral.vue").default);
Vue.component('card-aquisicao-clientes-fake', require("./front/fake_dash/CardAquisicaoClientes.vue").default);
Vue.component('card-nps-fake', require("./front/fake_dash/CardNps.vue").default);

Vue.component('super-friday', require("./front/friday/SuperFriday.vue").default);
Vue.component('super-friday-unitys', require("./front/friday/SuperFridayUnitys.vue").default);
Vue.component('dash-months', require("./front/friday/DashMonths.vue").default);
Vue.component('dash-thread', require("./front/friday/DashThread.vue").default);
Vue.component('dash-target-time', require("./front/friday/DashTargetTime.vue").default);


const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: {
      dark: {
        primary: '#00ffff',
        secondary: '#ffc107',
        // background: '#111519',
        // sheet: '#1d2228',
        background: '#001f2d',
        sheet: '#012433',
      },
    },
  },
})
export default vuetify;

const app = new Vue({
  el: "#app",
  vuetify: new Vuetify(),
});

$(function () {
  $('.form-research').on('submit', function (e) {
    $('.submit-button').attr('disabled', true);
    document.getElementsByClassName("submit-button")[0].style.opacity = "0.5";
    document.getElementsByClassName("submit-button")[0].style.cursor = "auto";
    document.getElementsByClassName("submit-button")[0].style.pointerEvents = "none";
    if (!$("input:radio[name='nota']").is(':checked')) {
      e.preventDefault();
      $('.helper-text-form').removeClass('d-none');
      $('.submit-button').attr('disabled', false);
      document.getElementsByClassName("submit-button")[0].style.opacity = "1";
      document.getElementsByClassName("submit-button")[0].style.cursor = "pointer";
      document.getElementsByClassName("submit-button")[0].style.pointerEvents = "auto";
    }
  });
});

flatpickr("#inputDate", {
  enableTime: false,
  altInput: true,
  locale: 'pt',
  maxDate: "today",
  dateFormat: "Y-m-d",
  altFormat: "d/m/Y",
  disableMobile: true,
});
