<template>
  <div>
    <v-card class="text-center" flat width="100%" height="165">
      <v-card-title class="title" style="font-size: 16px;">
        <v-row justify="center" align="center">
          <v-col>
            <span class="text-center">{{title}}
              <span v-if="items.franchises && items.franchises.length > 0">({{items.franchises.length}})</span>
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row justify="center" align="center" v-if="!loading">
          <p class="overline mr-1">total</p>
          <p class="text-info-blue title mx-1">|</p>
          <p class="text-info-yellow font-weight-bold body-1" v-if="show">{{ items.faturamentoMes | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}</p>
          <p class="text-info-yellow font-weight-bold body-1" v-else>-</p>
        </v-row>
        <v-row class="mt-n6" justify="center" align="center" v-if="!loading">
          <p class="overline mr-1">média</p>
          <p class="text-info-blue title mx-1">|</p>
          <p class="text-info-yellow font-weight-bold body-1" v-if="show">{{ items.faturamentoMedio | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}</p>
          <p class="text-info-yellow font-weight-bold body-1" v-else>-</p>
        </v-row>

        <v-skeleton-loader type="list-item-three-line" height="180" v-if="loading"></v-skeleton-loader>
      </v-card-text>
      <!-- <v-card-actions style="font-size: .875rem !important; color: #FFFFFFB3;" v-if="!loading">
        <v-row justify="center" align="center" class="mt-n6">
          <v-col cols="12" md="6" sm="6" lg="6" class="px-0" v-if="items.gap">
            
          </v-col>
          <v-col cols="12" md="6" sm="6" lg="6" class="px-0" v-if="items.valor_ant">
            <span>Anterior</span>
            <p>
              <span class="text-info-blue font-weight-bold body-2" v-if="items.gap_ant">{{items.gap_ant}}%</span>
              <span class="text-info-yellow title mx-1" v-if="items.gap_ant">|</span>
              <span
                class="text-info-blue font-weight-bold body-2"
                v-if="show"
              >{{items.valor_ant | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}</span>
              <span class="text-info-blue font-weight-bold body-2" v-else>-</span>
            </p>
          </v-col>
        </v-row>
      </v-card-actions> -->
    </v-card>
  </div>
</template>
<script>
// moment(newValue.data).fromNow()
import axios from "axios";
import moment from "moment";

export default {
  props: ["title", "show", "factory", "date"],

  data() {
    return {
      loading: false,
      value: [],
      items: []
    };
  },
  methods: {
    getDados(notLoading) {
      console.log('moment().format("Y-MM")');
      console.log(moment().format("Y-MM"));
      console.log("this.date");
      console.log(this.date);
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        periodo: this.date,
      };
      console.log('DADOS => ', dados);
      let valor = this.items.valor 
      axios
        .post("/api/faturamentoFranquia", dados)
        .then(response => {
          console.log("getFaturamentoFranquia");
          console.log(response.data);
          this.items = response.data;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  watch: {
    date() {
      this.getDados();
    }
  },
  created() {
    this.getDados();
  }
};
</script>