<template>
  <div>
    <v-card class="text-center" flat width="100%" height="740">
      <v-card-title class="title" style="font-size: 16px;">
        <v-row justify="center" align="center">
          <v-col>
            <span class="text-center">{{title}}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="color: white;max-height: 670px; overflow-y:auto;" class="mt-3 custom_scroll">
        <div v-for="(item, index) in products" :key="index">
          <v-row align="center" justify="start" class="text-center mt-0">
            <v-col class="pa-0 text-left pl-4" cols="12" sm="12" md="2" lg="2">
              <span class="body-2">{{ item.nome_produto }}</span>
            </v-col>
            <v-col class="pa-0" cols="12" sm="12" md="3" lg="3">
              <p>Dias</p>
              <span class="text-info-blue font-weight-bold subtitle-1">{{ item.dias }}</span>
            </v-col>
            <v-col class="pa-0" cols="12" sm="12" md="3" lg="3">
              <p>Funil</p>
              <span
                class="text-info-yellow font-weight-bold subtitle-1"
                v-if="show"
              >{{ item.funil | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}</span>
              <span class="text-info-yellow font-weight-bold subtitle-1" v-else>-</span>
            </v-col>
            <v-col class="pa-0" cols="12" sm="12" md="4" lg="4">
              <p>Janela do Mês</p>
              <span
                class="text-info-yellow font-weight-bold subtitle-1"
                v-if="show"
              >{{ item.janela | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}</span>
              <span class="text-info-yellow font-weight-bold subtitle-1" v-else>-</span>
              <div style="margin-top: -7px;">
                <span class="" style="font-size:11px;" v-if="show">{{ item.meta | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}</span>
                <span class="" style="font-size:11px;" v-else>-</span>
              </div>
            </v-col>
          </v-row>
          <v-divider v-if="index+1 != products.length" class="mb-2"></v-divider>
        </div>        
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// moment(newValue.data).fromNow()
import axios from "axios";
import moment from "moment";

export default {
  props: ["title", "show", "factory", "date"],

  data() {
    return {
      loading: false,
      value: [],
      products: []
    };
  },
  watch: {
    date() {
      this.getDados();
    }
  },
  created() {
    this.getDados();
  },

  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: this.saasid,
        factory_id: this.factory.id,
        periodo: this.date
      };
      this.products = [];
      axios
        .post("/api/jornada", dados)
        .then(response => {
          console.log("getJornada");
          console.log(response.data);
          var items = response.data;
          let limit = 11;
          for (let i = 0; i < items.porc.length; i++) {
            // if (i < limit) {
              this.products.push(items.porc[i]);
            // }
          }
          // if (items.porc.length > limit) {
          //   var base = {
          //     dias: 0,
          //     funil: 0,
          //     janela: "PEDENTE",
          //     nome_produto: "Outros",
          //     porcento_venda: 0,
          //     qtd_venda: 0
          //   };
          //   for (let a = 0; a < items.porc.length; a++) {
          //     if (a >= limit) {
          //       base.dias += items.porc[a].dias;
          //       base.funil += items.porc[a].funil;
          //       base.porcento_venda += items.porc[a].porcento_venda;
          //       base.qtd_venda += items.porc[a].qtd_venda;
          //     }
          //   }
          //   this.products.push(base);
          // }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
      }, 820000);
    }
  }
};
</script>
<style  scoped>
p {
  margin-bottom: 0px;
}
</style>