var render, staticRenderFns
import script from "./CardGraficoProdutosPequeno.vue?vue&type=script&lang=js"
export * from "./CardGraficoProdutosPequeno.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e620246a",
  null
  
)

export default component.exports