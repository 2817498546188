<template>
  <div>
    <div class="form-group row">
      <div class="col-sm-4">
        <label for="status" class="control-label">Ativo</label>
        <div class="row">
          <div class="col-sm-12">
            <input
              type="checkbox"
              v-model="formData.status"
              name="status"
              id="status"
              value="1"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <label for="friday" class="control-label">Super Sexta</label>
        <div class="row">
          <div class="col-sm-12">
            <input
              type="checkbox"
              v-model="formData.friday"
              name="friday"
              id="friday"
              value="1"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="title" class="control-label">Título *</label>
      <div class="row">
        <div class="col-sm-12">
          <input
            v-model="formData.title"
            type="text"
            name="title"
            class="form-control"
            id="title"
            maxlength="250"
            required
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="type" class="control-label">Tipo</label>
      <div class="row">
        <div class="col-sm-12">
          <input class="d-none" type="text" id="type" name="type" />
          <multiselect
            @input="select('type', formData.type), resetGoal('selectType')"
            v-model="formData.type"
            tagPlaceholder="Adicione um tipo"
            placeholder="Busque por um tipo"
            label="name"
            track-by="id"
            :allow-empty="false"
            :options="optionsType"
            :multiple="false"
            selectLabel="Pressione Enter para selecionar"
            deselectLabel
          ></multiselect>
        </div>
      </div>
    </div>
    <div
      class="form-group"
      v-if="formData.type.id == 2 && formData.goal.id != 4"
    >
      <label for="consultation" class="control-label"
        >Número de avaliações</label
      >
      <div class="row">
        <div class="col-sm-12">
          <input
            type="number"
            v-model="formData.consultation"
            name="consultation"
            class="form-control"
            id="consultation"
            maxlength="200"
          />
        </div>
      </div>
    </div>
    <div class="form-group" v-if="formData.type.id != 2">
      <label for="value" class="control-label">Valor *</label>
      <div class="row">
        <div class="col-sm-12">
          <ui-money
            v-model="formData.value"
            type="text"
            name="value"
            required="true"
            id="value"
          ></ui-money>
        </div>
      </div>
    </div>
    <div
      class="form-group"
      v-if="formData.type.id == 2 && formData.goal.id == 4"
    >
      <label for="value" class="control-label"
        >Meta por produto aceita apenas tipo Faturamento</label
      >
    </div>
    <div class="form-group">
      <label for="goal" class="control-label">Meta</label>
      <div class="row">
        <div class="col-sm-12">
          <input class="d-none" type="text" id="goal" name="goal" />
          <multiselect
            @input="select('goal', formData.goal), resetGoal('select')"
            v-model="formData.goal"
            tagPlaceholder="Adicione uma meta"
            placeholder="Busque por uma meta"
            label="name"
            track-by="id"
            :allow-empty="false"
            :options="optionsGoal"
            :multiple="false"
            selectLabel="Pressione Enter para selecionar"
            deselectLabel
          ></multiselect>
        </div>
      </div>
    </div>
    <!-- UNIDADE -->
    <div class="form-group" v-if="formData.goal.id == 1">
      <label for="factory_id" class="control-label">Unidade</label>
      <div class="row">
        <div class="col-sm-12">
          <input class="d-none" type="text" id="factory_id" name="factory_id" />
          <multiselect
            @input="
              selectUnidade('factory_id', formData.factory), getUsuarios()
            "
            v-model="formData.factory"
            tagPlaceholder="Adicione uma unidade"
            placeholder="Busque por uma unidade"
            label="name"
            track-by="id"
            :options="factories"
            :multiple="true"
            selectLabel="Pressione Enter para selecionar"
            deselectLabel="Pressione Enter para remover"
          ></multiselect>
        </div>
      </div>
    </div>
    <div
      class="my-2"
      v-if="
        (formData.factory.length > 0 || formData.goal.id == 2) &&
        formData.type.id == 1
      "
    >
      <label @click="resetGoal('switch')">
        <input
          id="goal_for"
          name="goal_for"
          type="radio"
          :value="0"
          v-model="formData.switchUnidade"
        />
        Sem relação
      </label>
      <br />
      <label @click="resetGoal('switch')">
        <input
          id="goal_for"
          name="goal_for"
          type="radio"
          :value="1"
          v-model="formData.switchUnidade"
        />
        Produto
      </label>
      <br />
      <label @click="resetGoal('switch')">
        <input
          id="goal_for"
          name="goal_for"
          type="radio"
          :value="2"
          v-model="formData.switchUnidade"
        />
        Vendedor
      </label>
    </div>
    <!-- USUÁRIO -->
    <div
      class="form-group"
      v-if="
        formData.switchUnidade == 2 &&
        formData.factory.length > 0 &&
        formData.type.id == 1
      "
    >
      <label for="user_id" class="control-label">Usuário vendedor</label>
      <div class="row">
        <div class="col-sm-12">
          <input class="d-none" type="text" id="user_id" name="user_id" />
          <multiselect
            @input="select('user_id', formData.user)"
            v-model="formData.user"
            tagPlaceholder="Adicione um usuário"
            placeholder="Busque por um usuário"
            label="name"
            track-by="id"
            :options="users"
            :multiple="true"
            selectLabel="Pressione Enter para selecionar"
            deselectLabel="Pressione Enter para remover"
          ></multiselect>
        </div>
      </div>
    </div>
    <!-- PRODUTO -->
    <div
      class="form-group"
      v-if="
        formData.switchUnidade == 1 &&
        (formData.factory.length > 0 || formData.goal.id == 2) &&
        formData.type.id == 1
      "
    >
      <label for="product_id" class="control-label">Produto</label>
      <div class="row">
        <div class="col-sm-12">
          <input class="d-none" type="text" id="product_id" name="product_id" />
          <multiselect
            @input="select('product_id', formData.product)"
            v-model="formData.product"
            tagPlaceholder="Adicione um produto"
            placeholder="Busque por um produto"
            label="name"
            track-by="id"
            :options="products"
            :multiple="false"
            selectLabel="Pressione Enter para selecionar"
            deselectLabel="Pressione Enter para remover"
          ></multiselect>
        </div>
      </div>
    </div>
    <!-- COR DA META -->
    <div class="form-group">
      <label for="consultation" class="control-label">Cor da meta</label>
      <div class="row">
        <div class="col-sm-1">
          <input
            type="color"
            v-model="formData.color"
            name="color"
            class="form-control"
            id="color"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["factories", "items", "products"],
  data() {
    return {
      users: [],
      formData: {
        switchUnidade: 0,
        user: [],
        product: [],
        factory: [],
        title: "",
        value: 0,
        consultation: 1,
        status: true,
        friday: true,
        type: { id: 1, name: "Faturamento" },
        goal: { id: 1, name: "Por unidade" },
      },
      optionsType: [
        { id: 1, name: "Faturamento" },
        { id: 2, name: "Avaliação" },
        { id: 3, name: "Novos Orçamentos" },
      ],
      optionsGoal: [
        { id: 1, name: "Por unidade" },
        { id: 2, name: "Geral" },
      ],
    };
  },
  methods: {
    selectUnidade(name, inputValue) {
      document.getElementById(name).value = JSON.stringify(inputValue);

      if (this.formData.user) {
        var ids = [];
        this.formData.factory.forEach((fac) => {
          ids.push(fac.id);
        });
        this.formData.user = this.formData.user.filter((item) => {
          return ids.includes(item.factory_id);
        });
      }
    },
    select(name, inputValue) {
      document.getElementById(name).value = JSON.stringify(inputValue);
    },
    getUsuarios() {
      let dados = [];
      for (let i = 0; i < this.formData.factory.length; i++) {
        dados[i] = this.formData.factory[i];
      }
      axios
        .post("/api/getUsuarios", dados)
        .then((response) => {
          this.users = JSON.parse(response.data);
          document.getElementById("user_id").value = this.items.user;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    resetGoal(method) {
      if (method == "select") {
        this.formData.factory = [];
      }
      if (method == "selectType") {
        this.formData.goal = { id: 1, name: "Por unidade" };
        this.formData.factory = [];
      }
      this.formData.user = [];
      this.formData.product = [];
      this.getUsuarios();
    },
  },
  mounted() {
    if (this.items) {
      document.getElementById("goal").value = JSON.stringify(
        this.optionsGoal[this.items.goal - 1]
      );
      document.getElementById("type").value = JSON.stringify(
        this.optionsType[this.items.type - 1]
      );
      if (this.items.goal == 1) {
        document.getElementById("factory_id").value = this.items.factory;
      }
      if (this.items.goal_for == 1) {
        document.getElementById("product_id").value = this.items.product;
        // } else if (this.items.goal_for == 2) {
      }
    } else {
      document.getElementById("type").value = JSON.stringify({
        id: 1,
        name: "Faturamento",
      });
      document.getElementById("goal").value = JSON.stringify({
        id: 1,
        name: "Por unidade",
      });
    }
  },
  created() {
    if (this.items) {
      this.formData = {
        switchUnidade: this.items.goal_for,
        user: JSON.parse(this.items.user),
        factory: JSON.parse(this.items.factory),
        product: JSON.parse(this.items.product),
        title: this.items.title,
        value: this.items.value,
        consultation: this.items.consultation,
        status: this.items.status,
        friday: this.items.friday,
        type: this.optionsType[this.items.type - 1],
        goal: this.optionsGoal[this.items.goal - 1],
        color: this.items.color,
      };
    }
    this.getUsuarios();
  },
};
</script>
