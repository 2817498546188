<template>
  <div>
    <v-card class="" flat width="100%" height="220px">
      <v-card-title style="font-size: 18px;">
        <v-row justify="center" align="center">
          <v-col class="text-center">
            <span class="text-center">AQUISIÇÃO DE CLIENTE</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="white--text" v-if="!loading">
        <div v-for="(item, index) in valueAquisicao" :key="index">
          <v-row class="pl-2 pr-2">
            <v-col md="8" class="body-1 py-1 mt-2" style="font-size:15px !important;">{{item.origem}}</v-col>
            <v-col md="4" class="font-weight-bold text-info-yellow body-1 py-1 mt-1 text-right" style="font-size:15px !important;">{{item.porc | percent}}</v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-skeleton-loader type="list-item-three-line" v-if="loading"></v-skeleton-loader>
    </v-card>
    <v-card class="text-center mt-3" flat width="100%" height="100%">
      <v-card-text>
        <v-img src="./img/logoOdontotop-white.svg"></v-img>
        <span class="subtitle-1 white--text font-weight-bold">{{factory.name}}</span>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["title", "show", "factory", "date"],

  data() {
    return {
      loading: false,
      valueAquisicao: [],
    };
  },
  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: 212,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/getAquisicaoClientes", dados)
        .then(response => {
          console.log("getAquisicaoClientes");
          console.log(response.data);
          this.valueAquisicao = response.data;
          this.loading = false;
          this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
      }, 120000);
    }
  },
  watch: {
    date() {
      this.getDados();
    }
  },
  created() {
    this.getDados();
  }
};
</script>