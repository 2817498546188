import { render, staticRenderFns } from "./CardUnidades.vue?vue&type=template&id=a3820fc2&scoped=true"
import script from "./CardUnidades.vue?vue&type=script&lang=js"
export * from "./CardUnidades.vue?vue&type=script&lang=js"
import style0 from "./CardUnidades.vue?vue&type=style&index=0&id=a3820fc2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3820fc2",
  null
  
)

export default component.exports