<template>
  <div style="height: 100%">
    <v-card flat style="height: 100%">
      <div class="d-flex" style="flex-wrap: wrap; flex-direction: column">
        <v-card-title>
          <v-row align="center">
            <v-col align="center" cols="4">
              <v-avatar size="56"
                ><img :src="user.img ? 'https://funil.pro/usuarios/avatar/'+user.img : '/img/favicon.png'" />
              </v-avatar>
            </v-col>
            <v-col align="center" cols="4">
              <span
                class="text-center"
                style="
                  font-size: 2rem;
                  white-space: pre-wrap;
                  display: inline-block;
                  word-break: keep-all;
                  line-height: 1;
                "
                >{{ user.name }}</span
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-1">
            <v-col
              cols="12"
              sm="6"
              align="center"
              style="font-size: 2rem; line-height: 2rem"
              >{{ user.average ? user.average:'-' }}</v-col
            >
            <v-col
              cols="12"
              sm="6"
              align="center"
              style="font-size: 2rem; line-height: 2rem"
              ><v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    style="font-size: 2rem"
                    label
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                    >{{ user.total_calls ? user.total_calls : 0 }}</v-chip
                  >
                </template>
                <span>Total</span>
              </v-tooltip></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="mt-sm-1 call-status-pills mb-sm-0 mb-2">
            <v-col cols="3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip label color="green" v-bind="attrs" v-on="on">{{
                    user.answered ? user.answered : 0
                  }}</v-chip>
                </template>
                <span>Atendidas</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip label color="red" v-bind="attrs" v-on="on">{{
                    user.failed ? user.failed : 0
                  }}</v-chip>
                </template>
                <span>Falhas</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip label color="purple" v-bind="attrs" v-on="on">{{
                    user.no_answer ? user.no_answer : 0
                  }}</v-chip>
                </template>
                <span>Sem Resposta</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip label color="#e39507" v-bind="attrs" v-on="on">{{
                    user.busy ? user.busy : 0
                  }}</v-chip>
                </template>
                <span>Ocupadas</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.call-status-pills {
  text-align: center;
  span {
    width: 60px;
    justify-content: center;
    font-size: 2rem;
    @media (max-width: 991px) {
      width: 60%;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}
</style>
