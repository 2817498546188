<template>
  <div>
    <div class="d-flex justify-content-center">
      <v-toolbar @click="Open()" style="cursor:pointer;" class="header-fillter header-filter-overlay-off"
        ><v-row class="filter-bubble" justify="space-around">
          {{ first_day }} até {{ last_day }}
        </v-row>
      </v-toolbar>
    </div>
    <v-card id="create">
      <v-speed-dial
        v-model="fab"
        :top="true"
        :right="true"
        direction="bottom"
        :open-on-hover="true"
        transition="scale-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="#012433" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-menu </v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#1f727c"
              elevation="5"
              fab
              small
              dark
              v-bind="attrs"
              v-on="on"
              @click="Open()"
              ><v-icon>mdi-filter</v-icon></v-btn
            >
          </template>
          <span>Filtrar</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="#1b601e"
              elevation="5"
              fab
              small
              dark
              @click="Export()"
              ><v-icon>mdi-file-export</v-icon></v-btn
            >
          </template>
          <span>Exportar Dados</span>
        </v-tooltip>
      </v-speed-dial>
    </v-card>
    <v-overlay
      :loading="true"
      color="#001f2d"
      opacity="0.88"
      :absolute="absolute"
      :value="overlay"
    >
      <div v-if="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
      <div v-else-if="!loading">
        <v-btn
          color="#042b3d"
          elevation="5"
          fab
          fixed
          dark
          top
          right
          @click="Close()"
          ><v-icon>fa fa-times</v-icon></v-btn
        >
        <div style="width: 310px">
          <v-select
            background-color="#042b3d"
            v-model="value_selected"
            item-value="ramal"
            item-text="name"
            :items="items_select"
            label="Selecione os usuários"
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <v-chip v-if="index === 1">
                <span>{{ item.name }}</span>
              </v-chip>
              <v-chip v-if="index === 2">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 3" class="grey--text text-caption">
                (+{{ value_selected.length - 3 }} outros)
              </span>
            </template>
          </v-select>
        </div>
        <div class="d-flex">
          <v-date-picker
            v-model="dates"
            range
            selected-items-text="2 Selecionadas"
            header-color="#042b3d"
            color="#257192"
            locale="pt-br"
            width="310"
            :max="nowDate"
          ></v-date-picker>
        </div>
        <div class="d-flex" style="width: 310px">
          <div style="width: 155px">
            <v-btn
              color="error"
              style="
                width: 100%;
                border-top-right-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
              "
              @click="ClearFilters()"
              >Limpar Filtro</v-btn
            >
          </div>
          <div style="width: 155px">
            <v-btn
              style="
                width: 100%;
                border-top-left-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
              "
              color="success"
              @click="Filter()"
              >Filtrar</v-btn
            >
          </div>
        </div>
      </div>
    </v-overlay>
    <div class="header-fill-content">
      <div
        v-if="users.length <= 0"
        style="
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
        "
      >
        Não foram encontrados dados de ligações!
      </div>
      <carousel
        v-else
        :key="loading"
        :scrollPerPage="false"
        :autoplayTimeout="4500"
        :perPage="4"
        :autoplay="true"
        :loop="true"
        style="
          padding: 12px;
          padding-top: 40px !important;
          margin-right: 0;
          padding-right: 0px !important;
          height: 100%;
        "
        class="w-100 row"
      >
        <slide
          class="col-12 col-lg-3"
          v-for="(user, index) in users"
          :key="index"
          style="padding-right: 0; height: 100%"
        >
          <card-ligacao-usuario :user="user"></card-ligacao-usuario>
        </slide>
      </carousel>
    </div>
    <div class="body-fill-content">
      <v-row>
        <v-col
          style="align-self: center; padding-right: 10px; padding-left: 28px"
          cols="12"
          sm="6"
        >
          <v-card>
            <card-grafico-ligacoes-hora
              height="400"
              :users="users"
              :graphic="graphic_value"
            ></card-grafico-ligacoes-hora>
          </v-card>
        </v-col>
        <v-col style="padding-right: 30px; padding-left: 5px" cols="12" sm="6">
          <v-card>
            <card-grafico-ligacoes-mes
              :users="users"
              :graphic="month_value"
            ></card-grafico-ligacoes-mes>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row
      style="padding-top: 0"
      class="footer-fill-content"
      v-if="funil_items.clients.length > 0"
    >
      <v-col
        v-if="funil_items.clients.length > 6"
        cols="10"
        style="height: 50%"
      >
        <carousel
          :key="loading"
          :scrollPerPage="false"
          :autoplayTimeout="4500"
          :perPage="
            first_half.length > 2 && first_half.length < 6
              ? first_half.length
              : 6
          "
          :autoplay="true"
          :loop="true"
          class="h-100 row carousel-body"
          style="margin-left: 15px"
        >
          <slide
            v-for="(item, index) in first_half"
            :key="index"
            :class="
              first_half.length < 6 ? 'minus-bottom-slider' : 'bottom-slider'
            "
            style="height: 100%; padding-top: 0; padding-bottom: 5px"
            cols="3"
          >
            <card-agendamento-unidades
              :items="item"
            ></card-agendamento-unidades>
          </slide>
        </carousel>
      </v-col>
      <v-col
        v-if="funil_items.clients.length > 6"
        cols="10"
        style="height: 50%"
      >
        <carousel
          :key="loading"
          :scrollPerPage="false"
          :autoplayTimeout="4500"
          :perPage="
            last_half.length > 2 && last_half.length < 6 ? last_half.length : 6
          "
          :autoplay="true"
          :loop="true"
          class="h-100 row carousel-body"
          style="margin-left: 15px"
        >
          <slide
            v-for="(item, index) in last_half"
            :key="index"
            :class="
              first_half.length < 6 ? 'minus-bottom-slider' : 'bottom-slider'
            "
            style="height: 100%; padding-top: 5px"
            cols="3"
          >
            <card-agendamento-unidades
              :items="item"
            ></card-agendamento-unidades>
          </slide>
        </carousel>
      </v-col>
      <v-col
        v-if="funil_items.clients.length <= 6 && funil_items.clients.length > 0"
        cols="10"
        class="h-100"
      >
        <carousel
          :key="loading"
          :scrollPerPage="false"
          :autoplayTimeout="4500"
          perPage="6"
          :autoplay="true"
          :loop="true"
          class="h-100 row carousel-body"
          style="margin-left: 15px"
        >
          <slide
            v-for="(item, index) in funil_items.clients"
            :key="index"
            class="minus-bottom-slider"
            style="height: 100%; padding-top: 0"
            cols="3"
          >
            <card-agendamento-unidades
              :items="item"
            ></card-agendamento-unidades>
          </slide>
        </carousel>
      </v-col>
      <v-col cols="2" class="h-100 total-card">
        <v-card
          class="d-flex"
          style="flex-direction: column"
          :style="first_half.length < 6 ? 'margin-left:5px' : ''"
        >
          <span style="font-weight: bolder">Totais</span
          ><span style="line-height: 1"
            >Agendamentos:
            <v-chip class="total-chip-schedule" color="green" label>{{
              funil_items.total_scheduled
            }}</v-chip></span
          ><span style="line-height: 1"
            >NoShow:
            <v-chip class="total-chip-schedule" color="red" label>{{
              funil_items.total_no_show
            }}</v-chip></span
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row
      style="padding-top: 0"
      class="footer-fill-content"
      v-if="first_half.length <= 0"
      ><v-col style="font-size: 2rem" cols="12"
        >Não foram encontrados dados de agendamentos!</v-col
      ></v-row
    >
  </div>
</template>

<script>
import Axios from "axios";
export default {
  props: [
    "users_props",
    "first_d",
    "last_d",
    "filter_endpoint",
    "clear_filter_endpoint",
    "users_array",
  ],
  data() {
    return {
      fab: false,
      loading: false,
      dates: [],
      users: {},
      absolute: true,
      overlay: false,
      first_day: "",
      last_day: "",
      graphic_value: [],
      month_value: [],
      items_select: [],
      value_selected: [],
      funil_items: {},
      first_half: 0,
      last_half: 0,
      interval: false,
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },

  created() {
    this.funil_items = JSON.parse(this.users_props).funil_clients;
    if (this.funil_items.clients.length > 1) {
      this.first_half = this.funil_items.clients.slice(
        0,
        this.funil_items.clients.length / 2
      );
      this.last_half = this.funil_items.clients.slice(this.first_half.length);
    } else if (this.funil_items.clients.length == 1) {
      this.first_half = this.funil_items.clients;
    } else {
      this.first_half = [];
      this.last_half = [];
    }
    this.users = JSON.parse(this.users_props).calls_main.calls_array;
    this.graphic_value = JSON.parse(this.users_props).calls_main.calls_by_day;
    this.month_value = JSON.parse(this.users_props).calls_main.calls_by_month;
    this.items_select = JSON.parse(this.users_array);
    if (this.first_d && this.last_d) {
      this.dates[0] = this.first_d;
      this.dates[1] = this.last_d;
      this.first_day = this.convertDate(this.first_d);
      this.last_day = this.convertDate(this.last_d);
    }
    this.Repeat();
  },

  methods: {
    Repeat() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.Filter();
      }, 300000);
    },

    Open() {
      clearInterval(this.interval);
      this.overlay = true;
    },

    Close() {
      this.overlay = false;
      this.Repeat();
    },

    Export() {
      const FileDownload = require("js-file-download");
      Axios({
        url: "/api/export_calls",
        method: "POST",
        responseType: "blob",
        data: {
          users: this.users,
          dates: this.dates,
        },
      })
        .then((response) => {
          FileDownload(
            response.data,
            "ligacoes_" +
              this.convertDateSecondForm(this.dates[0]) +
              "__" +
              this.convertDateSecondForm(this.dates[1]) +
              ".csv"
          );
        })
        .catch(function (error) {});
    },

    Filter() {
      clearInterval(this.interval);
      if (!this.dates[1]) {
        this.dates[1] = this.dates[0];
      }
      this.dates.sort(function (inicial, final) {
        let a = new Date(inicial),
          b = new Date(final);
        return a - b;
      });
      this.loading = true;
      Axios.post(this.filter_endpoint, {
        dates: this.dates,
        selected: this.value_selected,
      })
        .then((response) => {
          this.users = response.data.calls_main.calls_array;
          this.funil_items = response.data.funil_clients;
          console.log(this.funil_items);
          if (this.funil_items.clients.length > 1) {
            this.first_half = this.funil_items.clients.slice(
              0,
              this.funil_items.clients.length / 2
            );
            this.last_half = this.funil_items.clients.slice(
              this.first_half.length
            );
          } else if (this.funil_items.clients.length == 1) {
            this.first_half = this.funil_items.clients;
          } else {
            this.first_half = [];
            this.last_half = [];
          }
          this.graphic_value = response.data.calls_main.calls_by_day;
          this.month_value = response.data.calls_main.calls_by_month;
          this.first_day = this.convertDate(this.dates[0]);
          this.last_day = this.convertDate(this.dates[1]);
          this.overlay = false;
          this.loading = false;
          this.Repeat();
        })
        .catch((e) => {
          this.loading = false;
          this.$swal({
            title: "Oops!",
            text: "Nenhum dado encotrado nesta pesquisa!",
            type: "warning",
            timer: 2000,
          });
          this.Repeat();
        });
    },

    ClearFilters() {
      clearInterval(this.interval);
      this.loading = true;
      Axios.get(this.clear_filter_endpoint)
        .then((response) => {
          this.dates = response.data.dates;
          this.users = response.data.calls.calls_main.calls_array;
          this.funil_items = response.data.calls.funil_clients;
          if (this.funil_items.clients.length > 1) {
            this.first_half = this.funil_items.clients.slice(
              0,
              this.funil_items.clients.length / 2
            );
            this.last_half = this.funil_items.clients.slice(
              this.first_half.length
            );
          } else if (this.funil_items.clients.length == 1) {
            this.first_half = this.funil_items.clients;
          } else {
            this.first_half = [];
            this.last_half = [];
          }
          this.graphic_value = response.data.calls.calls_main.calls_by_day;
          this.month_value = response.data.calls.calls_main.calls_by_month;
          this.first_day = this.convertDate(this.dates[0]);
          this.last_day = this.convertDate(this.dates[1]);
          this.value_selected = [];
          this.overlay = false;
          this.loading = false;
          this.Repeat();
        })
        .catch((e) => {
          this.loading = false;
          this.Repeat();
        });
    },

    convertDate(date) {
      return (
        date.substring(8, 10) +
        "/" +
        date.substring(5, 7) +
        "/" +
        date.substring(0, 4)
      );
    },

    convertDateSecondForm(date) {
      return (
        date.substring(8, 10) +
        "-" +
        date.substring(5, 7) +
        "-" +
        date.substring(0, 4)
      );
    },
  },
};
</script>

<style lang="scss">
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

.header-fill-content {
  top: 0;
  left: 0;
  right: 0;
  height: 37%;
  position: absolute;
  @media (max-height: 800px) {
    height: 43%;
  }

  @media (min-height: 1000px) {
    height: 32%;
  }
}

.body-fill-content {
  position: absolute;
  top: 31%;
  height: 60%;
  left: 0;
  right: 0;
  @media (max-height: 800px) {
    top: 37%;
  }
}

.footer-fill-content {
  height: 25%;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  padding: 12px;
  text-align: center;
  align-items: center;
  font-size: 1.5rem;
  @media (max-height: 800px) {
    height: 10%;
  }
  div {
    padding: 0 15px 0 0;
    &:last-child {
      padding: 0;
    }
  }
}

.header-filter-overlay-off:first-child {
  background-color: #042c3e !important;
}

.header-filter-overlay-on:first-child {
  background-color: #1e1e1e !important;
}

.header-fillter {
  position: fixed;
  width: 25%;
  z-index: 10;
  opacity: 0.9;
  border-bottom-right-radius: 2.5em !important;
  border-bottom-left-radius: 2.5em !important;
  &:first-child {
    border-bottom-right-radius: 2.5em !important;
    border-bottom-left-radius: 2.5em !important;
    div {
      font-size: 2rem;
      text-align: center;
    }
  }
  @media (max-width: 992px) {
    width: 40%;
  }
}

b {
  font-weight: bolder;
}

.total-card {
  padding-left: 7px !important;
  margin-right: 20px;
  position: absolute;
  right: 9px;
  bottom: 13px;
  div {
    flex-direction: column;
    margin-bottom: 15px;
    height: calc(100% - 10px) !important;
    justify-content: space-evenly;
  }
}

.carousel-body {
  div {
    height: 100%;
    div {
      height: 100% !important;
    }
  }
}

.total-chip-schedule > span {
  font-size: 1.5rem;
}

.bottom-slider:last-child {
  padding-right: 15px !important;
}

.minus-bottom-slider:last-child {
  padding-right: 8px !important;
}

.filter-bubble {
  @media (max-width: 1600px) {
    font-size: 1.5rem !important;
  }
  @media (max-width: 1190px) {
    font-size: 1.2rem !important;
  }
}
</style>