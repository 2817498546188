<template>
  <div>
    <div class="fundo">
      <div id="chart">
        <div class="loading-area" v-if="loading">
          <img style="max-width: 100px;" src="/img/loading.gif" alt="Carregando" />
        </div>
        <div v-else>
          <v-img class="logo_fixa" src="./img/logo_branca.svg" alt="Logo" />
          <!-- <div class="topo">
            <div class="row align-items-center">
              <div class="col-md-3 col-12 pb-0">
                <div class="topo_titulo">
                  Vendas por Hora
                </div>
              </div>
              <div class="col-md-6 col-12 pb-0 text-center topo_titulo">
                TOTAL: 
                <b class="text-success">
                  <label>R$</label>
                  <number
                    :from="0"
                    :to="total"
                    :format="theFormat"
                    :duration="5"
                    :delay="1"
                    easing="Power1.easeOut"/>
                </b>
              </div>
              <div class="col-md-3 col-12 pb-0">
                <div class="topo_logo_area">
                  <v-img class="topo_logo" src="./img/logo_branca.svg" alt="Logo" />
                </div>
              </div>
            </div>
          </div>
          <apexchart :height="alturaGrafico" width="100%" type="line" :options="chartOptions" :series="series"></apexchart> -->
          <div class="row mx-0 pt-6">
            <div class="col-md-5 col-12 pb-0 pr-0">
              <div class="topo">
                <div class="row align-items-center">
                  <div class="col-md-3 col-12 pb-0">
                    <div class="topo_titulo mb-1">
                      {{moment(anteanteontem).format('D[/]MM')}}
                    </div>
                  </div>
                  <div class="col-md-6 col-12 pb-0 text-center topo_titulo">
                    <b class="text-success">
                      <label>R$</label>
                      <number
                        :from="0"
                        :to="totalAnteanteontem"
                        :format="theFormat"
                        :duration="5"
                        :delay="1"
                        easing="Power1.easeOut"/>
                    </b>
                  </div>
                </div>
              </div>
              <apexchart :height="alturaGraficoEsquerda" width="100%" type="line" :options="chartOptionsAnteanteontem" :series="seriesAnteanteontem"></apexchart>
              <div>
                <div class="topo">
                  <div class="row align-items-center">
                    <div class="col-md-3 col-12 pb-0">
                      <div class="topo_titulo mb-1">
                        {{moment(anteontem).format('D[/]MM')}}
                      </div>
                    </div>
                    <div class="col-md-6 col-12 pb-0 text-center topo_titulo">
                      <b class="text-success">
                        <label>R$</label>
                        <number
                          :from="0"
                          :to="totalAnteontem"
                          :format="theFormat"
                          :duration="5"
                          :delay="1"
                          easing="Power1.easeOut"/>
                      </b>
                    </div>
                  </div>
                </div>
                <apexchart :height="alturaGraficoEsquerda" width="100%" type="line" :options="chartOptionsAnteontem" :series="seriesAnteontem"></apexchart>
              </div>
              <div>
                <div class="topo">
                  <div class="row align-items-center">
                    <div class="col-md-3 col-12 pb-0">
                      <div class="topo_titulo mb-1">
                        {{moment(ontem).format('D[/]MM')}}
                      </div>
                    </div>
                    <div class="col-md-6 col-12 pb-0 text-center topo_titulo">
                      <b class="text-success">
                        <label>R$</label>
                        <number
                          :from="0"
                          :to="totalOntem"
                          :format="theFormat"
                          :duration="5"
                          :delay="1"
                          easing="Power1.easeOut"/>
                      </b>
                    </div>
                  </div>
                </div>
                <apexchart :height="alturaGraficoEsquerda" width="100%" type="line" :options="chartOptionsOntem" :series="seriesOntem"></apexchart>
              </div>
            </div>
            <div class="col-md-7 col-12 pb-0 pl-0">
              <div class="topo">
                <div class="row align-items-center">
                  <div class="col-md-3 col-12 pb-0">
                    <div class="topo_titulo mb-1">
                      {{moment(hoje).format('D[/]MM')}}
                    </div>
                  </div>
                  <div class="col-md-6 col-12 pb-0 text-center topo_titulo">
                    <b class="text-success">
                      <label>R$</label>
                      <number
                        :from="0"
                        :to="totalHoje"
                        :format="theFormat"
                        :duration="5"
                        :delay="1"
                        easing="Power1.easeOut"/>
                    </b>
                  </div>
                </div>
              </div>
              <apexchart :height="alturaGrafico" width="100%" type="line" :options="chartOptionsHoje" :series="seriesHoje"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales'
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import VueNumber from 'vue-number-animation'
export default {
  props: ['factory'],
  components: {
    apexchart: VueApexCharts,
    VueNumber
  },
  data() {
    return {
      loading: true,
      alturaGrafico: '',
      alturaGraficoEsquerda: '',
      chartOptions: {},
      chartOptionsAnteanteontem: {},
      chartOptionsAnteontem: {},
      chartOptionsOntem: {},
      chartOptionsHoje: {},
      totalHoje: 0,
      totalOntem: 0,
      totalAnteontem: 0,
      totalAnteanteontem: 0,
      hoje: '',
      ontem: '',
      anteontem: '',
      anteanteontem: '',
      series: [
        {
          name: 'Vendido',
          type: 'column',
          data: [],
        },
        //{
        //  name: 'Meta',
        //  type: 'line',
        //  data: [],
        //}
      ],
      seriesHoje: [
        {
          name: 'Vendido',
          type: 'column',
          data: [],
        }
      ],
      seriesOntem: [
        {
          name: 'Vendido',
          type: 'column',
          data: [],
        }
      ],
      seriesAnteontem: [
        {
          name: 'Vendido',
          type: 'column',
          data: [],
        }
      ],
      seriesAnteanteontem: [
        {
          name: 'Vendido',
          type: 'column',
          data: [],
        }
      ],
      labelsHoje: [],
      labelsOntem: [],
      labelsAnteontem: [],
      labelsAnteAnteontem: [],
    }
  },
  methods: {
    moment,
    theFormat(number) {
      const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      return new Intl.NumberFormat('pt-BR', options).format(parseFloat(number))
    },
    loadContent () {
      this.loading = true
      let alturaDaTela = window.innerHeight;
      let alturaCalculada = alturaDaTela - 65
      // let alturaCalculada = alturaDaTela - 100
      this.alturaGrafico = `${alturaCalculada}px`


      let alturaCalculadaEsquerda = alturaDaTela - 120
      this.alturaGraficoEsquerda = `${alturaCalculadaEsquerda/3}px`
      // this.alturaGrafico = `${alturaCalculada}px`
      // this.getDados(true)
      this.getDadosHoje(true)
      this.getDadosOntem()
      this.getDadosAnteOntem()
      this.getDadosAnteAnteOntem()
    },
    async getDadosHoje (carregar) {
      let dados = {
        factory_id: this.factory.id ? this.factory.id : 999999,
        periodo: this.hoje,
      }
      axios
      .post('/api/faturamentoPorHora', dados)
      .then(response => {
        this.labelsHoje = []
        this.seriesHoje[0].data = []
        let valor_total = 0
        response.data.forEach(item => {
          this.labelsHoje.push(`${item.hora}`);
          this.seriesHoje[0].data.push(parseFloat(item.valor))
          valor_total+=item.valor
        });
        this.totalHoje = valor_total
        this.chartOptionsHoje = {
          grid: {
            borderColor: '#333', // Cor das linhas de grade
            // strokeDashArray: 5, // Estilo de tracejado das linhas de grade
            position: 'back', // Posição das linhas de grade (atrás do gráfico)
          },
          chart: {
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            animations: {
              enabled: true,
              easing: 'linear',
              dynamicAnimation: {
                speed: 1000
              }
            },
          },
          markers: {
            size: 2,
            colors: ['#fff'],
            strokeColors: '#fff',
            strokeWidth: 0,
            shape: 'circle'
          },
          stroke: {
            width: [1, 2],
            curve: 'stepline',
          },
          colors: ['#05a55f', 'rgba(255,255,255,0.5)'],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              // return val.length >=6 ? val+'M' : val+'K';
              if (val < 1000) {
                return val
              } else {
                if (val > 0) {
                  const num = parseFloat(val)
                  let formattedNumber;
                  if (num >= 1000000) {
                    formattedNumber = (num / 1000000).toFixed(2) + 'M'
                  } else if (num >= 1000) {
                    formattedNumber = (num / 1000).toFixed(2) + 'K'
                  } else {
                    formattedNumber = num.toFixed(2)
                  }
                  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  const formatted = new Intl.NumberFormat('pt-BR', options)
                    .format(parseFloat(formattedNumber.replace(/[KM]/g, '')))
                    .replace(',', '.')
                  return formatted + formattedNumber.match(/[KM]/)
                } else {
                  return val+'K'
                }
              }
            },
            style: {
              fontSize: ['16px'], // Tamanho da fonte
              fontFamily: 'Arial, sans-serif', // Tipo de fonte
              colors: ['#05a55f', '#fff'], // Cor da fonte
            },
            offsetY: -15,// Move a caixinha para cima
            // offsetX: -10,
            background: {
              enabled: false, // Desabilita o fundo
            },
            border: {
              enabled: false, // Desabilita a borda
            },
            dropShadow: {
              enabled: false, // Desabilita a sombra
            },
          },
          labels: this.labelsHoje,
          xaxis: {
            labels: {
              style: {
                colors: '#FFFFFF', // Cor do texto embaixo do gráfico
                fontSize: '16px',
              },
            },
            axisBorder: {
              show: true,
              color: '#FFFFFF' // Cor da borda do eixo x
            },
            axisTicks: {
              show: true,
              color: '#FFFFFF' // Cor das marcas do eixo x
            }
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                if (value >= 1000000) {
                  return (value / 1000000).toFixed(0) + 'M';
                } else if (value >= 1000) {
                  return (value / 1000).toFixed(0) + 'K';
                } else {
                  return value.toFixed(0);
                }
              },
              style: {
                colors: '#FFFFFF', // Cor do texto ao lado esquerdo do gráfico
                fontSize: '10px',
              },
            },
            axisBorder: {
              show: true,
              color: '#FFFFFF' // Cor da borda do eixo y
            },
            axisTicks: {
              show: true,
              color: '#FFFFFF' // Cor das marcas do eixo y
            }
          },
          tooltip: {
            theme: 'dark', // Tema do tooltip, pode ser 'light' ou 'dark'
            style: {
              fontSize: '18px', // Tamanho da fonte
            },
            x: {
              show: false, // Mostrar valor do eixo x
            },
            y: {
              formatter: function (val) {
                return val + 'k';
              }
            },
            marker: {
              show: true, // Mostrar marcador
            },
            onDatasetHover: {
              highlightDataSeries: true,
            },
          },
          legend: {
            show: false
          },
        }
        setTimeout(() => {
          this.loading = false
        }, 100)
        if (carregar) {
          this.timeoutAtt()
        }
        console.log('nova request')
      })
      .catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    async getDadosOntem () {
      let dados = {
        factory_id: this.factory.id ? this.factory.id : 999999,
        periodo: this.ontem,
      }
      axios
      .post('/api/faturamentoPorHora', dados)
      .then(response => {
        this.labelsOntem = []
        this.seriesOntem[0].data = []
        let valor_total = 0
        response.data.forEach(item => {
          this.labelsOntem.push(`${item.hora}`);
          this.seriesOntem[0].data.push(parseFloat(item.valor))
          valor_total+=item.valor
        });
        this.totalOntem = valor_total
        this.chartOptionsOntem = {
          grid: {
            borderColor: '#333', // Cor das linhas de grade
            // strokeDashArray: 5, // Estilo de tracejado das linhas de grade
            position: 'back', // Posição das linhas de grade (atrás do gráfico)
          },
          chart: {
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            animations: {
              enabled: true,
              easing: 'linear',
              dynamicAnimation: {
                speed: 1000
              }
            },
          },
          markers: {
            size: 2,
            colors: ['#fff'],
            strokeColors: '#fff',
            strokeWidth: 0,
            shape: 'circle'
          },
          stroke: {
            width: [1, 2],
            curve: 'stepline',
          },
          colors: ['#05a55f', 'rgba(255,255,255,0.5)'],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              // return val.length >=6 ? val+'M' : val+'K';
              if (val < 1000) {
                return val
              } else {
                if (val > 0) {
                  const num = parseFloat(val)
                  let formattedNumber;
                  if (num >= 1000000) {
                    formattedNumber = (num / 1000000).toFixed(2) + 'M'
                  } else if (num >= 1000) {
                    formattedNumber = (num / 1000).toFixed(2) + 'K'
                  } else {
                    formattedNumber = num.toFixed(2)
                  }
                  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  const formatted = new Intl.NumberFormat('pt-BR', options)
                    .format(parseFloat(formattedNumber.replace(/[KM]/g, '')))
                    .replace(',', '.')
                  return formatted + formattedNumber.match(/[KM]/)
                } else {
                  return val+'K'
                }
              }
            },
            style: {
              fontSize: ['13px'], // Tamanho da fonte
              fontFamily: 'Arial, sans-serif', // Tipo de fonte
              colors: ['#05a55f', '#fff'], // Cor da fonte
            },
            offsetY: -15,// Move a caixinha para cima
            // offsetX: -10,
            background: {
              enabled: false, // Desabilita o fundo
            },
            border: {
              enabled: false, // Desabilita a borda
            },
            dropShadow: {
              enabled: false, // Desabilita a sombra
            },
          },
          labels: this.labelsOntem,
          xaxis: {
            labels: {
              style: {
                colors: '#FFFFFF', // Cor do texto embaixo do gráfico
                fontSize: '16px',
              },
            },
            axisBorder: {
              show: true,
              color: '#FFFFFF' // Cor da borda do eixo x
            },
            axisTicks: {
              show: true,
              color: '#FFFFFF' // Cor das marcas do eixo x
            }
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                if (value >= 1000000) {
                  return (value / 1000000).toFixed(0) + 'M';
                } else if (value >= 1000) {
                  return (value / 1000).toFixed(0) + 'K';
                } else {
                  return value.toFixed(0);
                }
              },
              style: {
                colors: '#FFFFFF', // Cor do texto ao lado esquerdo do gráfico
                fontSize: '10px',
              },
            },
            axisBorder: {
              show: true,
              color: '#FFFFFF' // Cor da borda do eixo y
            },
            axisTicks: {
              show: true,
              color: '#FFFFFF' // Cor das marcas do eixo y
            }
          },
          tooltip: {
            theme: 'dark', // Tema do tooltip, pode ser 'light' ou 'dark'
            style: {
              fontSize: '18px', // Tamanho da fonte
            },
            x: {
              show: false, // Mostrar valor do eixo x
            },
            y: {
              formatter: function (val) {
                return val + 'k';
              }
            },
            marker: {
              show: true, // Mostrar marcador
            },
            onDatasetHover: {
              highlightDataSeries: true,
            },
          },
          legend: {
            show: false
          },
        }
        console.log('ontem')
      })
      .catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    async getDadosAnteOntem () {
      let dados = {
        factory_id: this.factory.id ? this.factory.id : 999999,
        periodo: this.anteontem,
      }
      axios
      .post('/api/faturamentoPorHora', dados)
      .then(response => {
        this.labelsAnteontem = []
        this.seriesAnteontem[0].data = []
        let valor_total = 0
        response.data.forEach(item => {
          this.labelsAnteontem.push(`${item.hora}`);
          this.seriesAnteontem[0].data.push(parseFloat(item.valor))
          valor_total+=item.valor
        });
        this.totalAnteontem = valor_total
        this.chartOptionsAnteontem = {
          grid: {
            borderColor: '#333', // Cor das linhas de grade
            // strokeDashArray: 5, // Estilo de tracejado das linhas de grade
            position: 'back', // Posição das linhas de grade (atrás do gráfico)
          },
          chart: {
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            animations: {
              enabled: true,
              easing: 'linear',
              dynamicAnimation: {
                speed: 1000
              }
            },
          },
          markers: {
            size: 2,
            colors: ['#fff'],
            strokeColors: '#fff',
            strokeWidth: 0,
            shape: 'circle'
          },
          stroke: {
            width: [1, 2],
            curve: 'stepline',
          },
          colors: ['#05a55f', 'rgba(255,255,255,0.5)'],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              // return val.length >=6 ? val+'M' : val+'K';
              if (val < 1000) {
                return val
              } else {
                if (val > 0) {
                  const num = parseFloat(val)
                  let formattedNumber;
                  if (num >= 1000000) {
                    formattedNumber = (num / 1000000).toFixed(2) + 'M'
                  } else if (num >= 1000) {
                    formattedNumber = (num / 1000).toFixed(2) + 'K'
                  } else {
                    formattedNumber = num.toFixed(2)
                  }
                  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  const formatted = new Intl.NumberFormat('pt-BR', options)
                    .format(parseFloat(formattedNumber.replace(/[KM]/g, '')))
                    .replace(',', '.')
                  return formatted + formattedNumber.match(/[KM]/)
                } else {
                  return val+'K'
                }
              }
            },
            style: {
              fontSize: ['13px'], // Tamanho da fonte
              fontFamily: 'Arial, sans-serif', // Tipo de fonte
              colors: ['#05a55f', '#fff'], // Cor da fonte
            },
            offsetY: -15,// Move a caixinha para cima
            // offsetX: -10,
            background: {
              enabled: false, // Desabilita o fundo
            },
            border: {
              enabled: false, // Desabilita a borda
            },
            dropShadow: {
              enabled: false, // Desabilita a sombra
            },
          },
          labels: this.labelsAnteontem,
          xaxis: {
            labels: {
              style: {
                colors: '#FFFFFF', // Cor do texto embaixo do gráfico
                fontSize: '16px',
              },
            },
            axisBorder: {
              show: true,
              color: '#FFFFFF' // Cor da borda do eixo x
            },
            axisTicks: {
              show: true,
              color: '#FFFFFF' // Cor das marcas do eixo x
            }
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                if (value >= 1000000) {
                  return (value / 1000000).toFixed(0) + 'M';
                } else if (value >= 1000) {
                  return (value / 1000).toFixed(0) + 'K';
                } else {
                  return value.toFixed(0);
                }
              },
              style: {
                colors: '#FFFFFF', // Cor do texto ao lado esquerdo do gráfico
                fontSize: '10px',
              },
            },
            axisBorder: {
              show: true,
              color: '#FFFFFF' // Cor da borda do eixo y
            },
            axisTicks: {
              show: true,
              color: '#FFFFFF' // Cor das marcas do eixo y
            }
          },
          tooltip: {
            theme: 'dark', // Tema do tooltip, pode ser 'light' ou 'dark'
            style: {
              fontSize: '18px', // Tamanho da fonte
            },
            x: {
              show: false, // Mostrar valor do eixo x
            },
            y: {
              formatter: function (val) {
                return val + 'k';
              }
            },
            marker: {
              show: true, // Mostrar marcador
            },
            onDatasetHover: {
              highlightDataSeries: true,
            },
          },
          legend: {
            show: false
          },
        }
        console.log('anteontem')
      })
      .catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    async getDadosAnteAnteOntem () {
      let dados = {
        factory_id: this.factory.id ? this.factory.id : 999999,
        periodo: this.anteanteontem,
      }
      axios
      .post('/api/faturamentoPorHora', dados)
      .then(response => {
        this.labelsAnteAnteontem = []
        this.seriesAnteanteontem[0].data = []
        let valor_total = 0
        response.data.forEach(item => {
          this.labelsAnteAnteontem.push(`${item.hora}`);
          this.seriesAnteanteontem[0].data.push(parseFloat(item.valor))
          valor_total+=item.valor
        });
        this.totalAnteanteontem = valor_total
        this.chartOptionsAnteanteontem = {
          grid: {
            borderColor: '#333', // Cor das linhas de grade
            // strokeDashArray: 5, // Estilo de tracejado das linhas de grade
            position: 'back', // Posição das linhas de grade (atrás do gráfico)
          },
          chart: {
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            animations: {
              enabled: true,
              easing: 'linear',
              dynamicAnimation: {
                speed: 1000
              }
            },
          },
          markers: {
            size: 2,
            colors: ['#fff'],
            strokeColors: '#fff',
            strokeWidth: 0,
            shape: 'circle'
          },
          stroke: {
            width: [1, 2],
            curve: 'stepline',
          },
          colors: ['#05a55f', 'rgba(255,255,255,0.5)'],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              // return val.length >=6 ? val+'M' : val+'K';
              if (val < 1000) {
                return val
              } else {
                if (val > 0) {
                  const num = parseFloat(val)
                  let formattedNumber;
                  if (num >= 1000000) {
                    formattedNumber = (num / 1000000).toFixed(2) + 'M'
                  } else if (num >= 1000) {
                    formattedNumber = (num / 1000).toFixed(2) + 'K'
                  } else {
                    formattedNumber = num.toFixed(2)
                  }
                  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  const formatted = new Intl.NumberFormat('pt-BR', options)
                    .format(parseFloat(formattedNumber.replace(/[KM]/g, '')))
                    .replace(',', '.')
                  return formatted + formattedNumber.match(/[KM]/)
                } else {
                  return val+'K'
                }
              }
            },
            style: {
              fontSize: ['13px'], // Tamanho da fonte
              fontFamily: 'Arial, sans-serif', // Tipo de fonte
              colors: ['#05a55f', '#fff'], // Cor da fonte
            },
            offsetY: -15,// Move a caixinha para cima
            // offsetX: -10,
            background: {
              enabled: false, // Desabilita o fundo
            },
            border: {
              enabled: false, // Desabilita a borda
            },
            dropShadow: {
              enabled: false, // Desabilita a sombra
            },
          },
          labels: this.labelsAnteAnteontem,
          xaxis: {
            labels: {
              style: {
                colors: '#FFFFFF', // Cor do texto embaixo do gráfico
                fontSize: '16px',
              },
            },
            axisBorder: {
              show: true,
              color: '#FFFFFF' // Cor da borda do eixo x
            },
            axisTicks: {
              show: true,
              color: '#FFFFFF' // Cor das marcas do eixo x
            }
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                if (value >= 1000000) {
                  return (value / 1000000).toFixed(0) + 'M';
                } else if (value >= 1000) {
                  return (value / 1000).toFixed(0) + 'K';
                } else {
                  return value.toFixed(0);
                }
              },
              style: {
                colors: '#FFFFFF', // Cor do texto ao lado esquerdo do gráfico
                fontSize: '10px',
              },
            },
            axisBorder: {
              show: true,
              color: '#FFFFFF' // Cor da borda do eixo y
            },
            axisTicks: {
              show: true,
              color: '#FFFFFF' // Cor das marcas do eixo y
            }
          },
          tooltip: {
            theme: 'dark', // Tema do tooltip, pode ser 'light' ou 'dark'
            style: {
              fontSize: '18px', // Tamanho da fonte
            },
            x: {
              show: false, // Mostrar valor do eixo x
            },
            y: {
              formatter: function (val) {
                return val + 'k';
              }
            },
            marker: {
              show: true, // Mostrar marcador
            },
            onDatasetHover: {
              highlightDataSeries: true,
            },
          },
          legend: {
            show: false
          },
        }
        console.log('anteanteontem')
      })
      .catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    async getDados (carregar) {
      let dados = {
        factory_id: this.factory.id ? this.factory.id : 999999,
        periodo: moment().format('Y-MM'),
      }
      axios
      .post('/api/faturamentoPorHora', dados)
      .then(response => {
        // this.labels.push(this.getNextHour(this.labels[this.labels.length - 1]))
        // this.series[0].data.push(this.generateRandomValue())

        // let lastMetaValue = this.series[1].data[this.series[1].data.length - 1]
        // this.series[1].data.push(lastMetaValue + 5)
        this.labels = []
        this.series[0].data = []
        // this.series[1].data = []
        let valor_total = 0
        response.data.forEach(item => {
          this.labels.push(`${item.hora}`);
          this.series[0].data.push(parseFloat(item.valor))
          // this.series[1].data.push(parseFloat(item.meta))
          valor_total+=item.valor
        });
        this.total = valor_total
        
        setTimeout(() => {
          this.loading = false
        }, 100)
        if (carregar) {
          this.timeoutAtt()
        }
        console.log('nova request')
        console.log(response)
      })
      .catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    generateRandomValue() {
      return Math.floor(Math.random() * 100)
    },
    getNextHour(currentHour) {
      let [hour, minute] = currentHour.split(':').map(Number)
      hour = (hour + 1) % 24
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDadosHoje(true)
      }, 300000)
    },
    getDays() {
      this.hoje = moment(new Date()).format('YYYY-MM-DD')

      this.ontem = moment(new Date()).subtract(1, 'days')
      this.anteontem = moment(new Date()).subtract(2, 'days')
      this.anteanteontem = moment(new Date()).subtract(3, 'days')

      if (this.ontem.day() === 0) {
        this.ontem = moment(new Date()).subtract(2, 'days')
        this.anteontem = moment(new Date()).subtract(3, 'days')
        this.anteanteontem = moment(new Date()).subtract(4, 'days')
      } else if (this.anteontem.day() === 0) {
        this.anteontem = moment(new Date()).subtract(3, 'days')
        this.anteanteontem = moment(new Date()).subtract(4, 'days')
      } else if (this.anteanteontem.day() === 0) {
        this.anteanteontem = moment(new Date()).subtract(4, 'days')
      }

      this.ontem = this.ontem.format('YYYY-MM-DD')
      this.anteontem = this.anteontem.format('YYYY-MM-DD')
      this.anteanteontem = this.anteanteontem.format('YYYY-MM-DD')
    }
  },
  created () {
    this.getDays()
    this.loadContent()
  }
}
</script>

<style lang="scss" scoped>
.fundo {
  background-color: black;
  height: 100vh;
  overflow: auto;
  position: relative;
}
.loading-area {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  img {
    filter: invert(1);
  }
}
.topo {
  padding: 0 25px;
  &_titulo {
    color: white;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 767px){
      font-size: 18px;
      padding-top: 10px;
      text-align: center;
    }
  }
  &_logo {
    max-width: 200px;
    @media (max-width: 767px){
      max-width: 120px;
    }
  }
  &_logo_area {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 767px){
      justify-content: center;
    }
  }
}
.apexcharts-xaxis-labels text[data-series-name="Meta"] {
  font-size: 60px!important;
}
.logo_fixa {
  max-width: 200px;
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>