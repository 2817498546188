<script>
import axios from "axios";
import moment from "moment";
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Pie,

  props: ["saasid", "height", "factory", "date"],

  data() {
    return {
      loading: false,
      value: [],
      chartdata: {
        labels: [],
        datasets: [
          {
            datalabels: {
              formatter: function(value, context) {
                return value + "%";
              },
              anchor: "center",
              display: true,
              color: "black",
              backgroundColor: "#FFFFFFB3",
              borderRadius: 3,
              font: {
                size: 18
              }
            },
            borderColor: "white",
            borderWidth: 1,
            backgroundColor: [
              "#ff6384",
              "#ff9f40",
              "#ffcd56",
              "#4bc0c0",
              "#36a2eb"
            ],
            data: []
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          position: "top",
          display: true,
          text: "% venda",
          padding: 0,
          fontStyle: "normal",
          fontSize: 14,
          fontColor: "white"
        },
        legend: {
          display: true,
          position: "right",
          align: "start",
          labels: {
            boxWidth: 15,
            fontColor: "#fff",
            fontSize: 11
          }
        },
        layout: {
          padding: {
            left: -20,
            right: 20,
            top: 10,
            bottom: 10
          }
        },
        animation: {
          duration: 1
        },
      },
      items: []
    };
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
  watch: {
    date() {
      this.getDados();
    }
  },
  created() {
    this.getDados();
  },
  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: 212,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/jornada", dados)
        .then(response => {
          console.log("getGraficoProdutos");
          console.log(response.data);
          this.montar_chart(response.data);
          // this.items = response.data;
          this.loading = false;
          this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
      }, 820000);
    },
    reset_data() {
      this.chartdata = {
        labels: [],
        datasets: [
          {
            datalabels: {
              formatter: function(value, context) {
                return Math.round(value) + "%";
              },
              anchor: "center",
              display: true,
              color: "black",
              backgroundColor: "#FFFFFFB3",
              borderRadius: 3,
              font: {
                size: 18
              }
            },
            borderColor: "white",
            borderWidth: 1,
            backgroundColor: [
              // "#ff6384",
              // "#ff9f40",
              // "#ffcd56",
              // "#4bc0c0",
              // "#36a2eb"
            ],
            data: []
          }
        ]
      };
    },
    montar_chart(items) {
      this.reset_data();
      let limit = 400;
      for (let i = 0; i < items.porc.length; i++) {
        // if (i < limit) {
          this.chartdata.labels.push(items.porc[i].nome_produto);
          this.chartdata.datasets[0].data.push(items.porc[i].porcento_venda);
          this.chartdata.datasets[0].backgroundColor.push(items.porc[i].color);
        // }
      }
      // if (items.porc.length > limit) {
      //   this.chartdata.labels.push("Outros");
      //   var porc = 0;
      //   for (let a = 0; a < items.porc.length; a++) {
      //     if (a >= limit) {
      //       porc += items.porc[a].porcento_venda;
      //     }
      //   }
      //   this.chartdata.datasets[0].data.push(porc);
      //   this.chartdata.datasets[0].backgroundColor.push("#36a2eb");
      // }
      this.renderChart(this.chartdata, this.options);
      this.$data._chart.update();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>