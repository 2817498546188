<template>
  <div>
    <v-card
      style="
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
      "
    >
      <span style="padding-bottom: 5px;margin-top: 15px;">{{ items.name }}</span>
      <div class="row w-100" style="align-items: center">
        <div class="col-6 card-schedule-span" style="height: auto !important">
          <v-chip label color="green">{{
            items.scheduled ? items.scheduled : 0
          }}</v-chip>
        </div>
        
        <div class="col-6 card-schedule-span" style="height: auto !important">
          <v-chip label color="red">{{
            items.no_show ? items.no_show : 0
          }}</v-chip>
        </div>
        <!-- <span style="color: #4caf50">{{
          items.scheduled ? items.scheduled : 0
        }}</span> -->
        <!-- <span class="col-6" style="color: #f44336">
          {{ items.no_show ? items.no_show : 0 }}
        </span> -->
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["items"],
};
</script>

<style lang="scss">
.card-schedule-span > span > span{
  font-size: 1.5rem;
}
</style>