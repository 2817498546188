<template>
  <div class="box box-widget">
    <div class="box-header">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <h3 class="box-title">{{title}}</h3>
        </div>
        <div class="col-6">
          <div class="d-flex justify-content-end">
            <form method="GET" class="form-horizontal" :action="url">
              <input name="filter" type="hidden" :value="filterList">
              <button class="filter-btn btn-promoter" :class="filterList == 'promoter' ? 'shadow' : ''" @click="filterList = 'promoter'">
                Promotor
              </button>
              <button class="filter-btn btn-neutral" :class="filterList == 'neutral' ? 'shadow' : ''" @click="filterList = 'neutral'">
                Neutro
              </button>
              <button class="filter-btn btn-detractor" :class="filterList == 'detractor' ? 'shadow' : ''" @click="filterList = 'detractor'">
                Detrator
              </button>
              <button class="filter-btn btn-all" :class="filterList == '' || !filterList ? 'shadow' : ''" @click="filterList = ''">
                Todos
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="box-body">
      <slot></slot>

      <form
        v-if="items.data.length > 0"
        :class="formClass"
        v-on:submit.prevent="confirmDelete"
        :action="url + '/destroy'"
        method="POST"
      >
        <input type="hidden" name="_token" :value="token" />
        <input type="hidden" name="_method" value="DELETE" />
        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th v-if="!notDeletable" align="center" width="50px">
                <input
                  class="checkbox-delete"
                  id="checkbox-delete-origin"
                  type="checkbox"
                  name="registro[]"
                  data-toggle="tooltip"
                  title="Marcar tudo"
                  v-on:click="checkAll"
                />
              </th>
              <th v-for="(title, index) in titles" :key="index">{{title}}</th>
              <th v-if="actions != 'false'">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <th v-if="!notDeletable" align="center" class="v-middle">
                <input class="checkbox-delete" type="checkbox" name="registro[]" :value="item.id" />
              </th>

              <td
              
                v-for="(field, index) in item"
                :key="index"
                class="v-middle"
                :style="isMultiBadge(field) ? 'max-width: 300px' : ''"
              >
                <span v-if="field === null">{{field}}</span>
                <span v-else-if="!field.type&& !Array.isArray(field)">{{field}}</span>
                <span v-else-if="field.type == 'img'">
                  <img :src="field.src" style="max-width:150px; max-height:150px;" />
                </span>
                <span
                  class="mr-2 px-2 py-0 rounded-pill badge"
                  v-else-if="isMultiBadge(field)"
                  v-for="(campo, index) in field"
                  :key="index"
                  :class="'badge-' + campo.status"
                >
                  <span>{{campo.text}}</span>
                </span>
                <span
                  v-else-if="field.type == 'badge'"
                  class="badge"
                  :class="'badge-' + field.status"
                >{{ field.text }}</span>

                <span
                  v-else-if="field.type == 'action'"
                  v-for="(action, index) in item.actions"
                  :key="index"
                >
                  <a
                    data-toggle="tooltip"
                    class="btn btn-flat ml-10"
                    :class="'btn-' + action.color"
                    :href="action.path"
                    :title="action.label"
                  >
                    <i :class="action.icon"></i>
                  </a>&nbsp;
                </span>
              </td>
             
              <td v-if="!item.actions && actions != 'false'" class="v-middle">
                <a
                  class="btn btn-flat btn-primary"
                  :href="url + '/' + item.id + '/edit'"
                  data-toggle="tooltip"
                  title="Editar"
                >
                  <i class="fa fa-pencil"></i>
                </a>
                <a
                  class="btn btn-flat btn-warning"
                  :href="'/dashboard/' + item.status.hash"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Dashboard"
                  v-if="factoryList"
                >
                  <i class="fa fa-bar-chart"></i>
                </a>
                <span
                  class="badge badge-danger ml-1"
                  v-if="factoryList && !item.status.usuario_sistema"
                >
                  Sem sistema
                </span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="(titles.length + 2)" align="left" vertical-align="center">
                <button
                  v-if="!notDeletable"
                  type="submit"
                  class="mv-22 btn btn-flat btn-danger"
                  :disabled="btnDelete.disabled"
                >
                  <i :class="btnDelete.icon"></i>
                  {{ btnDelete.text }}
                </button>
                <slot name="pagination"></slot>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>

      <div v-if="items.data.length == 0" class="callout callout-default">
        <h4>Nenhum registro encontrado!</h4>
      </div>
    </div>
  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
.filter-btn {
  color: #fff;
  padding: 4px 8px;
  margin-right: 5px;
  border-radius: 4px;
  transition: all .3s;
}
.filter-btn:last-child{
  margin-right: 0;
}
.btn-promoter {
  background: #28A745;
}
.btn-promoter:hover {
  background: #1e7b33;
}
.btn-neutral {
  background: #F8C107;
}
.btn-neutral:hover {
  background: #b18905;
}
.btn-detractor {
  background: #DC3545;
}
.btn-detractor:hover {
  background: #922630;
}
.btn-all {
  background: #a0a0a0;
}
.btn-all:hover {
  background: #797979;
}
</style>

<script>
export default {
  props: [
    "calendar",
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "notDeletable",
    "actions",
    "factoryList",
    "mask",
    "filter",
  ],
  data: function() {
    return {
      itemsList: [],
      deleteItems: [],
      filterList: '',
      btnDelete: {
        icon: "fa fa-trash",
        text: "Excluir registros selecionados",
        disabled: false
      }
    };
  },
  created() {
    if (!this.items.data) {
      this.items.data = this.items;
    }
    this.filterList = this.filter;
  },
  computed: {
    filteredItems() {
      return this.items.data.filter((item) => {
        if (this.filter == 'promoter') {
          return item.score.text >= 9;
        } else if (this.filter == 'neutral') {
          return (item.score.text == 7 || item.score.text == 8);
        } else if (this.filter == 'detractor') {
          return item.score.text <= 6;
        } else {
          return item;
        }
      });
    }
  },
  methods: {
    isMultiBadge(field) {
      if (Array.isArray(field) && field.length > 0) {
        if (field[0].type == "multiBadge") {
          return true;
        }
      }
      return false;
    },
    checkAll: function() {
      var checkboxOrigin = document.getElementById("checkbox-delete-origin");
      var checkboxes = document.getElementsByClassName("checkbox-delete");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = checkboxOrigin.checked;
      }
    },
    confirmDelete: function(e) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir os registros selecionados?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, deletar!",
        cancelButtonText: "Não!"
      }).then(result => {
        if (result.value) {
          e.target.submit(), (this.btnDelete.icon = "fa fa-spinner fa-pulse");
          this.btnDelete.text = "Excluindo";
          this.btnDelete.disabled = true;
        }
      });
    }
  }
};
</script>