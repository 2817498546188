<script>
import axios from "axios";
import moment from "moment";
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  // mixins: [reactiveProp],
  props: ["saasid", "height", "factory", "date"],

  data() {
    return {
      loading: false,
      value: [],
      chartdata: {
        labels: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30"
        ],
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        animation: {
          duration: 1
        },
        plugins: {
          datalabels: {
            anchor: "center",
            display: false,
            color: "black",
            backgroundColor: "#FFFFFFF4",
            borderRadius: 3,
            font: {
              size: 13
            }
          },
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: 5,
                borderColor: "#00ffff",
                borderWidth: 3,
                borderDash: [2, 2],
                label: {
                  enabled: true,
                  backgroundColor: "rgba(133, 133, 133, 0.9)",
                  content: "aa"
                }
              }
            ]
          }
        },
        tooltips: {
          mode: "label",
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              return Number(tooltipItem.yLabel)
                .toFixed(2)
                .toString()
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
          }
        },
        scales: {
          xAxes: [
            {
              // gridLines: {
              //   display: false
              // },
              ticks: {
                beginAtZero: true
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };
  },

  created() {
    this.getDados();
  },
  watch: {
    date() {
      this.getDados();
    }
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },

  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: this.saasid,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/graficoMetas", dados)
        .then(response => {
          console.log("getGraficoMetas");
          console.log(response.data);
          this.value = response.data;
          this.popularGrafico(response.data);
          this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    popularGrafico(data) {
      var base_datasets = {
        label: "",
        borderColor: "#00ffff",
        borderWidth: 1,
        fill: false,
        lineTension: 0,
        // pointBorderColor: "#012433",
        pointBackgroundColor: "#00ffff",
        pointBorderWidth: 0,
        data: []
      };
      var labels = [];
      var datasets = [];
      data.dias.forEach(element => {
        labels.push(element);
      });
      this.chartdata.labels = labels;
      this.chartdata.datasets = [];

      var datasets = [];
      if (data.valores.meta){
        data.valores.meta.forEach(item => {
          var new_data = JSON.parse(JSON.stringify(base_datasets));
          new_data.label = "Meta";
          new_data.data = item.values;
          // new_data.borderColor = "#FDD835";
          // new_data.pointBackgroundColor = "#FDD835";
          new_data.borderColor = this.hexToRGB(item.color, 0.3);
          new_data.pointBackgroundColor = this.hexToRGB(item.color, 0.3);
          datasets.push(new_data);
        });
      }

      var new_data = JSON.parse(JSON.stringify(base_datasets));
      new_data.label = "Atingido";
      new_data.data = data.valores.valor;
      new_data.borderColor = "#32abf1";
      new_data.pointBackgroundColor = "#32abf1";
      datasets.push(new_data);

      this.chartdata.datasets = datasets;

      console.log("this.chartdata");
      console.log(this.chartdata);
      this.loading = false;
      this.$data._chart.update();
      // this.renderChart(this.chartdata, this.options);
    },

    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
      }, 120000);
    },

    hexToRGB(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16),
          g = parseInt(hex.slice(3, 5), 16),
          b = parseInt(hex.slice(5, 7), 16);

      if (alpha) {
          return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      } else {
          return "rgb(" + r + ", " + g + ", " + b + ")";
      }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>