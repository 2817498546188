<script>
import axios from "axios";
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  // mixins: [reactiveProp],
  props: ["height"],

  data() {
    return {
      loading: false,
      value: {
        dias: [
          "01",
          "02",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
        valores: {
          valor: [16,28,33,59,70,88,102,115,148,193,200,208,223,265,292,310,341,377,402,408,440,456,483,507,523,560],
          meta: [15.384615385, 30.76923077, 46.153846155, 61.53846154, 76.923076925, 92.30769231000001, 107.69230769500001, 123.07692308000001, 138.46153846500002, 153.84615385, 169.230769235, 184.61538462, 200.00000000499998, 215.38461538999996, 230.76923077499995, 246.15384615999994, 261.53846154499996, 276.92307693, 292.307692315, 307.6923077, 323.07692308500003, 338.46153847000005, 353.84615385500007, 369.2307692400001, 384.6153846250001,400]
        },
      },
      chartdata: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        animation: {
          duration: 1,
        },
        plugins: {
          datalabels: {
            anchor: "center",
            display: false,
            color: "black",
            backgroundColor: "#FFFFFFF4",
            borderRadius: 3,
            font: {
              size: 13,
            },
          },
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: 5,
                borderColor: "#00ffff",
                borderWidth: 3,
                borderDash: [2, 2],
                label: {
                  enabled: true,
                  backgroundColor: "rgba(133, 133, 133, 0.9)",
                  content: "aa",
                },
              },
            ],
          },
        },
        tooltips: {
          mode: "label",
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                Number(tooltipItem.yLabel)
                  .toFixed(2)
                  .toString()
                  // .replace(".", ",")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              );
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  created() {
    this.popularGrafico(this.value);
  },

  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
  methods: {
    popularGrafico(data) {
      var base_datasets = {
        label: "",
        borderColor: "#00ffff",
        borderWidth: 1,
        fill: false,
        lineTension: 0,
        // pointBorderColor: "#012433",
        pointBackgroundColor: "#00ffff",
        pointBorderWidth: 0,
        data: [],
      };
      var labels = [];
      var datasets = [];
      data.dias.forEach((element) => {
        labels.push(element);
      });
      this.chartdata.labels = labels;
      this.chartdata.datasets = [];

      var datasets = [];
      if (data.valores.meta) {
        var new_data = JSON.parse(JSON.stringify(base_datasets));
        new_data.label = "Meta";
        new_data.data = data.valores.meta;
        new_data.borderColor = "rgba(255,0,0,0.3)";
        new_data.pointBackgroundColor = "rgba(255,0,0,0.3)";
        datasets.push(new_data);
      }

      var new_data = JSON.parse(JSON.stringify(base_datasets));
      new_data.label = "Atingido";
      new_data.data = data.valores.valor;
      new_data.borderColor = "#3ef203";
      new_data.pointBackgroundColor = "#3ef203";
      datasets.push(new_data);

      this.chartdata.datasets = datasets;

      this.loading = false;
      this.$data._chart.update();
      // this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>