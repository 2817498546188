<template>
  <v-row class="ma-0">
    <v-col cols="12" sm="12" md="12" class="pa-0 text-center">
      <v-card class="text-center" height="680" flat>
        <!-- <span class="float_title" style="right: calc(50% - 50px);top:5px;">mapa de vendas</span> -->
        <v-card-text justify="center" align="center" class="pa-0">
          <div id="map-wrap" style="height: 680px; width: 100%" v-if="!loading">
            <l-map :zoom="5" :center="[-27.2235873,-52.0397792]">
              <!-- TODO pegar de cada unidade -->
              <!-- <l-tile-layer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"></l-tile-layer> -->
              <l-tile-layer
                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
              ></l-tile-layer>
              <l-layer-group :visible="aberto" layerType="overlay" name="Sources">
                <v-marker-cluster :options="clusterOptions">
                  <l-marker
                    v-for="(item, index) in aguardando"
                    :key="index"
                    :lat-lng="item.latLng"
                    :icon="defaultIcon"
                    class="red"
                  >
                    <l-popup :content="item.content"></l-popup>
                  </l-marker>
                </v-marker-cluster>
              </l-layer-group>
              <l-layer-group :visible="negociando" layerType="overlay" name="Sources">
                <v-marker-cluster :options="clusterOptions2">
                  <l-marker
                    v-for="(item, index) in cancelado"
                    :key="index"
                    :lat-lng="item.latLng"
                    :icon="defaultIcon"
                  >
                    <l-popup :content="item.content"></l-popup>
                  </l-marker>
                </v-marker-cluster>
              </l-layer-group>
              <l-layer-group :visible="fechado" layerType="overlay" name="Sources">
                <v-marker-cluster :options="clusterOptions3">
                  <l-marker
                    v-for="(item, index) in aprovado"
                    :key="index"
                    :lat-lng="item.latLng"
                    :icon="defaultIcon"
                  >
                    <l-popup :content="item.content"></l-popup>
                  </l-marker>
                </v-marker-cluster>
              </l-layer-group>
              <l-control position="bottomleft" v-if="!loading">
                <div class="legenda_mapa w-100 text-left" style="cursor: pointer;">
                  <div class="linha w-100" @click="aberto = !aberto">
                    <div v-if="aberto" class="box_cor pull-left" style="background-color:red;"></div>Cancelado ({{value.cancelado.length}})
                    <div v-if="!aberto" class="box_cor pull-left" style="background-color: grey;"></div>
                  </div>
                  <div class="linha w-100" @click="negociando = !negociando">
                    <div
                      v-if="negociando"
                      class="box_cor pull-left"
                      style="background-color:#FDD835;"
                    ></div>Aguardando ({{value.aguardando.length}})
                    <div
                      v-if="!negociando"
                      class="box_cor pull-left"
                      style="background-color: grey;"
                    ></div>
                  </div>
                  <div class="linha w-100" @click="fechado = !fechado">
                    <div v-if="fechado" class="box_cor pull-left" style="background-color:green;"></div>Aprovado ({{value.aprovado.length}})
                    <div v-if="!fechado" class="box_cor pull-left" style="background-color: grey;"></div>
                  </div>
                </div>
              </l-control>
              <l-control-fullscreen :options="{ 'false': 'Full', 'true': 'Back' }" position="topleft"/>
            </l-map>
          </div>
          <v-skeleton-loader type="list-item-three-line" height="200" v-if="loading"></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md="4" class="pr-0 pb-0 text-center">
      <v-col cols="12" sm="12" md="12" class="pa-0" v-if="loadingCli">
        <v-skeleton-loader type="list-item-avatar-three-line" class="mb-2" height="90"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-three-line" class="mb-2" height="90"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-three-line" class="mb-2" height="90"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-three-line" class="mb-2" height="90"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-three-line" class="mb-2" height="90"></v-skeleton-loader>
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
export default {
  props: ["saasid", "title", "factory", "date"],
  components: {
    "v-marker-cluster": Vue2LeafletMarkerCluster
  },
  data() {
    return {
      negociando: true,
      fechado: true,
      aberto: true,
      clusterOptions: {
        iconCreateFunction: cluster => {
          var childCount = cluster.getChildCount();
          var c = " marker-cluster-";
          if (childCount < 10) {
            c += "small";
          } else if (childCount < 100) {
            c += "medium";
          } else {
            c += "large";
          }
          return new L.DivIcon({
            html:
              "<div class='black--text' style='background-color: #fdd835;'><span> " +
              childCount +
              "</span></div>",
            className: "marker-cluster background-cluster-yellow" + c,
            iconSize: new L.Point(40, 40)
          });
        }
      },
      clusterOptions2: {
        iconCreateFunction: cluster => {
          var childCount = cluster.getChildCount();
          var c = " marker-cluster-";
          if (childCount < 10) {
            c += "small";
          } else if (childCount < 100) {
            c += "medium";
          } else {
            c += "large";
          }
          return new L.DivIcon({
            html:
              "<div class='black--text' style='background-color: #c90e0e;'><span> " +
              childCount +
              "</span></div>",
            className: "marker-cluster background-cluster-red" + c,
            iconSize: new L.Point(40, 40)
          });
        }
      },
      clusterOptions3: {
        iconCreateFunction: cluster => {
          var childCount = cluster.getChildCount();
          var c = " marker-cluster-";
          if (childCount < 10) {
            c += "small";
          } else if (childCount < 100) {
            c += "medium";
          } else {
            c += "large";
          }
          return new L.DivIcon({
            html:
              "<div class='black--text' style='background-color: :#3ec90e;'><span> " +
              childCount +
              "</span></div>",
            className: "marker-cluster background-cluster-green" + c,
            iconSize: new L.Point(40, 40)
          });
        }
      },
      aguardando: [
        // {
        //   latLong: [-27.2235873, -52.0397792],
        //   content: "Unidade 1",
        //   status: 1
        // },
        // {
        //   latLong: [-27.162583, -51.85906],
        //   content: "Unidade 2",
        //   status: 2
        // },
        // {
        //   latLong: [-27.562583, -51.85906],
        //   content: "Unidade 3",
        //   status: 3
        // },
        // {
        //   latLong: [-27.585921, -48.621385],
        //   content: "Unidade 4",
        //   status: 1
        // },
        // {
        //   latLong: [-26.846636, -49.335327],
        //   content: "Unidade 5",
        //   status: 2
        // },
        // {
        //   latLong: [-27.398797, -51.216607],
        //   content: "Unidade 6",
        //   status: 3
        // },
        // {
        //   latLong: [-26.798841, -49.02359],
        //   content: "Unidade 7",
        //   status: 1
        // }
      ],
      cancelado: [
        // {
        //   latLong: [-28.2235873, -52.0397792],
        //   content: "Unidade 8",
        //   status: 1
        // },
        // {
        //   latLong: [-28.162583, -51.85906],
        //   content: "Unidade 9",
        //   status: 2
        // },
        // {
        //   latLong: [-28.562583, -51.85906],
        //   content: "Unidade 10",
        //   status: 3
        // },
        // {
        //   latLong: [-28.585921, -48.621385],
        //   content: "Unidade 11",
        //   status: 1
        // },
        // {
        //   latLong: [-26.846636, -49.335328],
        //   content: "Unidade 12",
        //   status: 2
        // },
        // {
        //   latLong: [-28.398898, -51.216608],
        //   content: "Unidade 13",
        //   status: 3
        // },
        // {
        //   latLong: [-26.898841, -49.02359],
        //   content: "Unidade 8",
        //   status: 1
        // }
      ],
      aprovado: [
        // {
        //   latLong: [-29.2235873, -52.0397792],
        //   content: "Unidade 1",
        //   status: 1
        // },
        // {
        //   latLong: [-29.162583, -51.85906],
        //   content: "Unidade 2",
        //   status: 2
        // },
        // {
        //   latLong: [-29.562583, -51.85906],
        //   content: "Unidade 3",
        //   status: 3
        // },
        // {
        //   latLong: [-29.585921, -48.621385],
        //   content: "Unidade 4",
        //   status: 1
        // },
        // {
        //   latLong: [-26.846636, -49.335329],
        //   content: "Unidade 5",
        //   status: 2
        // },
        // {
        //   latLong: [-29.398999, -51.216609],
        //   content: "Unidade 6",
        //   status: 3
        // },
        // {
        //   latLong: [-26.998841, -49.02359],
        //   content: "Unidade 9",
        //   status: 1
        // }
      ],
      defaultIcon: L.icon({
        iconUrl: "./img/marker-icon.png",
        iconSize: [26, 42],
        iconAnchor: [13, 13],
        shadowUrl: "./img/marker-icon.png",
        shadowSize: [0, 0],
        shadowAnchor: [0, 0]
      }),
      loading: false,
      loadingCli: false,
      value: [],
      clientes: [],
      heats: [],
      heatsAbertasMes: [],
      heatsAbertasDia: [],
      heatsFechadasMes: []
    };
  },
  watch: {
    date() {
      this.getDados();
    }
  },
  created() {
    this.getDados();
  },

  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: this.saasid,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/mapa", dados)
        .then(response => {
          console.log("getMapa ***************");
          console.log(response.data);
          this.value = response.data;
          var self = this;
          response.data.aguardando.forEach(element => {
            self.aguardando.push({
              latLng: [parseFloat(element.lat), parseFloat(element.lng)],
              content: element.nome
            });
          });
          response.data.cancelado.forEach(element => {
            self.cancelado.push({
              latLng: [parseFloat(element.lat), parseFloat(element.lng)],
              content: element.nome
            });
          });
          response.data.aprovado.forEach(element => {
            self.aprovado.push({
              latLng: [parseFloat(element.lat), parseFloat(element.lng)],
              content: element.nome
            });
          });
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
      }, 120000);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>