<template>
  <div class="pt-3">
    <v-row>
      <v-col md="8" class="pt-0">
        <v-card width="400" height="340" flat>
          <vue-funnel-graph
            v-if="!loading"
            class="ma-2"
            width="250"
            height="285"
            :labels="labels"
            :values="values"
            :colors="colors"
            :sub-labels="[]"
            direction="vertical"
            gradient-direction="horizontal"
            :animated="false"
            :display-percentage="false"
          ></vue-funnel-graph>
          <v-card-text v-if="loading" class="d-flex align-center fill-height justify-center">
            <v-progress-circular :width="6" :size="60" indeterminate></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-row>
          <v-col class="pl-0 pt-0">
            <v-card class="text-center" flat width="100%" height="100%">
              <v-card-title style="font-size: 17px;">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">TAXA CONVERSÃO</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col v-if="loadingTaxas" class="pt-0">
                    <v-skeleton-loader type="list-item-three-line" height="50"></v-skeleton-loader>
                  </v-col>
                  <v-col v-if="!loadingTaxas">
                    <span
                      class="text-info-yellow h5 font-weight-bold"
                      v-if="taxas.taxa_conversao"
                    >{{taxas.taxa_conversao.toFixed(2)}}%</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-0 pt-0">
            <v-card class="text-center" flat width="100%" height="100%">
              <v-card-title style="font-size: 17px;">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">TAXA REJEIÇÃO</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col v-if="loadingTaxas" class="pt-0">
                    <v-skeleton-loader type="list-item-three-line" height="50"></v-skeleton-loader>
                  </v-col>
                  <v-col v-if="!loadingTaxas">
                    <span
                      class="text-info-yellow h5 font-weight-bold"
                      v-if="taxas.taxa_rejeicao"
                    >{{taxas.taxa_rejeicao.toFixed(2)}}%</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-0 pt-0">
            <v-card class="text-center" flat width="100%" height="100%">
              <v-card-title style="font-size: 17px;">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">TOTAL LEADS</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col v-if="loadingTaxas" class="pt-0">
                    <v-skeleton-loader type="list-item-three-line" height="50"></v-skeleton-loader>
                  </v-col>
                  <v-col v-if="!loadingTaxas">
                    <span class="text-info-yellow h5 font-weight-bold">{{taxas.leads}}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.porc_funnel {
  font-size: 10px !important;
}
.label__value {
  font-size: 12px !important;
  margin-bottom: 0px !important;
}
.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label {
  padding-top: 5px !important;
}
</style>
<script>
import { VueFunnelGraph } from "vue-funnel-graph-js";
import axios from "axios";
export default {
  components: {
    VueFunnelGraph
  },

  props: ["title", "factory", "date"],
  
  data() {
    return {
      taxas: [],
      labels: [],
      subLabels: [],
      values: [],
      colors: [],
      direction: "vertical",
      gradientDirection: "vertical",
      loadingTaxas: false,
      loading: false
    };
  },
  created() {
    this.getTaxas();
    this.getDados();
  },
  // watch: {
  //   date() {
  //     this.getTaxas();
  //     this.getDados();
  //   }
  // },
  methods: {
    getTaxas(notLoadingTaxas) {
      if (!notLoadingTaxas) {
        this.loadingTaxas = true;
      }
      var dados = {
        saasid: this.saasid,
        factory_id: this.factory.id,
        periodo: this.date,
      };
      axios
        .post("/api/taxas", dados)
        .then(response => {
          console.log("getTaxas");
          console.log(response.data);
          this.taxas = response.data;
          this.loadingTaxas = false;
          this.timeoutAtt();
        })
        .catch(err => {
          this.loadingTaxas = false;
          console.log(err);
        });
    },
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: this.saasid,
        factory_id: this.factory.id,
        periodo: this.date,
      };
      axios
        .post("/api/funil", dados)
        .then(response => {
          console.log("getFunil");
          console.log(response.data);
          this.timeoutAtt();
          this.popularFunil(response.data);
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    popularFunil(data) {
      var labels = [];
      var subLabels = [];
      var values = [];
      var totalNegAndamento = 0;
      data.etapas.forEach(element => {
        // labels.push(element.etapa_nome);
        // labels.push("R$ " + element.valor_total_format);
        labels.push(element.etapa_nome + " | R$ " + element.valor_total_format);
        values.push(element.values);
        totalNegAndamento += parseFloat(element.valor_total);
      });
      data.users.forEach(element => {
        subLabels.push(element.user_nome);
      });
      this.labels = labels;
      this.values = values;
      this.subLabels = subLabels;
      this.totalNegAndamento = totalNegAndamento;
      this.loading = false;
      setTimeout(() => {
        $(".porc_funnel").remove();
        var a = 1;
        data.etapas.forEach(element => {
          $(
            "div.svg-funnel-js__label.label-" + a + " > div.label__title"
          ).after(
            '<span class="porc_funnel">' +
              parseFloat((element.qtd_ganhas / data.total_neg) * 100).toFixed(
                2
              ) +
              "% conversão" +
              "<br/>" +
              parseFloat((element.qtd_perdidas / data.total_neg) * 100).toFixed(
                2
              ) +
              "% rejeição" +
              "</span>"
          );
          a++;
        });
      }, 500);
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
        // this.getOther(true);
        this.getTaxas(true);
      }, 820000);
    }
  }
};
</script>

