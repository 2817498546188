<script>
import axios from "axios";
import moment from "moment";
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Pie,

  props: ["height"],

  data() {
    return {
      loading: false,
      value: [],
      chartdata: {
        labels: [],
        datasets: [
          {
            datalabels: {
              formatter: function (value, context) {
                return value + "%";
              },
              anchor: "center",
              display: true,
              color: "black",
              backgroundColor: "#FFFFFFB3",
              borderRadius: 3,
              font: {
                size: 18,
              },
            },
            borderColor: "white",
            borderWidth: 1,
            backgroundColor: [
              "#ff6384",
              "#ff9f40",
              "#ffcd56",
              "#4bc0c0",
              "#36a2eb",
            ],
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          position: "top",
          display: true,
          text: "% venda",
          padding: 0,
          fontStyle: "normal",
          fontSize: 14,
          fontColor: "white",
        },
        legend: {
          display: true,
          position: "right",
          align: "start",
          labels: {
            boxWidth: 15,
            fontColor: "#fff",
            fontSize: 11,
          },
        },
        layout: {
          padding: {
            left: -20,
            right: 20,
            top: 10,
            bottom: 10,
          },
        },
        animation: {
          duration: 1,
        },
      },
      items: {
        porc: [
          {
            nome_produto: "Implantodontia",
            id_produto: 14,
            dias: 1,
            qtd_venda: 19,
            color: "#4fa0c0",
            funil: 39290,
            janela: 100710,
            meta: 140000,
            porcento_venda: 10,
          },
          {
            nome_produto: "Prótese",
            id_produto: 9,
            dias: 1,
            qtd_venda: 27,
            color: "#ff7f10",
            funil: 28464.25,
            janela: -2464.25,
            meta: 26000,
            porcento_venda: 35,
          },
          {
            nome_produto: "Dentística",
            id_produto: 6,
            dias: 1,
            qtd_venda: 61,
            color: "#31d2eb",
            funil: 7684.970001220703,
            janela: 20315.029998779297,
            meta: 28000,
            porcento_venda: 1,
          },
          {
            nome_produto: "Endodontia",
            id_produto: 7,
            dias: 1,
            qtd_venda: 19,
            color: "#18a2fb",
            funil: 6652.539978027344,
            janela: 35347.460021972656,
            meta: 42000,
            porcento_venda: 20,
          },
          {
            nome_produto: "Cirurgia",
            id_produto: 10,
            dias: 1,
            qtd_venda: 40,
            color: "#ad6384",
            funil: 6470,
            janela: 28530,
            meta: 35000,
            porcento_venda: 10,
          },
          {
            nome_produto: "Prevenção",
            id_produto: 4,
            dias: 1,
            qtd_venda: 31,
            color: "#4bc0c0",
            funil: 3520.5,
            janela: 11479.5,
            meta: 15000,
            porcento_venda: 10,
          },
          {
            nome_produto: "Ortodontia",
            id_produto: 11,
            dias: 1,
            qtd_venda: 12,
            color: "#ae9f40",
            funil: 1149,
            janela: 40851,
            meta: 42000,
            porcento_venda: 1,
          },
          {
            nome_produto: "Periodontia",
            id_produto: 8,
            dias: 1,
            qtd_venda: 1,
            color: "#ee9f40",
            funil: 267.510009765625,
            janela: 8732.489990234375,
            meta: 9000,
            porcento_venda: 1,
          },
          {
            nome_produto: "Odontopediatria",
            id_produto: 5,
            dias: 1,
            qtd_venda: 2,
            color: "#36f2eb",
            funil: 190,
            janela: 8810,
            meta: 9000,
            porcento_venda: 1,
          },
          {
            nome_produto: "Radiologia",
            id_produto: 12,
            dias: 1,
            qtd_venda: 2,
            color: "#ff1784",
            funil: 160,
            janela: 0,
            porcento_venda: 6,
          },
          {
            nome_produto: "Outros",
            id_produto: 9999,
            dias: 1,
            qtd_venda: 0,
            color: "#000",
            funil: 0,
            janela: 0,
            meta: 0,
            porcento_venda: 5,
          },
        ],
        qtd: [
          {
            nome_produto: "Implantodontia",
            id_produto: 14,
            dias: 1,
            qtd_venda: 19,
            color: "#4fa0c0",
            funil: 39290,
            janela: 100710,
            meta: 140000,
            porcento_venda: 22.81,
          },
          {
            nome_produto: "Prótese",
            id_produto: 9,
            dias: 1,
            qtd_venda: 27,
            color: "#ff7f10",
            funil: 28464.25,
            janela: -2464.25,
            meta: 26000,
            porcento_venda: 16.53,
          },
          {
            nome_produto: "Dentística",
            id_produto: 6,
            dias: 1,
            qtd_venda: 61,
            color: "#31d2eb",
            funil: 7684.970001220703,
            janela: 20315.029998779297,
            meta: 28000,
            porcento_venda: 4.46,
          },
          {
            nome_produto: "Endodontia",
            id_produto: 7,
            dias: 1,
            qtd_venda: 19,
            color: "#18a2fb",
            funil: 6652.539978027344,
            janela: 35347.460021972656,
            meta: 42000,
            porcento_venda: 3.86,
          },
          {
            nome_produto: "Cirurgia",
            id_produto: 10,
            dias: 1,
            qtd_venda: 40,
            color: "#ad6384",
            funil: 6470,
            janela: 28530,
            meta: 35000,
            porcento_venda: 3.76,
          },
          {
            nome_produto: "Prevenção",
            id_produto: 4,
            dias: 1,
            qtd_venda: 31,
            color: "#4bc0c0",
            funil: 3520.5,
            janela: 11479.5,
            meta: 15000,
            porcento_venda: 2.04,
          },
          {
            nome_produto: "Ortodontia",
            id_produto: 11,
            dias: 1,
            qtd_venda: 12,
            color: "#ae9f40",
            funil: 1149,
            janela: 40851,
            meta: 42000,
            porcento_venda: 0.67,
          },
          {
            nome_produto: "Periodontia",
            id_produto: 8,
            dias: 1,
            qtd_venda: 1,
            color: "#ee9f40",
            funil: 267.510009765625,
            janela: 8732.489990234375,
            meta: 9000,
            porcento_venda: 0.16,
          },
          {
            nome_produto: "Odontopediatria",
            id_produto: 5,
            dias: 1,
            qtd_venda: 2,
            color: "#36f2eb",
            funil: 190,
            janela: 8810,
            meta: 9000,
            porcento_venda: 0.11,
          },
          {
            nome_produto: "Radiologia",
            id_produto: 12,
            dias: 1,
            qtd_venda: 2,
            color: "#ff1784",
            funil: 160,
            janela: 0,
            porcento_venda: 0.09,
          },
          {
            nome_produto: "Não contabilizado",
            id_produto: 9999,
            dias: 1,
            qtd_venda: 0,
            color: "#000",
            funil: 0,
            janela: 0,
            meta: 0,
            porcento_venda: 45.51,
          },
        ],
      },
    };
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },

  created() {
    this.montar_chart(this.items);
  },
  methods: {
    reset_data() {
      this.chartdata = {
        labels: [],
        datasets: [
          {
            datalabels: {
              formatter: function (value, context) {
                return Math.round(value) + "%";
              },
              anchor: "center",
              display: true,
              color: "black",
              backgroundColor: "#FFFFFFB3",
              borderRadius: 3,
              font: {
                size: 18,
              },
            },
            borderColor: "white",
            borderWidth: 1,
            backgroundColor: [
              // "#ff6384",
              // "#ff9f40",
              // "#ffcd56",
              // "#4bc0c0",
              // "#36a2eb"
            ],
            data: [],
          },
        ],
      };
    },
    montar_chart(items) {
      this.reset_data();
      let limit = 400;
      for (let i = 0; i < items.porc.length; i++) {
        this.chartdata.labels.push(items.porc[i].nome_produto);
        this.chartdata.datasets[0].data.push(items.porc[i].porcento_venda);
        this.chartdata.datasets[0].backgroundColor.push(items.porc[i].color);
      }
      this.renderChart(this.chartdata, this.options);
      this.$data._chart.update();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>