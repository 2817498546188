<script>
import axios from "axios";
import moment from "moment";
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Pie,

  props: ["saasid", "height", "factory", "date"],

  data() {
    return {
      loading: false,
      value: [],
      chartdata: {
        labels: [],
        datasets: [
          {
            datalabels: {
              formatter: function(value, context) {
                return Math.round(value) + "%";
              },
              anchor: "center",
              display: true,
              color: "black",
              backgroundColor: "#FFFFFFB3",
              borderRadius: 3,
              font: {
                size: 18
              }
            },
            borderColor: "white",
            borderWidth: 1,
            backgroundColor: [
              // "#ff6384",
              // "#ff9f40",
              // "#ffcd56",
              // "#4bc0c0",
              // "#36a2eb"
            ],
            data: []
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          position: "top",
          display: true,
          text: "",
          padding: 0,
          fontStyle: "normal",
          fontSize: 14,
          fontColor: "white"
        },
        legend: {
          display: false,
          position: "right",
          align: "start",
          labels: {
            boxWidth: 11,
            fontColor: "#fff"
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        animation: {
          duration: 1
        }
      }
    };
  },

  mounted() {
    this.montar_chart(this.date)
    // this.renderChart(this.chartdata, this.options);
  },
  created() {
    // this.getDados();
  },
  watch: {
    date() {
      // this.getDados();
    }
  },
  methods: {
    // getDados(notLoading) {
    //   if (!notLoading) {
    //     this.loading = true;
    //   }
    //   var dados = {
    //     saasid: 212,
    //     factory_id: this.factory.id,
    //     periodo: this.date
    //   };
    //   axios
    //     .post("/api/jornada", dados)
    //     .then(response => {
    //       console.log("getGraficoProdutos");
    //       console.log(response.data);
    //       this.montar_chart(response.data);
    //       // this.items = response.data;
    //       this.loading = false;
    //       this.timeoutAtt();
    //     })
    //     .catch(err => {
    //       this.loading = false;
    //       console.log(err);
    //     });
    // },
    // timeoutAtt() {
    //   setTimeout(() => {
    //     this.getDados(true);
    //   }, 120000);
    // },
    // reset_data() {
    //   this.chartdata = {
    //     labels: [],
    //     datasets: [
    //       {
    //         datalabels: {
    //           anchor: "center",
    //           display: true,
    //           color: "black",
    //           font: {
    //             size: 18
    //           }
    //         },
    //         borderColor: "white",
    //         borderWidth: 1,
    //         backgroundColor: [
    //           // "#ff6384",
    //           // "#ff9f40",
    //           // "#ffcd56",
    //           // "#4bc0c0",
    //           // "#36a2eb"
    //         ],
    //         data: []
    //       }
    //     ]
    //   };
    // },
    montar_chart(items) {
      // this.reset_data();
      let limit = 4;
      for (let i = 0; i < items.length; i++) {
        if (i < limit) {
          this.chartdata.labels.push(items[i].nome_produto);
          // this.chartdata.datasets[0].data.push(items[i].qtd_venda);
          this.chartdata.datasets[0].data.push(items[i].porcento_venda);
          this.chartdata.datasets[0].backgroundColor.push(items[i].color);
        }
      }
      if (items.length > limit) {
        this.chartdata.labels.push("Outros");
        var qtd = 0;
        for (let a = 0; a < items.length; a++) {
          if (a >= limit) {
            qtd += items[a].porcento_venda;
          }
        }
        this.chartdata.datasets[0].data.push(qtd);
        this.chartdata.datasets[0].backgroundColor.push("#36a2eb");
      }
      this.renderChart(this.chartdata, this.options);
      this.$data._chart.update();
    }
  }
};
</script>

<style lang="scss" scoped>
.minor-graph {
  padding-bottom: 10px;
  position: initial!important;
}
</style>