<template>
  <v-row>
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="115">
        <v-card-title style="font-size: 14px;">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">QTD AVALIAÇÕES</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{value.total}}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="!loading"
          style="font-size: .875rem !important; color: #FFFFFFB3;"
          class="mt-n5"
        >
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" sm="6" lg="6" class="px-0">
              <p>
                <span
                  class="text-info-blue font-weight-bold body-2"
                >{{value.total_ant_porc | percent}}</span>
                <span class="text-info-yellow title mx-1">|</span>
                <span class="text-info-blue font-weight-bold body-2">{{value.anterior.total_ant}}</span>
              </p>
            </v-col>
          </v-row>
          <v-skeleton-loader type="list-item-three-line" height="130" v-if="loading"></v-skeleton-loader>
        </v-card-actions>
        <v-skeleton-loader type="list-item-three-line" height="115" v-if="loading"></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="115">
        <v-card-title style="font-size: 14px;">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">QTD REMARCADO</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{value.reagendados}}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="!loading"
          style="font-size: .875rem !important; color: #FFFFFFB3;"
          class="mt-n5"
        >
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" sm="6" lg="6" class="px-0">
              
              <p>
                <span
                  class="text-info-blue font-weight-bold body-2"
                >{{value.reagendados_ant_porc | percent}}</span>
                <span class="text-info-yellow title mx-1">|</span>
                <span
                  class="text-info-blue font-weight-bold body-2"
                >{{value.anterior.reagendados_ant}}</span>
              </p>
            </v-col>
          </v-row>
          <v-skeleton-loader type="list-item-three-line" height="130" v-if="loading"></v-skeleton-loader>
        </v-card-actions>
        <v-skeleton-loader type="list-item-three-line" height="115" v-if="loading"></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="115">
        <v-card-title style="font-size: 14px;">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">NO SHOW</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{value.faltas_porc | percent}}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="!loading"
          style="font-size: .875rem !important; color: #FFFFFFB3;"
          class="mt-n5"
        >
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" sm="6" lg="6" class="px-0">
              
              <p>
                <span
                  class="text-info-blue font-weight-bold body-2"
                >{{value.faltas_ant_porc | percent}}</span>
                <span class="text-info-yellow title mx-1">|</span>
                <span class="text-info-blue font-weight-bold body-2">{{value.anterior.faltas_ant}}</span>
              </p>
            </v-col>
          </v-row>
          <v-skeleton-loader type="list-item-three-line" height="130" v-if="loading"></v-skeleton-loader>
        </v-card-actions>
        <v-skeleton-loader type="list-item-three-line" height="115" v-if="loading"></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col>
      <v-card class="text-center" flat width="100%" height="115">
        <v-card-title style="font-size: 14px;">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">QTD ATENDIMENTO</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{value.atendidos}}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="!loading"
          style="font-size: .875rem !important; color: #FFFFFFB3;"
          class="mt-n5"
        >
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" sm="6" lg="6" class="px-0">
              
              <p>
                <span
                  class="text-info-blue font-weight-bold body-2"
                >{{value.atendidos_ant_porc | percent}}</span>
                <span class="text-info-yellow title mx-1">|</span>
                <span
                  class="text-info-blue font-weight-bold body-2"
                >{{value.anterior.atendidos_ant}}</span>
              </p>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-skeleton-loader type="list-item-three-line" height="115" v-if="loading"></v-skeleton-loader>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
// moment(newValue.data).fromNow()
import axios from "axios";
import moment from "moment";

export default {
  props: ["title", "show", "factory", "date"],

  data() {
    return {
      loading: false,
      value: {
        anterior: []
      }
    };
  },
  watch: {
    date() {
      this.getDados();
    }
  },
  created() {
    this.getDados();
  },
  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: 212,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/quantAvaliacoes", dados)
        .then(response => {
          console.log("getQuantAvaliacoes");
          console.log(response.data);
          this.value = response.data;
          this.loading = false;
          this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
      }, 120000);
    }
  }
};
</script>