var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"text-center",attrs:{"flat":"","width":"100%","height":"740"}},[_c('v-card-title',{staticClass:"title",staticStyle:{"font-size":"16px"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.title))])])],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"mt-3 custom_scroll",staticStyle:{"color":"white","max-height":"670px","overflow-y":"auto"}},_vm._l((_vm.products),function(item,index){return _c('div',{key:index},[_c('v-row',{staticClass:"text-center mt-0",attrs:{"align":"center","justify":"start"}},[_c('v-col',{staticClass:"pa-0 text-left pl-4",attrs:{"cols":"12","sm":"12","md":"2","lg":"2"}},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(item.nome_produto))])]),_vm._v(" "),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('p',[_vm._v("Dias")]),_vm._v(" "),_c('span',{staticClass:"text-info-blue font-weight-bold subtitle-1"},[_vm._v(_vm._s(item.dias))])]),_vm._v(" "),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('p',[_vm._v("Funil")]),_vm._v(" "),(_vm.show)?_c('span',{staticClass:"text-info-yellow font-weight-bold subtitle-1"},[_vm._v(_vm._s(_vm._f("currency")(item.funil,"", 0, {
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                  })))]):_c('span',{staticClass:"text-info-yellow font-weight-bold subtitle-1"},[_vm._v("-")])]),_vm._v(" "),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('p',[_vm._v("Janela do Mês")]),_vm._v(" "),(_vm.show)?_c('span',{staticClass:"text-info-yellow font-weight-bold subtitle-1"},[_vm._v(_vm._s(_vm._f("currency")(item.janela,"", 0, {
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                  })))]):_c('span',{staticClass:"text-info-yellow font-weight-bold subtitle-1"},[_vm._v("-")]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"-7px"}},[(_vm.show)?_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm._f("currency")(item.meta,"", 0, {
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                  })))]):_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("-")])])])],1),_vm._v(" "),(index + 1 != _vm.products.length)?_c('v-divider',{staticClass:"mb-2"}):_vm._e()],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }