var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',[_c('infinite-slide-bar',{attrs:{"duration":_vm.duration(),"delay":!_vm.loading ? '10s' : '50000s'}},[_c('v-slide-group',{staticClass:"py-0"},_vm._l((_vm.items),function(item,index){return _c('v-slide-item',{key:index,staticClass:"mr-2"},[_c('v-list',{staticClass:"pa-0 d-flex align-item-center",attrs:{"two-line":"","width":"380px","height":"100px","justify":"center"}},[_c('v-list-item',{staticStyle:{"height":"100px"}},[_c('v-avatar',{staticClass:"mr-4",attrs:{"size":"60","color":"grey"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar,"lazy-src":item.avatar},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5","size":"20","width":"2"}})],1)]},proxy:true}],null,true)}):_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account")])],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"pa-0",attrs:{"width":"100%"}},[_c('v-list-item-title',{staticClass:"pl-0"},[_c('div',{staticClass:"title",staticStyle:{"font-size":"16px !important","line-height":"20px !important"}},[_vm._v("\n                    "+_vm._s(item.user_name)+"\n                  ")]),_vm._v(" "),(_vm.show)?_c('span',{staticClass:"text-info-yellow font-weight-bold title mt-1"},[_vm._v(_vm._s(_vm._f("currency")(item.valor,"", 0, {
                          thousandsSeparator: ".",
                          decimalSeparator: ",",
                        })))]):_c('span',{staticClass:"text-info-yellow font-weight-bold title mt-1"},[_vm._v("-")])]),_vm._v(" "),_c('v-list-item-subtitle',{staticStyle:{"height":"13px"}},[_c('v-row',{staticClass:"pl-0 mr-0 ml-0",attrs:{"justify":"start","align":"center"}},[(item.gap)?_c('v-col',{staticClass:"pa-0 pr-2"},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("GAP:")]),_vm._v(" "),_c('span',{staticClass:"text-info-blue font-weight-bold overline"},[_vm._v(_vm._s(_vm._f("percent")(item.gap)))]),_vm._v(" "),_c('span',{staticClass:"text-info-yellow title"},[_vm._v("|")]),_vm._v(" "),(_vm.show)?_c('span',{staticClass:"text-info-blue font-weight-bold overline"},[_vm._v(_vm._s(_vm._f("currency")(item.gap_valor,"", 0, {
                              thousandsSeparator: ".",
                              decimalSeparator: ",",
                            })))]):_c('span',{staticClass:"text-info-blue font-weight-bold overline"},[_vm._v("-")])]):_vm._e(),_vm._v(" "),(item.valor_ant)?_c('v-col',{staticClass:"pa-0"},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Ant:")]),_vm._v(" "),_c('span',{staticClass:"text-info-blue font-weight-bold overline"},[_vm._v(_vm._s(_vm._f("percent")(item.porc_ant)))]),_vm._v(" "),_c('span',{staticClass:"text-info-yellow title"},[_vm._v("|")]),_vm._v(" "),(_vm.show)?_c('span',{staticClass:"text-info-blue font-weight-bold overline"},[_vm._v(_vm._s(_vm._f("currency")(item.valor_ant,"", 0, {
                              thousandsSeparator: ".",
                              decimalSeparator: ",",
                            })))]):_c('span',{staticClass:"text-info-blue font-weight-bold overline"},[_vm._v("-")])]):_vm._e()],1)],1)],1)],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }