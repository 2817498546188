<template>
  <div class="fundo" style="background-image: url(./img/porquinhos_v2/fundo.jpg);">
    <div class="container-fluid h-100">
      <div class="row h-100 align-items-center">
        <div class="col-12 text-center" v-if="loading">
          <img style="max-width: 100px;" src="/img/loading.gif" alt="Carregando" />
        </div>
        <div class="col-12" style="padding-bottom: 135px;" v-else>
          <div class="unidades">
            <div class="unidades_unidade" v-for="(item, index) in items" :key="index" :class="item.metaAteHojeAtingida ? '' : 'border-error'">
              <div class="unidades_unidade_nome">
                {{item.unidade}}
              </div>
              <div class="unidades_unidade_consultor">
                {{item.consultor}}
              </div>
              <div class="unidades_unidade_grafico">
                <!-- <div class="percent">
                  <svg>
                    <circle cx="80" cy="50" r="45"></circle>
                    <circle cx="80" cy="50" r="45" :style="'--percent: '+retornaProgresso(item.meta, item.valor)"></circle>
                  </svg>
                  <div class="number">
                    <h3>{{retornaProgresso(item.meta, item.valor)}}%</h3>
                  </div>
                </div> -->
                <div class="percent">
                  <svg>
                    <circle cx="42" cy="34" r="30"></circle>
                    <circle cx="42" cy="34" r="30" :style="'--percent: '+retornaProgresso(item.meta, item.valor)"></circle>
                  </svg>
                  <div class="number">
                    <h3>{{retornaProgresso(item.meta, item.valor)}}%</h3>
                  </div>
                </div>
              </div>
              <div class="unidades_unidade_meta">
                <!-- <label>
                  Meta
                </label>
                <br> -->
                R$
                <number
                  ref="number2"
                  :from="0"
                  :to="item.meta"
                  :format="theFormat"
                  :duration="5"
                  :delay="1"
                  easing="Power1.easeOut"/>
              </div>
              <div class="unidades_unidade_valor">
                <!-- <label>
                  Vendido
                </label>
                <br> -->
                R$
                <number
                  ref="number"
                  :from="0"
                  :to="item.valor"
                  :format="theFormat"
                  :duration="5"
                  :delay="1"
                  easing="Power1.easeOut"/>
              </div>
            </div>
          </div>
          <div class="total_area">
            <div class="meta_vendido">
              Meta: 
              R$ 
              <number
                ref="number"
                :from="0"
                :to="totalMeta"
                :format="theFormat"
                :duration="5"
                :delay="1"
                easing="Power1.easeOut"/>
            </div>
            <div class="total_vendido">
              Total: 
              R$ 
              <number
                ref="number"
                :from="0"
                :to="totalVendido"
                :format="theFormat"
                :duration="5"
                :delay="1"
                easing="Power1.easeOut"/>
            </div>
            <div class="faltam_vendido">
              Faltam: 
              R$ 
              <number
                ref="number"
                :from="0"
                :to="faltam"
                :format="theFormat"
                :duration="5"
                :delay="1"
                easing="Power1.easeOut"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="area_footer">
      <marquee width="100%" direction="right" scrollamount="10" behavior="alternate" loop="infinite" class="titulo_super">
        <span v-for="index in 20" :key="index">
          <span class="pl-1"></span> MÊS DE {{ moment().locale('pt-br').format('MMMM') }} <span class="pr-1"></span>| 
        </span>
      </marquee>
    </div>
  </div>
</template>

<script>
import VueNumber from 'vue-number-animation'
import moment from 'moment'
import axios from 'axios'
// import VueCircle from 'vue2-circle-progress'
export default {
  props: ['factory'],
  components: {
    VueNumber,
    // VueCircle
  },
  data() {
    return {
      valor: 350000,
      loading: true,
      fill : { gradient: ["#2b72b7"] },
      items: [],
      totalMeta: 0,
      totalVendido: 0,
      faltam: 0,
      animation: {
        duration: 4000,
        easing: "circleProgressEasing"
      }
    }
  },
  methods: {
    moment,
    retornaProgresso (meta, valor) {
      if (this.meta === 0) {
        return 0
      }
      let porcentagem = (valor / meta) * 100
      let valorRetorno = Math.min(Math.max(porcentagem, 0), 100)
      if(valorRetorno > 0 && valorRetorno < 1) {
        return 1
      } else {
        return Number(valorRetorno).toFixed(0)
      }
    },
    theFormat(number) {
      const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      return new Intl.NumberFormat('pt-BR', options).format(parseFloat(number))
    },
    loadContent () {
      this.loading = true
      this.getDados()
    },
    somaTotal (lista) {
      let total = 0
      let meta = 0
      lista.forEach(el => {
        total = Number(total + el.valor)
        meta = Number(meta + el.meta)
      })
      this.totalVendido = total
      this.totalMeta = meta
      this.faltam = meta-total
    },
    async getDados () {
      let dados = {
        saasid: 212,
        factory_id: this.factory.id ? this.factory.id : 999999,
        periodo: moment().format('Y-MM-DD'),
      }
      axios
        .post("/api/faturamentoTotalSuperSextaUnidades", dados)
        .then(response => {
          this.items = response.data
          this.timeoutAtt()
          this.loading = false
          console.log('nova request')
          this.somaTotal(this.items)
          console.log(response)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados()
      }, 180000)
      // }, 15000)
    },
  },
  created () {
    this.loadContent()
  }
}
</script>

<style lang="scss" scoped>
.fundo {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: auto;
  position: relative;
}
.area_footer {
  bottom: 0;
  background: #ffc911;
  height: 18px;
  position: fixed;
  width: 100%;
  z-index: 20;
}
.titulo_super {
  color: #102945;
  left: 0;
  font-family: 'Raleway';
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  position: absolute;
  padding: 0 3px;
  text-transform: uppercase;
  top: 0px;
}
.unidades {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &_unidade {
    background-color: rgba(17, 47, 81, 0.7);
    border: 2px #204671 solid;
    border-radius: 5px;
    margin: 1px;
    padding: 5px 2px;
    text-align: center;
    width: 75px;
    // @media (max-width: 1480px){
    //   width: 150px;
    // }
    &_nome {
      color: white;
      font-weight: 900;
      min-height: 17px;
      text-transform: uppercase;
      font-size: 7px;
      line-height: normal;
      word-break: break-word;
      // @media (max-width: 1480px){
      //   font-size: 6px;
      //   min-height: 13px;
      // }
    }
    &_consultor {
      color: white;
      font-weight: 300;
      min-height: 18px;
      font-size: 7px;
      line-height: normal;
      text-transform: uppercase;
      word-break: break-word;
      // @media (max-width: 1480px){
      //   font-size: 5px;
      //   min-height: 15px;
      // }
    }
    &_grafico {
      margin-top: -20px;
    }
    &_meta {
      color: white;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 7px;
      margin-top: -5px;
    }
    &_valor {
      color: white;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 9px;
      margin: -10px 0 -7px 0;
    }
  }
}
.total_area {
  bottom: 20px;
  left: 30px;
  position: absolute;
}
.meta_vendido {
  background-color: rgba(17, 47, 81, 1);
  border: 2px #204671 solid;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 10px;
  text-align: center;
}
.total_vendido {
  background-color: rgba(17, 47, 81, 1);
  border: 2px #204671 solid;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
  padding: 3px 10px;
  text-align: center;
}
.faltam_vendido {
  background-color: rgba(17, 47, 81, 1);
  border: 2px #204671 solid;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 3px 10px;
  text-align: center;
}

.percent {
  position: relative;
}

.percent svg {
  position: relative;
  width: 90px;
  height: 90px;
  filter: drop-shadow(0px 0px 5px rgba(255,255,255, 0.1));
  transform: rotate(-90deg);
}

.percent svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: rgba(47, 47, 47, 1);
  // stroke: #0e2642;
  stroke-width: 8;
  stroke-linecap: round;
}

.percent svg circle:last-of-type {
  stroke-dasharray: 185px;
  stroke-dashoffset: calc(185px - (185px * var(--percent)) / 100);
  // stroke: #387bbc;
  stroke: rgba(56, 123, 188, 1);
  transition: all 5s;
}
.percent .number {
  position: absolute;
  top: 48px;
  left: calc(50% - -1px);
  transform: translate(-50%, -50%);
}

.percent .number h3 {
  font-weight: bold;
  font-size: 18px;
}
.border-error {
  // animation: borderChange infinite 0.5s;
  border: 2px solid #b1d2f3;
}
@keyframes borderChange {
  0% {
    border-color: red;
  }
  25% {
    border-color: #204671;
  }
  75% {
    border-color: #204671;
  }
  100% {
    border-color: red;
  }
}
</style>
