<template>
  <div>
    <v-card class="text-center" flat width="100%" height="740">
      <v-card-title class="title" style="font-size: 16px">
        <v-row justify="center" align="center">
          <v-col>
            <span class="text-center">{{ title }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        style="color: white; max-height: 670px; overflow-y: auto"
        class="mt-3 custom_scroll"
      >
        <div v-for="(item, index) in products" :key="index">
          <v-row align="center" justify="start" class="text-center mt-0">
            <v-col class="pa-0 text-left pl-4" cols="12" sm="12" md="2" lg="2">
              <span class="body-2">{{ item.nome_produto }}</span>
            </v-col>
            <v-col class="pa-0" cols="12" sm="12" md="3" lg="3">
              <p>Dias</p>
              <span class="text-info-blue font-weight-bold subtitle-1">{{
                item.dias
              }}</span>
            </v-col>
            <v-col class="pa-0" cols="12" sm="12" md="3" lg="3">
              <p>Funil</p>
              <span
                class="text-info-yellow font-weight-bold subtitle-1"
                v-if="show"
                >{{
                  item.funil
                    | currency("", 0, {
                      thousandsSeparator: ".",
                      decimalSeparator: ",",
                    })
                }}</span
              >
              <span class="text-info-yellow font-weight-bold subtitle-1" v-else
                >-</span
              >
            </v-col>
            <v-col class="pa-0" cols="12" sm="12" md="4" lg="4">
              <p>Janela do Mês</p>
              <span
                class="text-info-yellow font-weight-bold subtitle-1"
                v-if="show"
                >{{
                  item.janela
                    | currency("", 0, {
                      thousandsSeparator: ".",
                      decimalSeparator: ",",
                    })
                }}</span
              >
              <span class="text-info-yellow font-weight-bold subtitle-1" v-else
                >-</span
              >
              <div style="margin-top: -7px">
                <span class="" style="font-size: 11px" v-if="show">{{
                  item.meta
                    | currency("", 0, {
                      thousandsSeparator: ".",
                      decimalSeparator: ",",
                    })
                }}</span>
                <span class="" style="font-size: 11px" v-else>-</span>
              </div>
            </v-col>
          </v-row>
          <v-divider
            v-if="index + 1 != products.length"
            class="mb-2"
          ></v-divider>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// moment(newValue.data).fromNow()

export default {
  props: ["title", "show"],

  data() {
    return {
      loading: false,
      value: [],
      products: [],
    };
  },

  created() {
    var items = {
      porc: [
        {
          nome_produto: "Implantodontia",
          id_produto: 14,
          dias: 1,
          qtd_venda: 10,
          color: "#4fa0c0",
          funil: 55000,
          janela: 100000,
          meta: 155000,
          porcento_venda: 10,
        },
        {
          nome_produto: "Prótese",
          id_produto: 9,
          dias: 1,
          qtd_venda: 35,
          color: "#ff7f10",
          funil: 25000,
          janela: -1000,
          meta: 26000,
          porcento_venda: 35,
        },
        {
          nome_produto: "Dentística",
          id_produto: 6,
          dias: 1,
          qtd_venda: 1,
          color: "#31d2eb",
          funil: 2500,
          janela: 12500,
          meta: 15000,
          porcento_venda: 1,
        },
        {
          nome_produto: "Endodontia",
          id_produto: 7,
          dias: 1,
          qtd_venda: 20,
          color: "#18a2fb",
          funil: 5000,
          janela: 45000,
          meta: 50000,
          porcento_venda: 20,
        },
        {
          nome_produto: "Cirurgia",
          id_produto: 10,
          dias: 1,
          qtd_venda: 10,
          color: "#ad6384",
          funil: 6500,
          janela: 22000,
          meta: 28500,
          porcento_venda: 10,
        },
        {
          nome_produto: "Prevenção",
          id_produto: 4,
          dias: 1,
          qtd_venda: 10,
          color: "#4bc0c0",
          funil: 3700,
          janela: 12300,
          meta: 16000,
          porcento_venda: 10,
        },
        {
          nome_produto: "Ortodontia",
          id_produto: 11,
          dias: 1,
          qtd_venda: 1,
          color: "#ae9f40",
          funil: 1150,
          janela: 3150,
          meta: 4300,
          porcento_venda: 1,
        },
        {
          nome_produto: "Periodontia",
          id_produto: 8,
          dias: 1,
          qtd_venda: 1,
          color: "#ee9f40",
          funil: 260,
          janela: 9740,
          meta: 10000,
          porcento_venda: 1,
        },
        {
          nome_produto: "Odontopediatria",
          id_produto: 5,
          dias: 1,
          qtd_venda: 1,
          color: "#36f2eb",
          funil: 135,
          janela: 8865,
          meta: 9000,
          porcento_venda: 1,
        },
        {
          nome_produto: "Radiologia",
          id_produto: 12,
          dias: 1,
          qtd_venda: 6,
          color: "#ff1784",
          funil: 120,
          janela: 0,
          porcento_venda: 6,
        },
        {
          nome_produto: "Outros",
          id_produto: 9999,
          dias: 1,
          qtd_venda: 5,
          color: "#000",
          funil: 0,
          janela: 0,
          meta: 0,
          porcento_venda: 5,
        },
      ],
      qtd: [
        {
          nome_produto: "Implantodontia",
          id_produto: 14,
          dias: 1,
          qtd_venda: 10,
          color: "#4fa0c0",
          funil: 39290,
          janela: 100710,
          meta: 140000,
          porcento_venda: 10,
        },
        {
          nome_produto: "Prótese",
          id_produto: 9,
          dias: 1,
          qtd_venda: 35,
          color: "#ff7f10",
          funil: 28464.25,
          janela: -2464.25,
          meta: 26000,
          porcento_venda: 35,
        },
        {
          nome_produto: "Dentística",
          id_produto: 6,
          dias: 1,
          qtd_venda: 1,
          color: "#31d2eb",
          funil: 7684.970001220703,
          janela: 20315.029998779297,
          meta: 28000,
          porcento_venda: 1,
        },
        {
          nome_produto: "Endodontia",
          id_produto: 7,
          dias: 1,
          qtd_venda: 20,
          color: "#18a2fb",
          funil: 6652.539978027344,
          janela: 35347.460021972656,
          meta: 42000,
          porcento_venda: 20,
        },
        {
          nome_produto: "Cirurgia",
          id_produto: 10,
          dias: 1,
          qtd_venda: 10,
          color: "#ad6384",
          funil: 6470,
          janela: 28530,
          meta: 35000,
          porcento_venda: 10,
        },
        {
          nome_produto: "Prevenção",
          id_produto: 4,
          dias: 1,
          qtd_venda: 10,
          color: "#4bc0c0",
          funil: 3520.5,
          janela: 11479.5,
          meta: 15000,
          porcento_venda: 10,
        },
        {
          nome_produto: "Ortodontia",
          id_produto: 11,
          dias: 1,
          qtd_venda: 1,
          color: "#ae9f40",
          funil: 1149,
          janela: 40851,
          meta: 42000,
          porcento_venda: 1,
        },
        {
          nome_produto: "Periodontia",
          id_produto: 8,
          dias: 1,
          qtd_venda: 1,
          color: "#ee9f40",
          funil: 267.510009765625,
          janela: 8732.489990234375,
          meta: 9000,
          porcento_venda: 1,
        },
        {
          nome_produto: "Odontopediatria",
          id_produto: 5,
          dias: 1,
          qtd_venda: 1,
          color: "#36f2eb",
          funil: 190,
          janela: 8810,
          meta: 9000,
          porcento_venda: 1,
        },
        {
          nome_produto: "Radiologia",
          id_produto: 12,
          dias: 1,
          qtd_venda: 6,
          color: "#ff1784",
          funil: 160,
          janela: 0,
          porcento_venda: 6,
        },
        {
          nome_produto: "Outros",
          id_produto: 9999,
          dias: 1,
          qtd_venda: 5,
          color: "#000",
          funil: 0,
          janela: 0,
          meta: 0,
          porcento_venda: 5,
        },
      ],
    };
    for (let i = 0; i < items.porc.length; i++) {
      this.products.push(items.porc[i]);
    }
  },
};
</script>
<style  scoped>
p {
  margin-bottom: 0px;
}
</style>