<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  // mixins: [reactiveProp],
  props: ["users", "graphic"],

  data() {
    return {
      loading: false,
      value: [],
      chartdata: {
        labels: [
          "Total de ligações",
          "Atendidas",
          "Falhas",
          "Sem Resposta",
          "Ocupadas",
        ],
        datasets: [
          {
            label: "Ligações Geral",
            borderColor: [
              "#20649a",
              "#1a711e",
              "#ae332a",
              "#5a1e68",
              "#8f661b",
            ],
            borderWidth: 2.3,
            // fill: false,
            // lineTension: 0,
            // pointBorderColor: "#012433",
            // pointBackgroundColor: "#00ffff",
            // pointBorderWidth: 0,
            backgroundColor: [
              "#2196f3",
              "#4caf50",
              "#f44336",
              "#9c27b0",
              "#ffa500",
            ],
            data: this.graphic,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 15,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          display: false,
        },
        animation: {
          duration: 1,
        },
        plugins: {
          datalabels: {
            anchor: "end",
            // align: 'top',
            align: ["center", "top", "top", "top", "top"],
            display: true,
            color: "white",
            backgroundColor: [
              "#2196f3",
              "#4caf50",
              "#f44336",
              "#9c27b0",
              "#ffa500",
            ],
            borderColor: [
              "#20649a",
              "#1a711e",
              "#ae332a",
              "#5a1e68",
              "#8f661b",
            ],
            borderWidth: 2.3,
            borderRadius: 10,
            font: {
              size: 13,
            },
          },
        },
        tooltips: {
          mode: "label",
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return "Ligações: " + tooltipItem.yLabel;
            },
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontSize: 15,
                fontColor: "#fff",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontSize: 15,
                fontColor: "#fff",
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      },
    };
  },

  mounted() {
    this.renderChart(this.chartdata, this.options);
  },

  watch: {
    users() {
      this.chartdata.datasets[0].data = this.graphic;
      this.renderChart(this.chartdata, this.options);
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
</style>