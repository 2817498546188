<template>
  <div class="fill-height">
    <v-row justify="center">
      <v-col class="pr-0">
        <v-card width="100%" height="100%" flat>
          <v-card-title class="py-1">{{title}}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0" md="6" :class="factory.id != 999999 ? 'col-md-6' : 'col-md-12'">
        <v-row class="ma-0">
          <v-col class="pr-0 pt-0" md="6">
            <v-card class="text-center" flat width="100%" height="100%">
              <v-card-title style="font-size: 18px;">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">QTD AGENDAMENTO</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text v-if="!loading">
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col>
                    <span
                      class="text-info-blue overline font-weight-bold"
                    >{{value.agendados_ant_porc | percent}}</span>
                    <span class="text-info-yellow h4">|</span>
                    <span class="text-info-yellow h5 font-weight-bold">{{value.agendados}}</span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-skeleton-loader type="list-item-two-line" height="50" v-if="loading"></v-skeleton-loader>
            </v-card>
          </v-col>
          <v-col class="pt-0" md="6">
            <v-card class="text-center" flat width="100%" height="100%">
              <v-card-title style="font-size: 18px;">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">QTD REMARCARDO</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text v-if="!loading">
                <v-row justify="center" align="center" class="mt-n5">
                  <v-col>
                    <span
                      class="text-info-blue overline font-weight-bold"
                    >{{value.reagendados_ant_porc | percent}}</span>
                    <span class="text-info-yellow h4">|</span>
                    <span class="text-info-yellow h5 font-weight-bold">{{value.reagendados}}</span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-skeleton-loader type="list-item-two-line" height="50" v-if="loading"></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col md="12">
            <v-row>
              <v-col class="pr-0 pt-0" md="6">
                <v-card class="text-center" flat width="100%" height="100%">
                  <v-card-title style="font-size: 18px;">
                    <v-row justify="center" align="center">
                      <v-col>
                        <span class="text-center">TOTAL INV. MARKETING</span>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text v-if="!loadingInv">
                    <v-row justify="center" align="center" class="mt-n5">
                      <v-col>
                        <span
                          class="text-info-blue overline font-weight-bold"
                        >{{valueInv.mkt_porc | percent}}</span>
                        <span class="text-info-yellow h4">|</span>
                        <span
                          class="text-info-yellow h5 font-weight-bold"
                        >{{valueInv.mkt.valor | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','})}}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-skeleton-loader type="list-item-two-line" height="50" v-if="loadingInv"></v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col class="pt-0" md="6">
                <v-card class="text-center" flat width="100%" height="100%">
                  <v-card-title style="font-size: 18px;">
                    <v-row justify="center" align="center">
                      <v-col>
                        <span class="text-center">TOTAL FATURAMENTO</span>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text v-if="!loadingFatu">
                    <v-row justify="center" align="center" class="mt-n5">
                      <v-col>
                        <span
                          class="text-info-blue overline font-weight-bold"
                        >{{valueFatu.porc | percent}}</span>
                        <span class="text-info-yellow h4">|</span>
                        <span
                          class="text-info-yellow h5 font-weight-bold"
                        >{{valueFatu.faturamento | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','})}}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-skeleton-loader type="list-item-two-line" height="50" v-if="loadingFatu"></v-skeleton-loader>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pr-0 pt-0" md="6">
                <v-row>
                  <v-col class="pr-0" md="6">
                    <v-card class="text-center" flat width="100%" height="100%">
                      <v-card-title style="font-size: 16px;">
                        <v-row justify="center" align="center" class="mt-1">
                          <span class="text-center">
                            CPL
                            <br />FACEBOOK
                          </span>
                        </v-row>
                      </v-card-title>
                      <v-card-text v-if="!loadingInv">
                        <v-row justify="center" align="center" class="mt-n5">
                          <v-col>
                            <span
                              class="text-info-blue overline font-weight-bold"
                            >{{valueInv.face_porc | percent}}</span>
                            <span class="text-info-yellow h6">|</span>
                            <span
                              class="text-info-yellow h6 font-weight-bold"
                            >{{valueInv.face.valor | currency('', 2, { thousandsSeparator: '.', decimalSeparator: ','})}}</span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-skeleton-loader type="list-item-two-line" height="50" v-if="loadingInv"></v-skeleton-loader>
                    </v-card>
                  </v-col>
                  <v-col md="6" class="pl-1">
                    <v-card class="text-center" flat width="100%" height="100%">
                      <v-card-title style="font-size: 16px;">
                        <v-row justify="center" align="center" class="pt-1">
                          <v-col>
                            <span class="text-center">CPL REAL</span>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text v-if="!loadingCplReal">
                        <v-row justify="center" align="center" class="mt-n5">
                          <v-col>
                            <span
                              class="text-info-blue overline font-weight-bold"
                            >{{valueCplReal.cpl_real_porc | percent}}</span>
                            <span class="text-info-yellow h6">|</span>
                            <span
                              class="text-info-yellow h6 font-weight-bold"
                            >{{valueCplReal.cpl_real | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','})}}</span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-skeleton-loader
                        type="list-item-two-line"
                        height="50"
                        v-if="loadingCplReal"
                      ></v-skeleton-loader>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0" md="6">
                <v-card class="text-center" flat width="100%" height="100%">
                  <v-card-title style="font-size: 18px;">
                    <v-row justify="center" align="center">
                      <v-col>
                        <span class="text-center">NO SHOW</span>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text v-if="!loading">
                    <v-row justify="center" align="center" class="mt-n5">
                      <v-col>
                        <span
                          class="text-info-blue overline font-weight-bold"
                        >{{value.faltas_ant_porc | percent}}</span>
                        <span class="text-info-yellow h4">|</span>
                        <span class="text-info-yellow h5 font-weight-bold">{{value.faltas}}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-skeleton-loader type="list-item-two-line" height="50" v-if="loading"></v-skeleton-loader>
                </v-card>
              </v-col>
              <!-- <v-col class="pr-0 pt-0" md="6">
                <v-card class="text-center" flat width="100%" height="100%">
                  <v-card-text>
                    <v-img src="./img/logoOdontotop-white.svg"></v-img>
                    <span class="subtitle-1 white--text font-weight-bold">{{factory.name}}</span>
                  </v-card-text>
                </v-card>
              </v-col> -->
            </v-row>
          </v-col>
          <!-- <v-col md="4" class="pt-0">
            <v-card class="text-center" flat width="100%" height="100%">
              <v-card-title style="font-size: 18px;">
                <v-row justify="center" align="center">
                  <v-col>
                    <span class="text-center">AQUISIÇÃO DE CLIENTE</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="white--text" v-if="!loadingAquisicao">
                <div v-for="(item, index) in valueAquisicao" :key="index">
                  <v-row align="center" justify="start" class>
                    <v-col md="6" class="body-1 py-1 mt-1">{{item.origem}}</v-col>
                    <v-col
                      md="6"
                      class="font-weight-bold text-info-yellow body-1 py-1 mt-1"
                    >{{item.porc | percent}}</v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-skeleton-loader type="list-item-two-line" height="50" v-if="loadingAquisicao"></v-skeleton-loader>
            </v-card>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col class="pa-0" md="6" v-if="factory.id != 999999">
        <card-funil :factory="factory"></card-funil>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// moment(newValue.data).fromNow()
import axios from "axios";
import moment from "moment";

export default {
  props: ["title", "show", "factory", "date"],

  data() {
    return {
      loading: false,
      loadingAquisicao: false,
      loadingFatu: false,
      loadingInv: false,
      loadingCplReal: false,
      value: [],
      valueAquisicao: [],
      valueFatu: [],
      valueInv: [],
      valueCplReal: [],
      totalAquisicao: 0
    };
  },

  created() {
    this.getDados();
    // this.getAquisicao();
    this.getFaturamemto();
    this.getInvestimento();
    this.getCplReal();
  },
  watch: {
    date() {
      this.getDados();
      // this.getAquisicao();
      this.getFaturamemto();
      this.getInvestimento();
      this.getCplReal();
    }
  },
  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: 212,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/getMaquinaVendas", dados)
        .then(response => {
          console.log("getMaquinaVendas");
          console.log(response.data);
          this.value = response.data;
          this.loading = false;
          this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    getAquisicao(notLoading) {
      if (!notLoading) {
        this.loadingAquisicao = true;
      }
      var dados = {
        saasid: 212,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/getAquisicaoClientes", dados)
        .then(response => {
          console.log("getAquisicaoClientes");
          console.log(response.data);
          this.valueAquisicao = response.data;
          this.loadingAquisicao = false;
          // this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    getFaturamemto(notLoading) {
      if (!notLoading) {
        this.loadingFatu = true;
      }
      var dados = {
        saasid: 212,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/getFaturamemto", dados)
        .then(response => {
          console.log("getFaturamemto");
          console.log(response.data);
          this.valueFatu = response.data;
          this.loadingFatu = false;
          // this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    getInvestimento(notLoading) {
      if (!notLoading) {
        this.loadingInv = true;
      }
      var dados = {
        saasid: 212,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/getInvestimento", dados)
        .then(response => {
          console.log("getInvestimento");
          console.log(response.data);
          this.valueInv = response.data;
          this.loadingInv = false;
          // this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    getCplReal(notLoading) {
      if (!notLoading) {
        this.loadingCplReal = true;
      }
      var dados = {
        saasid: 212,
        factory_id: this.factory.id,
        periodo: this.date
      };
      axios
        .post("/api/getCplReal", dados)
        .then(response => {
          console.log("getCplReal");
          console.log(response.data);
          this.valueCplReal = response.data;
          this.loadingCplReal = false;
          // this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
        // this.getAquisicao(true);
        this.getFaturamemto(true);
        this.getInvestimento(true);
        this.getCplReal(true);
      }, 120000);
    }
  }
};
</script>