<template>
  <div>
    <v-card class="text-center" flat width="100%" height="165">
      <v-card-title class="title" style="font-size: 16px">
        <v-row justify="center" align="center">
          <v-col>
            <span
              class="text-center"
              style="text-transform: uppercase"
              v-if="items.ranking_nome != null"
              >{{ items.ranking_nome }}</span
            >
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="mt-5" v-if="items.ranking_nome != null">
        <v-row justify="center" align="center" v-if="!loading">
          <p class="overline mr-1">Unidade</p>
          <p class="text-info-yellow font-weight-bold body-1">
            {{ items.ranking_atual_unidade }}
          </p>
          <i
            style="margin-bottom: 16px; padding: 0px 5px 5px"
            v-if="items.ranking_atual_unidade < items.ranking_anterior_unidade"
            class="fa fa-arrow-up text-success"
          ></i>
          <i
            style="
              margin-bottom: 12px;
              padding: 0px 5px 5px;
              font-size: 20px !important;
            "
            v-if="items.ranking_atual_unidade == items.ranking_anterior_unidade"
            class="text-info-blue font-weight-bold body-2"
            >=</i
          >
          <i
            style="margin-bottom: 12px; padding: 0px 5px 5px"
            v-if="items.ranking_atual_unidade > items.ranking_anterior_unidade"
            class="fa fa-arrow-down text-danger"
          ></i>
          <sup
            ><p
              class="text-info-yellow body-1"
              style="font-size: 11px !important"
              v-if="
                Math.abs(
                  items.ranking_atual_unidade - items.ranking_anterior_unidade
                ) > 0
              "
            >
              {{
                Math.abs(
                  items.ranking_atual_unidade - items.ranking_anterior_unidade
                )
              }}
            </p></sup
          >
        </v-row>
        <v-row
          class="mt-n6"
          justify="center"
          align="center"
          v-if="!loading && items.nome_avaliador_1 != null"
        >
          <p class="overline mr-1">{{ items.nome_avaliador_1 }}</p>
          <p class="text-info-yellow font-weight-bold body-1">
            {{ items.ranking_atual_avaliador_1 }}
          </p>
          <i
            style="margin-bottom: 16px; padding: 0px 5px 5px"
            v-if="
              items.ranking_atual_avaliador_1 <
              items.ranking_anterior_avaliador_1
            "
            class="fa fa-arrow-up text-success"
          ></i>
          <i
            style="
              margin-bottom: 16px;
              padding: 0px 5px 5px;
              font-size: 20px !important;
            "
            v-if="
              items.ranking_atual_avaliador_1 ==
              items.ranking_anterior_avaliador_1
            "
            class="text-info-blue font-weight-bold body-2"
            >=</i
          >
          <i
            style="margin-bottom: 12px; padding: 0px 5px 5px"
            v-if="
              items.ranking_atual_avaliador_1 >
              items.ranking_anterior_avaliador_1
            "
            class="fa fa-arrow-down text-danger"
          ></i>
          <sup
            ><p
              class="text-info-yellow body-1"
              style="font-size: 11px !important"
              v-if="
                Math.abs(
                  items.ranking_atual_avaliador_1 -
                    items.ranking_anterior_avaliador_1
                ) > 0
              "
            >
              {{
                Math.abs(
                  items.ranking_atual_avaliador_1 -
                    items.ranking_anterior_avaliador_1
                )
              }}
            </p></sup
          >
        </v-row>
        <v-row
          class="mt-n6"
          justify="center"
          align="center"
          v-if="!loading && items.nome_avaliador_2 != null"
        >
          <p class="overline mr-1">{{ items.nome_avaliador_2 }}</p>
          <p class="text-info-yellow font-weight-bold body-1">
            {{ items.ranking_atual_avaliador_2 }}
          </p>
          <i
            style="margin-bottom: 16px; padding: 0px 5px 5px"
            v-if="
              items.ranking_atual_avaliador_2 <
              items.ranking_anterior_avaliador_2
            "
            class="fa fa-arrow-up text-success"
          ></i>
          <i
            style="
              margin-bottom: 16px;
              padding: 0px 5px 5px;
              font-size: 20px !important;
            "
            v-if="
              items.ranking_atual_avaliador_2 ==
              items.ranking_anterior_avaliador_2
            "
            class="text-info-blue font-weight-bold body-2"
            >=</i
          >
          <i
            style="margin-bottom: 12px; padding: 0px 5px 5px"
            v-if="
              items.ranking_atual_avaliador_2 >
              items.ranking_anterior_avaliador_2
            "
            class="fa fa-arrow-down text-danger"
          ></i>
          <sup
            ><p
              class="text-info-yellow body-1"
              style="font-size: 11px !important"
              v-if="
                Math.abs(
                  items.ranking_atual_avaliador_2 -
                    items.ranking_anterior_avaliador_2
                ) > 0
              "
            >
              {{
                Math.abs(
                  items.ranking_atual_avaliador_2 -
                    items.ranking_anterior_avaliador_2
                )
              }}
            </p></sup
          >
        </v-row>
        <v-skeleton-loader
          type="list-item-three-line"
          height="180"
          v-if="loading"
        ></v-skeleton-loader>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// moment(newValue.data).fromNow()
import axios from "axios";

export default {
  props: ["title", "show"],

  data() {
    return {
      loading: false,
      value: [],
      items: {
        nome_avaliador_1: "Dra Clinica Geral",
        nome_avaliador_2: null,
        ranking_nome: "Malaquita",
        ranking_atual_unidade: 1,
        ranking_anterior_unidade: 2,
        ranking_atual_avaliador_1: 1,
        ranking_anterior_avaliador_1: 2,
        ranking_atual_avaliador_2: null,
        ranking_anterior_avaliador_2: null,
      },
    };
  },
};
</script>