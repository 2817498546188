<template>
  <div>
    <v-overlay :absolute="true" :opacity="0.88" :value="overlay" :z-index="999999999999">
      <v-card class="pa-3 mb-12" style="background-color: #1e1e1e !important" v-if="show">
        <v-card-title>
          <v-row>
            <v-col md="auto">Esconder Informações</v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-icon @click="(overlay = false), (password = '')" color="red">mdi-close-thick</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-btn @click="(show = false), (overlay = false)">Esconder</v-btn>
        </v-card-text>
      </v-card>
      <v-card class="pa-3 mb-12" style="background-color: #1e1e1e !important" v-if="!show">
        <v-card-title>
          <v-row>
            <v-col md="auto">Código de Liberação</v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-icon @click="(overlay = false), (password = '')" color="red">mdi-close-thick</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <input
            v-if="!loadingOverlay"
            :disabled="loadingOverlay"
            autocomplete="off"
            maxlength="4"
            autofocus
            @input="verifyToken()"
            @blur="myFocusFunction()"
            id="getPass"
            v-model="password"
            class="mx-auto mb-2"
          />
          <v-row justify="center" v-else>
            <v-col class="d-flex justify-center">
              <v-progress-circular :width="3" :size="24" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
          <span class="red--text text-center" v-if="wrongCod">Código errado!</span>
        </v-card-text>
      </v-card>
      <v-date-picker
        @change="datePicker()"
        v-model="date"
        color="#32abf1"
        type="month"
        width="310"
        :max="new Date().toISOString().substr(0, 7)"
        class="mt-6"
        locale="pt-br"
      ></v-date-picker>
    </v-overlay>
    <div>
      <v-row class="ma-0" :value="$vuetify.theme.dark = true">
        <v-col md="5">
          <v-row>
            <v-col cols="12" sm="12" md="6" class="pr-0">
              <card-novo-orcamento
                :date="date"
                title="NOVOS ORÇAMENTOS"
                :show="show"
                :factory="factory"
              ></card-novo-orcamento>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pr-0">
              <card-faturamento-total
                @rocketChild="showRocket"
                :date="date"
                title="FATURAMENTO TOTAL"
                :show="show"
                :factory="factory"
              ></card-faturamento-total>
            </v-col>
          </v-row>
        </v-col>

        <v-col md="2">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pr-0" >
              <card-ranking
                :date="date"
                title="RANKING"
                :show="show"
                :factory="factory"
              ></card-ranking>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="5">
          <v-card class="d-flex align-center" flat height="165">
            <span class="float_title" style="right: calc(50% - 67.5px);">Gráfico de Metas</span>
            <card-grafico-metas
              :date="date"
              saasid="180"
              height="160"
              class="w-100"
              :factory="factory"
            ></card-grafico-metas>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-n3 ma-0">
        <v-col cols="12" sm="12" md="12">
          <card-usuarios :key="show" :date="date" :show="show" :factory="factory"></card-usuarios>
        </v-col>
      </v-row>
      <v-row class="mt-n3 ma-0">
        <v-col cols="12" sm="12" md="6" class="pr-0">
          <!-- <v-card class="align-center" flat height="350">
            <v-card-title class="pb-0">PRODUTO</v-card-title>
            <card-grafico-produtos :date="date" title="PRODUTO" :height="230" :factory="factory"></card-grafico-produtos>
            <card-grafico-produtos-pequeno
              :date="date"
              title="PRODUTO"
              :width="150"
              class="minor-graph"
              :height="125"
              :factory="factory"
              style="right:0;"
            ></card-grafico-produtos-pequeno>
          </v-card> -->
          <v-col cols="12" sm="12" md="12" class="pr-0 pl-0 pt-0">
            <card-mapa :date="date" title="LOCALIZAÇÃO DOS NEGÓCIOS" :factory="factory"></card-mapa>
          </v-col>
        </v-col>
        <!-- <v-col cols="12" sm="12" md="3" class="pr-0">
          <card-jornada-produto
            :date="date"
            title="JORNADA POR PRODUTO"
            height="350"
            :show="show"
            :factory="factory"
          ></card-jornada-produto>
        </v-col> -->
        <v-col cols="12" sm="12" md="6">
          <v-row>
            <v-col>
              <v-card flat height="196">
                <v-card-title class="pb-0 pt-2" style="font-size:16px;">FLUXO TOTAL DE AVALIAÇÕES</v-card-title>
                <card-grafico-avaliacoes :date="date" height="160" :factory="factory"></card-grafico-avaliacoes>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-n3">
            <v-col cols="12" sm="12" md="12">
              <card-avaliacoes
                :date="date"
                title="FATURAMENTO TOTAL"
                :show="show"
                :factory="factory"
              ></card-avaliacoes>
            </v-col>
          </v-row>
          <v-row class="mt-n3">
            <v-col cols="12" sm="12" md="8">
              <!-- <card-maquina-venda :date="date" title="MÁQUINA DE VENDAS" :factory="factory"></card-maquina-venda> -->
              <card-funil-estatisticas :show="show" :date="date" title="ESTATÍSTICAS - ÚLTIMOS 30 DIAS" :factory="factory"></card-funil-estatisticas>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <card-aquisicao-clientes :date="date" :factory="factory"></card-aquisicao-clientes>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <transition name="fade">
      <div class="rocket_background" v-if="rocket">
        <ul class="star">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div class="rocket_area">
          <div class="rocket_position">
            <img class="rocket-launch" src="/img/rocket.png" alt="Foguete Odonto" />
          </div>
          <div class="exhaust-flame"></div>
          <ul class="exhaust-fumes">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </transition>
    <div style="position:fixed;bottom:20px;right:20px;z-index: 9999;">
      <v-btn
        color="#012433"
        elevation="5"
        fab
        small
        dark
        @click="openOverlay()"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </div>
    <!-- <div class="addBg" @click="apareceFoguete()">Zummmm</div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["factory"],
  data() {
    return {
      rocket: false,
      date: new Date().toISOString().substr(0, 7),
      show: false,
      overlay: false,
      password: "",
      loadingOverlay: false,
      wrongCod: false
    };
  },
  
  created() {},
  methods: {
    showRocket(a) {
      if(a) {
        this.apareceFoguete()    
      }
    },
    apareceFoguete() {
      this.rocket = !this.rocket;
      setTimeout(() => {
        this.rocket = !this.rocket;
      }, 5000);
    },
    datePicker() {
      console.log(this.date);
      this.overlay = false;
    },
    verifyToken() {
      if (this.password.length == 4) {
        this.loadingOverlay = true;
        this.wrongCod = false;
        let pass = {
          dash: 'normal',
          passwd: this.password,
          factory_id: this.factory
        };
        axios
          .post("/api/verifyInfo", pass)
          .then(response => {
            this.loadingOverlay = false;
            if (response.data == true) {
              this.show = true;
              this.overlay = false;
              this.timeoutAtt();
              console.log("Passwd certa.");
              this.wrongCod = false;
            } else {
              this.wrongCod = true;
              setTimeout(() => {
                this.myFocusFunction();
              }, 300);
              console.log("Passwd errada.");
            }
            this.password = "";
          })
          .catch(err => {
            this.loadingOverlay = false;
            console.error(err);
          });
      }
    },
    openOverlay() {
      this.overlay = true;
      setTimeout(() => {
        this.myFocusFunction();
      }, 300);
    },
    timeoutAtt() {
      setTimeout(() => {
        this.show = false;
      }, 5 * 60000);
    },
    myFocusFunction() {
      if (document.getElementById("getPass")) {
        document.getElementById("getPass").focus();
      }
    }
  }
};
</script>

<style>
.v-date-picker-title__date {
  font-size: 34px;
  text-align: left;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  margin-bottom: -8px;
  padding-top: 10px;
}
</style>
<style scoped lang="scss">
$char-w: 1ch;
$gap: 0.5 * $char-w;
$n-char: 4;
$in-w: $n-char * ($char-w + $gap);

input {
  display: block;
  border: none;
  padding: 0;
  width: $in-w;
  background: repeating-linear-gradient(
      90deg,
      rgb(231, 182, 19) 0,
      rgb(231, 182, 19) $char-w,
      transparent 0,
      transparent $char-w + $gap
    )
    0 100% / #{$in-w - $gap} 2px no-repeat;
  font: 5ch droid sans mono, consolas, monospace;
  letter-spacing: $gap;
  outline: none;
  color: transparent;
  text-shadow: 0 0 0 rgb(231, 182, 19);
}
.rocket_background {
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  transition: all 0.3s;
  z-index: 9999998;
}
.visivel {
  visibility: visible;
}
.invisivel {
  visibility: hidden;
}
.rocket_area {
  bottom: 45px;
  position: fixed;
  left: 39px;
  transform: rotate(33deg);
  z-index: 9999999;
}
.rocket_position {
}
.rocket-launch {
  animation: bounce 6s;
  max-width: 260px;
  position: absolute;
  bottom: 213px;
  left: calc(50% + 37px);
  animation-fill-mode: forwards;
}
$white: #f5f5f5;
.exhaust-flame {
  opacity: 0;
  bottom: 75px;
  position: absolute;
  width: 35px;
  transform: rotate(23deg);
  background: linear-gradient(to bottom, transparent 10%, $white 100%);
  height: 175px;
  left: calc(50% + 56px);
  animation: exhaust 0.2s infinite;
}
.exhaust-fumes {
  padding-left: 0px !important;
}
.exhaust-fumes li {
  width: 60px;
  height: 60px;
  background-color: $white;
  list-style: none;
  position: absolute;
  border-radius: 100%;
  &:first-child {
    width: 170px;
    height: 170px;
    bottom: 0px;
    animation: fumes 5s infinite;
  }
  &:nth-child(2) {
    width: 150px;
    height: 150px;
    left: -120px;
    bottom: 0px;
    animation: fumes 3.2s infinite;
  }
  &:nth-child(3) {
    width: 120px;
    height: 120px;
    left: -40px;
    bottom: 0px;
    animation: fumes 3s 1s infinite;
  }
  &:nth-child(4) {
    width: 100px;
    height: 100px;
    left: -170px;
    animation: fumes 4s 2s infinite;
    bottom: 0px;
  }
  &:nth-child(5) {
    width: 130px;
    height: 130px;
    left: -120px;
    bottom: 0px;
    animation: fumes 5s infinite;
  }
  &:nth-child(6) {
    bottom: -66px;
    width: 170px;
    height: 170px;
    left: -60px;
    animation: fumes2 10s infinite;
  }
  &:nth-child(7) {
    width: 100px;
    height: 100px;
    left: -100px;
    bottom: 0px;
  }
  &:nth-child(8) {
    width: 110px;
    height: 110px;
    left: 70px;
    bottom: 0px;
  }
  &:nth-child(9) {
    width: 90px;
    height: 90px;
    left: 140px;
    bottom: 0px;
    animation: fumes 20s infinite;
  }
}
@keyframes fumes {
  50% {
    transform: scale(1.5);
    background-color: transparent;
  }
  51% {
    transform: scale(0.8);
  }
  100% {
    background-color: $white;
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    bottom: 10vh;
    left: 1.5vw;
    transform: translate3d(0px, 0px, 0);
  }
  9% {
    bottom: 10vh;
    left: 1.5vw;
    transform: translate3d(0px, -2px, 0px);
  }
  18% {
    bottom: 10vh;
    left: 1.5vw;
    transform: translate3d(0px, 0px, 0);
  }
  27% {
    bottom: 10vh;
    left: 1.5vw;
    transform: translate3d(0px, -2px, 0);
  }
  36% {
    bottom: 10vh;
    left: 1.5vw;
    transform: translate3d(0px, 0px, 0);
  }
  25% {
    bottom: 10vh;
    left: 1.5vw;
    transform: translate3d(0px, -2px, 0);
  }
  45% {
    bottom: 10vh;
    left: 1.5vw;
    transform: translate3d(0px, 0px, 0);
  }
  100% {
    transform: rotate(-20deg);
    bottom: 110vw;
    left: 110vh;
  }
}

@keyframes exhaust {
  0% {
    background: linear-gradient(to bottom, transparent 10%, $white 100%);
  }
  50% {
    background: linear-gradient(to bottom, transparent 8%, $white 100%);
  }
  75% {
    background: linear-gradient(to bottom, transparent 12%, $white 100%);
  }
}

@keyframes fumes2 {
  50% {
    transform: scale(1.1);
  }
}
.star {
  left: 85%;
  padding-left: 0;
  position: fixed;
  top: 13%;
}
.star li {
  list-style: none;
  position: absolute;
  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: $white;
  }
  &:before {
    width: 20px;
    height: 2px;
    border-radius: 50%;
  }
  &:after {
    height: 15px;
    width: 2px;
    left: 9px;
    top: -6px;
  }
  &:first-child {
    top: -30px;
    left: -210px;
    animation: twinkle 0.4s infinite;
  }
  &:nth-child(2) {
    top: 0;
    left: 60px;
    animation: twinkle 0.5s infinite;
    &:before {
      height: 1px;
      width: 5px;
    }
    &:after {
      width: 1px;
      height: 5px;
      top: -2px;
      left: 2px;
    }
  }
  &:nth-child(3) {
    left: 120px;
    top: 220px;
    animation: twinkle 1s infinite;
  }
  &:nth-child(4) {
    left: -100px;
    top: 200px;
    animation: twinkle 0.5s ease infinite;
  }
  &:nth-child(5) {
    left: 170px;
    top: 100px;
    animation: twinkle 0.4s ease infinite;
  }
  &:nth-child(6) {
    top: 87px;
    left: -79px;
    animation: twinkle 0.2s infinite;
    &:before {
      height: 1px;
      width: 5px;
    }
    &:after {
      width: 1px;
      height: 5px;
      top: -2px;
      left: 2px;
    }
  }
}
@keyframes twinkle {
  80% {
    transform: scale(1.1);
    opacity: 0.7;
  }
}
.addBg {
  bottom: 20px;
  position: fixed;
  right: 20px;
  z-index: 99999999;
}
.oculta {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>