<template>
  <v-row>
    <v-col>
      <infinite-slide-bar
        :duration="duration()"
        :delay="!loading ? '10s': '50000s'"
        v-if="!loading"
      >
        <v-slide-group class="py-0">
          <v-slide-item v-for="(item,index) in items" :key="index" class="mr-2">
            <v-list two-line width="420px" :class="item.gap_valor < -5000 && show? 'red-bg': '' || item.gap_valor > 5000 && show ? 'green-bg' : '' || item.gap_valor < -3000 && show ? 'yellow-bg' : ''">
              <v-list-item>
                <v-row>
                  <v-col cols="4" sm="4" md="4" class="pr-0 text-center">
                    <card-graficos-unidades-pequeno
                    :date="item.grafico"
                    title="PRODUTO"
                    :width="115"
                    :key="index"
                    class="minor-graph"
                    :height="115"
                    :factory="factory"
                  ></card-graficos-unidades-pequeno>
                  </v-col>
                  <v-col cols="8" sm="8" md="8">
                    <v-list-item-content class="pa-0" :class="item.gap || item.gap_ant ? '' : 'h-100'" width="100%" height="100%">
                        <v-col cols="12" sm="12" md="12" class="pb-0">
                          <div class="text-center mb-2 title_local">{{item.unidade}}</div>
                          <div class="text-info-yellow h5 font-weight-bold text-center" v-if="show">
                            {{item.faturamentoMes.valor | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','})}}
                          </div>
                          <div class="text-info-yellow h5 font-weight-bold text-center" v-else>-</div>
                        </v-col>
                      <v-row v-if="item.gap || item.gap_valor">
                        <v-col cols="6" sm="6" md="6" v-if="item.gap">
                          <div class="text-center fs">GAP</div>
                          <span class="text-info-blue font-weight-bold body-2">
                            {{item.gap | percent}}
                          </span>
                          <span class="text-info-yellow title mx-1">
                            |
                          </span>
                          <span class="text-info-blue font-weight-bold body-2" v-if="show">
                            {{ item.gap_valor | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}
                          </span>
                          <span class="text-info-blue font-weight-bold body-2" v-else>-</span>
                        </v-col>
                        <v-col cols="6" sm="6" md="6" v-if="item.porc_ant">
                          <div class="text-center fs">Anterior</div>
                          <span class="text-info-blue font-weight-bold body-2">
                            {{item.porc_ant | percent}}
                          </span>
                          <span class="text-info-yellow title mx-1">
                            |
                          </span>
                          <span class="text-info-blue font-weight-bold body-2" v-if="show">
                            {{item.faturamentoMesAnterior.valor | currency('', 0, { thousandsSeparator: '.', decimalSeparator: ','}) }}
                          </span>
                          <span class="text-info-blue font-weight-bold body-2" v-else>-</span>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-slide-item>
        </v-slide-group>
      </infinite-slide-bar>
      <div v-else>
        <v-slide-group class="py-0">
          <v-slide-item v-for="(item,index) in 5" :key="index" class="mr-2">
            <v-list two-line width="320px">
              <v-list-item>
                <v-list-item-content class="pa-0" width="100%" height="100%">
                  <v-skeleton-loader type="list-item-two-line" height="75"></v-skeleton-loader>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-slide-item>
        </v-slide-group>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  props: ["title", "show", "factory", "date"],
  data: () => ({
    loading: false,
    items: []
  }),
  watch: {
    date() {
      this.getDados();
    }
  },
  created() {
    this.getDados();
  },
  methods: {
    getDados(notLoading) {
      if (!notLoading) {
        this.loading = true;
      }
      var dados = {
        saasid: 212,
        periodo: this.date,
        factory_id: this.factory.id
      };
      axios
        .post("/api/getFaturamentoUnidades", dados)
        .then(response => {
          console.log("getFaturamentoUnidades **************");
          console.log(response.data);
          this.items = response.data;
          this.loading = false;
          this.timeoutAtt();
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    timeoutAtt() {
      setTimeout(() => {
        this.getDados(true);
      }, 120000);
    },
    duration() {
      if (this.items.length >= 6) {
        // return "80s";
        return (this.items.length * 8) + "s";
      } else {
        return "4984984984984s";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.fs {
  font-size: 12px;
  margin-bottom: -7px;
}
.title_local {
  font-size: 18px;
}
.red-bg {
  border-radius: 5px;
  background-color:rgba(150,8,25,0.6)!important
}
.yellow-bg {
  border-radius: 5px;
  background-color:rgba(100,102,0,0.6)!important
}
.green-bg {
  border-radius: 5px;
  background-color:rgba(0,53,28,0.6)!important
}
</style>