<template>
  <div class="fundo" style="background-image: url(./img/porquinhos_v2/fundo.jpg);">
    <img class="logo" src="/img/porquinhos_v2/logo.gif" alt="Super Sexta" />
    <div class="container-fluid h-100">
      <div class="row h-100 align-items-center pt_lista">
        <div class="col-12 text-center" v-if="loading">
          <img style="max-width: 100px;" src="/img/loading.gif" alt="Carregando" />
        </div>
        <div class="col-12" v-else>
          <carousel
            ref="carouselSelector"
            :per-page="1"
            :pagination-enabled="false"
            :navigation-enabled="false"
            :autoplay="true"
            :loop="true"
            :autoplay-timeout="10000"
            :perPageCustom="[[300, 1], [768, 2], [991, 3], [1024, 4], [1400, 6]]"
          >
            <slide v-for="(item, index) in items" :key="index">
              <div class="px-2">
                <div class="nome_unidade">
                  {{item.unidade}}
                </div>
                <div class="box_content text-center">
                  <div class="porco_area">
                    <div v-if="retornaImg(item) < 10" class="porco_img" :style="'background-image: url(/img/porquinhos_v2/' + retornaImg(item) + '.png)'"></div>
                    <div v-else class="porco_img" :style="'background-image: url(/img/porquinhos_v2/gif_terminou.gif)'"></div>
                    <div class="vendido">
                      Vendido
                    </div>
                    <div class="valor text-center">
                      R$
                      <number
                        ref="number"
                        :from="0"
                        :to="item.valor"
                        :format="theFormat"
                        :duration="5"
                        :delay="1"
                        easing="Power1.easeOut"/>
                    </div>
                    <div class="meta">
                      Meta
                    </div>
                    <div class="valor_meta text-center">
                      R$
                      <number
                        ref="number2"
                        :from="0"
                        :to="item.meta"
                        :format="theFormat"
                        :duration="5"
                        :delay="1"
                        easing="Power1.easeOut"/>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
        <div class="col-12">
          <div v-if="items.length > 4" class="setas">
            <div class="row row-no-gutters justify-content-center align-items-center">
              <button @click="advancePages('backward')">
                <img src="/img/previous.png" alt="voltar" />
              </button>
              <button @click="advancePages()">
                <img src="/img/next.png" alt="avançar" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="area_footer">
      <marquee width="100%" direction="right" scrollamount="10" behavior="alternate" loop="infinite" class="titulo_super">
        <span v-for="index in 20" :key="index">
          <span class="pl-1"></span> SUPER SEXTA ODONTOTOP <span class="pr-1"></span>| 
        </span>
      </marquee>
    </div>
  </div>
</template>

<script>
import VueNumber from 'vue-number-animation'
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['factory'],
  components: {
    VueNumber
  },
  data() {
    return {
      valor: 350000,
      loading: true,
      items: []
    }
  },
  methods: {
    retornaImg (item) {
      let meta = item.meta
      let valor = item.valor
      let divisao = Math.floor(meta/10)
      let etapa = 1

      if (valor >= divisao * 1 && valor < divisao * 2) {
        etapa = 1
      }
      if (valor >= divisao * 2 && valor < divisao * 3) {
        etapa = 2
      }
      if (valor >= divisao * 3 && valor < divisao * 4) {
        etapa = 3
      }
      if (valor >= divisao * 4 && valor < divisao * 5) {
        etapa = 4
      }
      if (valor >= divisao * 5 && valor < divisao * 6) {
        etapa = 5
      }
      if (valor >= divisao * 6 && valor < divisao * 7) {
        etapa = 6
      }
      if (valor >= divisao * 7 && valor < divisao * 8) {
        etapa = 7
      }
      if (valor >= divisao * 8 && valor < divisao * 9) {
        etapa = 8
      }
      if (valor > divisao * 9 && valor < divisao * 10) {
        etapa = 9
      }
      if (valor > divisao * 10) {
        etapa = 10
      }
      return etapa
    },
    advancePages(direction) {
      this.$refs.carouselSelector.advancePage(direction)
    },
    theFormat(number) {
      const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      return new Intl.NumberFormat('pt-BR', options).format(parseFloat(number))
    },
    loadContent () {
      this.loading = true
      this.getDados()
    },
    async getDados () {
      let dados = {
        saasid: 212,
        factory_id: this.factory.id ? this.factory.id : 999999,
        periodo: moment().format('Y-MM-DD'),
      }
      axios
        .post("/api/faturamentoTotalSuperSextaUnidades", dados)
        .then(response => {
          this.items = response.data
          this.timeoutAtt()
          this.loading = false
          console.log('nova request')
          console.log(response.data)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados()
      }, 90000)
    },
  },
  created () {
    this.loadContent()
  }
}
</script>

<style lang="scss" scoped>
.fundo {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
}
.logo {
  left: calc(50% - 125px);
  max-width: 250px;
  position: absolute;
  top: 0px;
}
.area_footer {
  top: calc(100% - 23px);
  background: #ffc911;
  height: 35px;
  position: absolute;
  width: 100%;
  z-index: 20;
}
.titulo_super {
  color: #102945;
  left: 0;
  font-family: 'Raleway';
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  padding: 0 5px;
  top: 8px;
}
.nome_unidade {
  align-items: end;
  color: white;
  display: flex;
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 22px;
  justify-content: center;
  margin-bottom: 5px;
  min-height: 100px;
  text-align: center;
  line-height: 30px;
  text-transform: uppercase;
  @media (max-width: 1480px){
    font-size: 20px;
    list-style: normal;
    min-height: 90px;
  }
}
.box_content {
  border: 2px #153d69 solid;
  background: rgba(25, 55, 88, 0.8);
  border-radius: 10px;
  padding: 10px;
}
.porco_area {
  margin-bottom: 0px;
  padding-top: 5px;
}
.porco_img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 15px;
  height: 250px;
  width: 100%;
}
.vendido{
  color: #ffc911;
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 24px;
  margin: 5px 0;
  text-transform: uppercase;
}
.meta {
  color: #ffc911;
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 18px;
  margin: 5px 0;
  text-transform: uppercase;
}
.valor {
  color: #fff;
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 22px;
  margin: 10px 0;
  text-transform: uppercase;
  @media (max-width: 1480px){
    font-size: 20px;
  }
}
.valor_meta {
  color: #fff;
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;
  @media (max-width: 1480px){
    font-size: 16px;
  }
}
.pt_lista {
  padding-top: 150px;
}
.setas {
  margin-top: -50px;
}
</style>