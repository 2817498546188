<template>
  <div>
    <v-card class="text-center" flat width="100%" height="165">
      <v-card-title class="title" style="font-size: 16px">
        <v-row justify="center" align="center">
          <v-col>
            <span class="text-center">{{ title }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row justify="center" align="center" v-if="!loading">
          <p class="overline mr-1">hoje</p>
          <p class="text-info-blue title mx-1">|</p>
          <p class="text-info-yellow font-weight-bold body-1" v-if="show">
            {{
              items.valor_dia
                | currency("", 0, {
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}
          </p>
          <p class="text-info-yellow font-weight-bold body-1" v-else>-</p>
        </v-row>
        <v-row class="mt-n6" justify="center" align="center" v-if="!loading">
          <p class="overline mr-1">mês</p>
          <p class="text-info-blue title mx-1">|</p>
          <p class="text-info-yellow font-weight-bold body-1" v-if="show">
            {{
              items.valor
                | currency("", 0, {
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}
          </p>
          <p class="text-info-yellow font-weight-bold body-1" v-else>-</p>
        </v-row>

        <v-skeleton-loader
          type="list-item-three-line"
          height="180"
          v-if="loading"
        ></v-skeleton-loader>
      </v-card-text>
      <v-card-actions
        style="font-size: 0.875rem !important; color: #ffffffb3"
        v-if="!loading"
      >
        <v-row justify="center" align="center" class="mt-n6">
          <v-col cols="12" md="6" sm="6" lg="6" class="px-0" v-if="items.gap">
            <span>GAP</span>
            <p>
              <span class="text-info-blue font-weight-bold body-2">{{
                items.gap | percent
              }}</span>
              <span class="text-info-yellow title mx-1">|</span>
              <span class="text-info-blue font-weight-bold body-2" v-if="show">{{
                items.gap_valor
                  | currency("", 0, {
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                  })
              }}</span>
              <span class="text-info-blue font-weight-bold body-2" v-else
                >-</span
              >
            </p>
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="6"
            lg="6"
            class="px-0"
            v-if="items.gap_ant"
          >
            <span>Anterior</span>
            <p>
              <span class="text-info-blue font-weight-bold body-2"
                >{{ items.gap_ant }}%</span
              >
              <span class="text-info-yellow title mx-1">|</span>
              <span class="text-info-blue font-weight-bold body-2" v-if="show">{{
                items.meta_mes_ant
                  | currency("", 0, {
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                  })
              }}</span>
              <span class="text-info-blue font-weight-bold body-2" v-else
                >-</span
              >
            </p>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
// moment(newValue.data).fromNow()
export default {
  props: ["title", "show"],

  data() {
    return {
      loading: false,
      value: [],
      items: {
        faturamentoDia: {
          valor: 32000,
        },
        faturamentoMes: {
          valor: 350000,
        },
        faturamentoMesAnterior: {
          valor: 56017.25,
        },
        valor_ant: 56017.25,
        gap_ant: "+234",
        meta_mes_ant: 52345.71153846154,
        valor_dia: 32000,
        meta_dia: 80769.23076923078,
        gap: "+0.51",
        gap_valor: 81357.51923076922,
        meta_mes: -192345.78846153838,
        valor: 350000,
      },
    };
  },
};
</script>