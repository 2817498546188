var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"text-center",attrs:{"flat":"","width":"100%","height":"165"}},[_c('v-card-title',{staticClass:"title",staticStyle:{"font-size":"16px"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.title))])])],1)],1),_vm._v(" "),_c('v-card-text',[(!_vm.loading)?_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('p',{staticClass:"overline mr-1"},[_vm._v("hoje")]),_vm._v(" "),_c('p',{staticClass:"text-info-blue title mx-1"},[_vm._v("|")]),_vm._v(" "),(_vm.show)?_c('p',{staticClass:"text-info-yellow font-weight-bold body-1"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.items.valor_dia,"", 0, {
                thousandsSeparator: ".",
                decimalSeparator: ",",
              }))+"\n        ")]):_c('p',{staticClass:"text-info-yellow font-weight-bold body-1"},[_vm._v("-")])]):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('v-row',{staticClass:"mt-n6",attrs:{"justify":"center","align":"center"}},[_c('p',{staticClass:"overline mr-1"},[_vm._v("mês")]),_vm._v(" "),_c('p',{staticClass:"text-info-blue title mx-1"},[_vm._v("|")]),_vm._v(" "),(_vm.show)?_c('p',{staticClass:"text-info-yellow font-weight-bold body-1"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.items.valor,"", 0, {
                thousandsSeparator: ".",
                decimalSeparator: ",",
              }))+"\n        ")]):_c('p',{staticClass:"text-info-yellow font-weight-bold body-1"},[_vm._v("-")])]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line","height":"180"}}):_vm._e()],1),_vm._v(" "),(!_vm.loading)?_c('v-card-actions',{staticStyle:{"font-size":"0.875rem !important","color":"#ffffffb3"}},[_c('v-row',{staticClass:"mt-n6",attrs:{"justify":"center","align":"center"}},[(_vm.items.gap)?_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","md":"6","sm":"6","lg":"6"}},[_c('span',[_vm._v("GAP")]),_vm._v(" "),_c('p',[_c('span',{staticClass:"text-info-blue font-weight-bold body-2"},[_vm._v(_vm._s(_vm._f("percent")(_vm.items.gap)))]),_vm._v(" "),_c('span',{staticClass:"text-info-yellow title mx-1"},[_vm._v("|")]),_vm._v(" "),(_vm.show)?_c('span',{staticClass:"text-info-blue font-weight-bold body-2"},[_vm._v(_vm._s(_vm._f("currency")(_vm.items.gap_valor,"", 0, {
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })))]):_c('span',{staticClass:"text-info-blue font-weight-bold body-2"},[_vm._v("-")])])]):_vm._e(),_vm._v(" "),(_vm.items.gap_ant)?_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","md":"6","sm":"6","lg":"6"}},[_c('span',[_vm._v("Anterior")]),_vm._v(" "),_c('p',[_c('span',{staticClass:"text-info-blue font-weight-bold body-2"},[_vm._v(_vm._s(_vm.items.gap_ant)+"%")]),_vm._v(" "),_c('span',{staticClass:"text-info-yellow title mx-1"},[_vm._v("|")]),_vm._v(" "),(_vm.show)?_c('span',{staticClass:"text-info-blue font-weight-bold body-2"},[_vm._v(_vm._s(_vm._f("currency")(_vm.items.meta_mes_ant,"", 0, {
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })))]):_c('span',{staticClass:"text-info-blue font-weight-bold body-2"},[_vm._v("-")])])]):_vm._e()],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }