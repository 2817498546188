<template>
  <v-row class="mr-0">
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="80">
        <v-card-title style="font-size: 14px">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">TAXA CONVERSÃO</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{
                value.taxaConversao | percent
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-skeleton-loader
          type="list-item"
          height="80"
          v-if="loading"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="80">
        <v-card-title style="font-size: 14px">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">TAXA REJEIÇÃO</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{
                value.taxaRejeicao | percent
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-skeleton-loader
          type="list-item"
          height="80"
          v-if="loading"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col class="pr-0">
      <v-card class="text-center" flat width="100%" height="80">
        <v-card-title style="font-size: 14px">
          <v-row justify="center" align="center">
            <v-col>
              <span class="text-center">JORNADA MÉDIA</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row justify="center" align="center" class="mt-n5">
            <v-col>
              <span class="text-info-yellow h4 font-weight-bold">{{
                value.jornada
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-skeleton-loader
          type="list-item"
          height="80"
          v-if="loading"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
// moment(newValue.data).fromNow()

export default {
  props: ["title", "show"],

  data() {
    return {
      loading: false,
      value: {
        taxaConversao: 0.25,
        taxaRejeicao: 0.75,
        jornada: '12 dias'
      },
    };
  },
};
</script>